import { useT } from "@/common/utils/translation";
import { LoginTab } from "./login-tab";

export default function LoginSubmit() {
  let t = useT();
  return (
      <>
        <div
            className=" flex max-lg:w-full lg:w-[602px] items-center justify-center rounded-r-[8px] bg-white"
            style={{ position: 'relative' }}
        >
          <div style={{ position: 'absolute', top: '36px', fontSize: '14px', fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>Kerqu.Ai运营平台</div>
          <div className="flex-1 lg:px-[100px] max-lg:px-5">
            <p className="lg:mb-[70px] text-center max-lg:text-[26px] max-lg:my-0 lg:text-[30px]">
              {t('common_Login_account')}
            </p>
            <LoginTab></LoginTab>
          </div>
        </div>
      </>

  );
}
