import {useT} from '@/common/utils/translation.js';
import {Button, Flex, Radio, Divider} from 'antd';
import DescriptionItem
  from '@/pages/components/SettlementBoard/DescriptionItem.jsx';
import SvgIcon from '@/pages/components/SvgIcon/index.jsx';
import useStyle from '@/pages/components/SettlementBoard/hooks/useStyle.js';
import useVipPackageBtnName from '@/hooks/useVipPackageBtnName.js';
import {usePackageTitle} from './index';

function usePackageType(type, value) {
  let t = useT();
  switch (type) {
    case 'ANNUAL':
      return value ? t('components_annual_fee') : t('components_year');
    case 'MONTHLY':
      return value ? t('components_monthly_payment') : t('createCenter_moon');
    case 'FREE':
      return t('components_free');
  }
}

const SettlementMobileDetail = (props) => {
  let t = useT();
  const {vipPackageBtnName} = useVipPackageBtnName();
  const {paymentCardClassname, nameClassname} = useStyle();
  let {vipPackage, payPriceRMB, payPrice, packageExtensionTime} =
  props?.vipInfo || {};
  let packageType = usePackageType;
  let packageTitle = usePackageTitle();
  let price = payPrice ?? 0;

  return (
      <div className="mobile-container">
        <div className="title">
          {t('components_purchase_package_confirmation_information')}
        </div>
        {vipPackageBtnName[vipPackage.packageVersion]?.value === 'upgrade' ? (
            <div className="sub-title">
              {t(
                  'components_the_remaining_service_days_of_the_original_package_will_be_converted_into_a_balance_according_to_the_amount_and_the_original_package_balance_will_be_deducted_from_the_new_package_fee',
              )}
            </div>
        ) : (
            <div style={{marginBottom: '1rem'}}></div>
        )}
        <Flex
            className={nameClassname}
            justify="space-between"
            align="center"
        >
          <div style={{fontWeight: '500', fontSize: '1.13rem'}}>
            {t('components_professional_version')}
          </div>
          <div style={{fontSize: '1rem'}}>
          <span
              style={{
                fontWeight: '600',
                fontSize: '2rem',
              }}
          >
            ${props?.vipInfo?.packagePrice}
          </span>
            /
            <span
                style={{
                  fontSize: '1rem',
                }}
            >
            {packageType(vipPackage?.packageType)}
          </span>
          </div>
        </Flex>
        <Divider style={{marginBlock: '1.81rem 1.06rem'}}/>
        <div>
          <DescriptionItem
              title={t('components_package_version')}
              value={`${t('components_vip_package')}-${packageTitle(
                  vipPackage.packageVersion,
              )}`}
          />
          <DescriptionItem
              title={t('components_order_type')}
              value={vipPackageBtnName[vipPackage.packageVersion]?.name}
          />
          <DescriptionItem
              title={t('components_subscription_fee')}
              value={`$${props?.vipInfo?.packagePrice}`}
          />
          <DescriptionItem
              title={t('components_billing_cycle')}
              value={packageType(vipPackage?.packageType, true)}
          />
          {vipPackageBtnName[vipPackage.packageVersion]?.value === 'upgrade' ? (
              <DescriptionItem
                  style={{marginBottom: '37px'}}
                  title={t(
                      'components_deduction_from_original_package_balance')}
                  value={`-$${props?.vipInfo?.oldPackageDeductionAmount}`}
              />
          ) : null}
          <Divider style={{marginBlock: '1rem 1rem'}}/>
          <DescriptionItem
              title={t('components_total')}
              value={<span style={{fontSize: 20}}>{`$${
                  price >= 0 ? price : 0
              }(￥${payPriceRMB})`}</span>}
          />
          <Divider style={{marginBlock: '1.06rem 1.5rem'}}/>
          {price <= 0 ? (
              <div className="final-desc">
                {t(
                    'components_the_remaining_balance_after_deducting_the_balance_of_your_original_package',
                )}{' '}
                $
                {(props.vipInfo.oldPackageDeductionAmount ?? 0) -
                    (props.vipInfo.packagePrice ?? 0)}{' '}
                {/* ，
            {`${t(
              "components_automatically_extend_the_service_period_of_new_packages_by_73_days"
            )} ${packageExtensionTime ?? 0} 天`} */}
              </div>
          ) : null}
        </div>
        <Radio.Group onChange={props.onPaymentChange} value={props.payment}>
          <Flex justify="space-between" wrap={'wrap'} gap={12}>
            <div
                className={paymentCardClassname}
                data-value={'ALIPAY'}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: {value: e.target.dataset.value},
                  });
                }}
            >
              <Radio value={'ALIPAY'} className="payment-card-radio">
                <Flex align="center">
                  <SvgIcon name="alipay"/>
                  <div className="payment-card-label">
                    {t('components_alipay')}
                  </div>
                </Flex>
              </Radio>
            </div>
            <div
                data-value={'WECHAT'}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: {value: e.target.dataset.value},
                  });
                }}
            >
              <Radio value={'WECHAT'} className="payment-card-radio">
                <Flex align="center">
                  <SvgIcon name="wechatpay"/>
                  <div className="payment-card-label">
                    {t('components_wechat')}
                  </div>
                </Flex>
              </Radio>
            </div>
            <div
                data-value={'STRIPE'}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: {value: e.target.dataset.value},
                  });
                }}
            >
              <Radio value={'STRIPE'} className="payment-card-radio">
                <Flex align="center">
                  <SvgIcon name="cardpay"/>
                  <div className="payment-card-label">
                    {t('components_bank_card')}
                  </div>
                </Flex>
              </Radio>
            </div>
            <div
                data-value={'PAYPAL'}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: {value: e.target.dataset.value},
                  });
                }}
            >
              <Radio value={'PAYPAL'} className="payment-card-radio">
                <Flex align="center">
                  <SvgIcon name="paypal"/>
                  <div className="payment-card-label">Paypal</div>
                </Flex>
              </Radio>
            </div>
          </Flex>
        </Radio.Group>
        <Button
            type="primary"
            size="large"
            className="w-full"
            style={{marginTop: '1.5rem'}}
            onClick={props.pay}
        >
          {t('components_confirm_payment')}
        </Button>
      </div>
  );
};

export default SettlementMobileDetail;
