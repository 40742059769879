import styles from './index.module.scss';
import React, {useEffect, useRef, useState} from "react";
import {Input, Typography, Space, Button, message, Empty} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import SvgIcon from "@/pages/components/SvgIcon";
import {PrimaryColor} from "@/const";
import HeaderTitle from "@/pages/components/HeaderTitle/index.jsx";
import ApplicationCard from "@/pages/MyApplication/ApplicationCard/index.jsx";
import {useNavigate} from "react-router-dom";
import ChoiceApplication from "@/pages/HomeApplication/ChoiceApplication.jsx";
import {SortContainer} from "@/pages/AddApplication/ParamsList/index.jsx";
import {arrayMove, SortableElement, SortableHandle} from "react-sortable-hoc";
import {HolderOutlined} from "@ant-design/icons";
import {
  addHomePageType, deleteHomePageType,
  getHomeApplicationTypeList,
  getHomePageApplications, updateHomePageApplications,
  updateHomePageType, updateHomeTypeSort
} from "@/services/application.js";
import {typeToSvgName} from "@/pages/AddApplication/ChoiceType/const.jsx";
import LoadingComponent from "@/pages/components/LoadingComponent";
import {v4} from "uuid";
import {onPublishHomeApplication} from "@/services/application";
import CustomerConfirm, {customerSuccess} from "@/common/utils/CustomerConfirm";

import menuChangeListener_instance from '@/lib/menuChangeListener';

const {Link} = Typography;

const SortAbleHand = SortableHandle(({children}) => {
  return (<span>
      <HolderOutlined style={{color: 'rgba(0,0,0,0.25)'}}/>
    {children}
    </span>)
})

const SortElement = SortableElement((data) => {
  const {item, currentActiveId, onClick, onRefresh, len} = data;

  useEffect(() => {
    console.log('disabled', len);
  }, [len]);

  return <TypeOne
    title={item.title}
    status={item.status}
    onRefresh={onRefresh}
    disabled={len === 1}
    id={item.id}
    active={item.id === currentActiveId}
    onClick={() => {
      onClick(item.id);
    }}
  />
})

export const SortCardElement = SortableElement((data) => {
  const {item, onClick, onEdit, sorted} = data;
  return <div className={styles.SortCardElement}>
    {
      sorted && (
        <div className={styles.sortButton}>
          <SortAbleHand/>
        </div>
      )
    }
    <ApplicationCard
      cardInfo={item}
      onClick={onClick}
      editDelete={true}
      onEdit={(type) => {
        onEdit?.(type)
      }}
    />
  </div>
})

function TypeOne({title, onClick, status, active, id, onRefresh, disabled}) {

  const [editAble, setEditAble] = useState(status === 'edit');
  const [editTitle, setEditTitle] = useState(title);

  useEffect(() => {
    setEditAble(status === 'edit');
  }, [status]);

  const onChangeEditAble_true = (e) => {
    // e.stopPropagation();
    // e.preventDefault();
    setEditAble(true);
  }

  const onChangeEditAble_false = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditAble(false);
  }

  const onUpdateType = async (e) => {

    if(!editTitle){
      message.warning('应用组名称不能为空');
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    setEditAble(false);
    onRefresh?.('edit', id, editTitle);
    return;

    let res = null;
    if (id) {
      res = await updateHomePageType(id, editTitle);
    } else {
      res = await addHomePageType(editTitle);
    }

    if (res.success) {
      message.success('操作成功');
      onRefresh?.();
    }
  }

  const onDelete = async () => {
    onRefresh?.('del', id, editTitle);
    return;

    const res = await deleteHomePageType(id);
    if (res.success) {
      message.success('操作成功');
      onRefresh?.();
    }
  }

  return (
    <div
      className={`${styles.typeContainer} ${active ? styles.active : ''}`}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick?.();
      }}
    >
      <SortAbleHand>
        <span className={styles.sortText}></span>
      </SortAbleHand>
      {
        (editAble && !disabled) ? (
          <Input
            value={editTitle}
            style={{width: '124px'}}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onChange={(e) => {
              const value = e.target.value;
              setEditTitle(value);
            }}
          />
        ) : (
          <div className={styles.title} title={title}>{title}</div>
        )
      }

      {
        !disabled ?  (
          <div className={styles.operate}>
            {
              editAble ? (
                <Space>
                  <Link
                    style={{color: 'rgba(0,0,0,0.65)', fontSize: '12px'}}
                    onClick={onChangeEditAble_false}
                  >
                    取消
                  </Link>
                  <Link
                    onClick={onUpdateType}
                    style={{fontSize: '12px'}}
                  >
                    确定
                  </Link>
                </Space>
              ) : (
                <Space className={styles.operateButton}>
                  <SvgIcon
                    name={'edit'}
                    className={styles.buttonStyle}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      onChangeEditAble_true();
                    }}
                  />
                  <SvgIcon
                    name={'delete'}
                    className={styles.buttonStyle}
                    style={{color: 'red'}}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      onDelete();
                    }}
                  />
                </Space>
              )
            }
          </div>
        ) : (<div style={{width: '50px'}}></div>)
      }
    </div>
  )
}

function HomeApplication() {

  const navigate = useNavigate();
  const [choiceAppIds, setChoiceAppIds] = useState([]);
  const [currentActiveId, setCurrentActiveId] = useState('')
  const [typeList, setTypeList] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const applicationDetailRef = useRef({});
  const typeListRef = useRef([]);
  const oldListRef = useRef([]);

  useEffect(() => {
    menuChangeListener_instance.addPageChangeListener(checkHadChange)
    getTypeList();
  }, []);

  const checkHadChange = (fn) => {

    const newList = typeListRef.current.map(item => {
      return {
        title: item.title,
        appId: item.children.map(item => item.appId)
      }
    }).sort((a, b) => String(a.title).localeCompare(String(b.title)));

    const oldList = oldListRef.current?.map(item => {
      return {
        title: item.title,
        appId: item.children.map(item1 => item1.appId)
      }
    }).sort((a, b) => String(a.title).localeCompare(String(b.title)));

    if(JSON.stringify(newList) != JSON.stringify(oldList)){
      CustomerConfirm({
        title: '变更内容未保存,确定要离开吗？',
        onOk: () => {
          fn?.()
        }
      })
      return true;
    }

  }

  const getTypeList = async () => {
    const res = await getHomeApplicationTypeList();
    if (res.success) {
      const list = res.data.map(item => {
        const id = v4()
        applicationDetailRef.current[id] = item.app;

        return {
          title: item.title,
          id: id,//item.id,
          uuid: id,
          status: 'view',
          children: item.app,
        }
      });

      oldListRef.current = JSON.parse(JSON.stringify(list));

      setTypeList(list);
      typeListRef.current = list;

      if (res.data?.length) {
        setCurrentActiveId(list[0].id);
        // getApplication(res.data[0].id);
        getCurrentTypeDetail(list[0].id);
      }
    }
  }

  const getApplication = async (id) => {
    setCardList([]);
    setLoading(true);
    const res = await getHomePageApplications(id);
    if (res.success) {
      const _list = res.data.map(item => {
        return {
          ...item,
          type: {
            type: item.type
          }
          // icon: typeToSvgName[item.type],
          // name: item.title,
          // description: item.description,
          // createBy: item.author,
          // id: item.appId
        }
      });

      setCardList(_list);
    }

    setLoading(false);

  }

  const onSave = async () => {
    const data = typeList.map(item => {
      return {
        title: item.title,
        appId: item.children.map(item => item.appId)
      }
    })
    const res = await onPublishHomeApplication({groupList: data});
    if (res.success) {
      oldListRef.current = typeList;
      message.success('操作成功');
    }
  }

  const onSortEnd = async ({newIndex, oldIndex}) => {
    const tempList = arrayMove(typeList, oldIndex, newIndex);

    setTypeList(tempList);
    typeListRef.current = tempList;

    // const res = await updateHomeTypeSort(tempList.map(item => {
    //   return item.id;
    // }));
    // if (res.success) {
    //   getTypeList()
    // }
  }

  const onCardSortEnd = ({newIndex, oldIndex}) => {
    const tempList = arrayMove(cardList, oldIndex, newIndex);
    setCardList(tempList);


    setTypeList(list => {
      list.forEach(item => {
        if(item.id === currentActiveId){
          item.children = tempList;
        }
      });
      typeListRef.current = list;

      return list;
    });
  }

  const addType = () => {

    const hadDefault = typeList.find(item => item.title === '应用组');
    if(hadDefault){
      message.warning('已存在默认应用组');
      return;
    }

    setTypeList(list => {
      const id = v4();
      return [...list, {
        title: '应用组',
        id: id,
        uuid: id,
        status: 'edit',
        children: [],
      }]
    })
  }

  const onChangeApplication = (apps) => {

    return new Promise(resolve => {

      setTypeList(list => {
        const _list = JSON.parse(JSON.stringify(list));
        _list.forEach(item => {
          if (item.id === currentActiveId) {
            item.children = apps;
          }
        });

        setTypeList(_list);
        typeListRef.current = _list;

        setCardList(apps);

        return _list;
      })
      resolve(true);

      // updateHomePageApplications(currentActiveId, ids).then(res => {
      //   if (res.success) {
      //     getApplication(currentActiveId);
      //     resolve(true);
      //   }
      // })
      //
    });
  }

  const onTypeOperation = (type, id, title) => {
    if (type === 'del') {

      CustomerConfirm({
        title: '删除应用组',
        content: `确定删除${title}吗？,删除后应用组中的应用也将被删除`,
        onOk: () => {
          const _list = typeList.filter(item => item.id !== id);
          setTypeList(_list);
          typeListRef.current = _list;

          setCurrentActiveId(_list[0].id);
          setCardList(_list[0].children || []);
        },
        okText: '删除'
        // onText: <Button danger={true} onClick={() => {
        //     const _list = typeList.filter(item => item.id !== id);
        //     setTypeList(_list);
        //     typeListRef.current = _list;
        //
        //     setCurrentActiveId(_list[0].id);
        //     setCardList(_list[0].children || []);
        // }}/>
      })

    } else if (type === 'edit') {
      const hadCommon = typeList.some(item => item.title === title && item.id !== id);
      if (hadCommon) {
        message.warning('该分组名称已存在');
        return;
      }

      setTypeList(list => {
        const _list = JSON.parse(JSON.stringify(list));
        _list.forEach(item => {
          if (item.id === id) {
            item.title = title;
            item.status = 'view';
          }
        });

        typeListRef.current = _list;
        return _list;
      })
    }
  }

  const getCurrentTypeDetail = (id) => {
    const currentType = typeListRef.current.find(item => item.id === id);
    setCardList(currentType?.children || []);
  }

  return (
    <div className={styles.container}>
      <HeaderTitle
        title={'首页应用'}
        rightRender={<Button type={'primary'} onClick={onSave}>发布</Button>}
      />

      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.header}>
            <div className={styles.title}>应用组</div>
            <div className={styles.addGroup}>
              <Link
                onClick={() => {
                  addType();
                }}
              >
                <PlusOutlined style={{marginRight: '4px'}}/>
                添加应用组
              </Link>
            </div>
          </div>

          <div className={styles.group}>
            <SortContainer onSortEnd={onSortEnd} useDragHandle>
              {
                typeList.map((item, index) => {
                  return (
                    <SortElement
                      index={index}
                      key={item.id}
                      onRefresh={(type, id, title) => {
                        onTypeOperation(type, id, title);
                        // getTypeList();
                      }}
                      // len={typeList.length}
                      item={item}
                      currentActiveId={currentActiveId}
                      onClick={(id) => {
                        setCurrentActiveId(id);
                        getCurrentTypeDetail(id);
                        // getApplication(id);
                      }}
                    />
                  )
                })
              }
            </SortContainer>
          </div>

        </div>
        <div className={styles.right}>
          <div className={styles.headerLine}>
            <Button
              type={'primary'}
              onClick={() => {
                const ids = cardList;
                ChoiceApplication(ids, (choiceList) => {
                  return onChangeApplication(choiceList);
                })
              }}
            >
              选择应用
            </Button>
          </div>

          {
            loading && <LoadingComponent/>
          }

          {
            !loading && cardList.length === 0 ? (
              <Empty description={'暂无数据'}/>
            ) : (
              <SortContainer
                onSortEnd={onCardSortEnd}
                useDragHandle
                axis={'xy'}
              >
                <div className={styles.cardContainer}>
                  {/*<Space wrap={true} size={16}>*/}
                  {
                    cardList.map((item, index) => {
                      return (
                        <SortCardElement
                          index={index}
                          key={item.id}
                          item={{
                            ...item,
                            type: {
                              type: item.type
                            }
                          }}
                          sorted={true}
                          onEdit={async (type) => {
                            if (type === 'delete') {

                              const _typeList = typeList.map(item1 => {
                                return {
                                  ...item1,
                                  children: item1.children.filter(item2 => item2.appId !== item.appId)
                                }
                              })

                              const _cardList = cardList.filter(item2 => item2.appId !== item.appId)

                              setCardList(_cardList);
                              setTypeList(_typeList);
                              typeListRef.current = _typeList;
                            }

                          }}
                          onClick={() => {
                            navigate({
                              pathname: '/view-application',
                              search: `id=${item.appId}&groupId=${item.groupId}`
                            })
                          }}
                        />
                      )
                    })
                  }
                  {/*</Space>*/}

                </div>
              </SortContainer>
            )
          }

        </div>
      </div>
    </div>
  )

}

export default HomeApplication;