import { useRequest } from "ahooks";
import { Button, Checkbox, Form, Input, Select, Tabs, message } from "antd";

import { useT } from "@/common/utils/translation";
import { useRootContext } from "@/common/RootContext";
import CodeBtn from "./sendcode";
import { getUserInfo, login } from "@/services/common";
// import { useLocation, useNavigate } from "react-router-dom";
import { myCookie } from "@/common/utils/cookie";
import { useLoginContext } from "../components/LoginModal/index";

export function LoginTab() {
  let t = useT();
  let login_successful = t("common_login_successful");
  let user = useRootContext();
  let { loading, runAsync } = useRequest(login, {
    manual: true,
  });
  // const navigate = useNavigate();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const to = searchParams?.get("from") || "/";

  async function onFinish(values) {
    let logRes = await runAsync({ ...values });

    if (!logRes.success && logRes.message) {
      message.error(logRes.message);
    }
    if (logRes.success) {
      // console.log(useRes,'useRes');
      // router.replace 无法跳转，应该是next.js的bug
      // router.replace(to);
      message.success(login_successful);
      myCookie.set("kerqu-admin-token", logRes.data);
      user.dispatchSetLoginStatus(true);
      user.dispatchSetLoginVisible(false);
      // let initialize = await initializeInfo();
      // if (initialize.success) {
      //
      //   myCookie.set("groupId", initialize.data.groupId);
      //   myCookie.set("kerqu-group", initialize.data.groupId);
      // }
      getUserInfo().then((res) => {
        if (res.success) {
          user.dispatchSetUserInfo(res.data);
        }
      });
      
      // if (searchParams?.get("url")) {
      //   window.location.href = searchParams.get("url");
      // } else {
      //   navigate({
      //     pathname: to,
      //   });
      // }
    }
  }
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="86">+86</Select.Option>
      </Select>
    </Form.Item>
  );
  let loginContext = useLoginContext();
  const onChange = (key) => {};
  let password_login = t("common_password_login");
  let please_enter_the_correct_phone_number = t(
    "common_please_enter_the_correct_phone_number"
  );
  let please_enter_the_phone_number = t("common_please_enter_the_phone_number");
  let please_enter_verification_code = t(
    "common_please_enter_verification_code"
  );
  let automatically_log_in_within_seven_days = t(
    "common_automatically_log_in_within_seven_days"
  );
  let log_in = t("common_log_in");
  let please_enter_password = t("common_please_enter_password");
  let verification_code_login = t("common_verification_code_login");
  const codeForm = (
    <Form
      initialValues={{ prefix: "86" }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: please_enter_the_correct_phone_number,
          },
        ]}
      >
        <Input
          size="large"
          placeholder={please_enter_the_phone_number}
          addonBefore={prefixSelector}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: please_enter_verification_code,
          },
        ]}
        name="code"
      >
        <Input
          addonAfter={
            <CodeBtn text={t("login_send_the_verification_code")}></CodeBtn>
          }
          size="large"
          placeholder={please_enter_verification_code}
        />
      </Form.Item>
      <Form.Item name="rememberMe" valuePropName="checked">
        <Checkbox>{automatically_log_in_within_seven_days} </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          className="w-full"
          type="primary"
          htmlType="submit"
          size="large"
        >
          {log_in}
        </Button>
      </Form.Item>
    </Form>
  );

  const passForm = (
    <Form
      name="basic"
      className="flex w-full flex-col justify-center"
      initialValues={{ prefix: "86" }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: please_enter_the_correct_phone_number,
          },
        ]}
      >
        <Input
          size="large"
          placeholder={please_enter_the_phone_number}
          addonBefore={prefixSelector}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: please_enter_password }]}
        name="password"
      >
        <Input.Password size="large" placeholder={please_enter_password} />
      </Form.Item>

      <Form.Item name="rememberMe" valuePropName="checked">
        <Checkbox>{automatically_log_in_within_seven_days}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          className="w-full"
          type="primary"
          htmlType="submit"
          size="large"
        >
          {log_in}
        </Button>
      </Form.Item>
    </Form>
  );
  const items = [
    {
      key: "1",
      label: verification_code_login,
      children: codeForm,
    },
    {
      key: "2",
      label: password_login,
      children: passForm,
    },
  ];
  return (
    <>
      <Tabs defaultActiveKey="1" centered items={items} onChange={onChange} />
    </>
  );
}
