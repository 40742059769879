import styles from './index.module.scss';
import {QRCode} from "antd";
import favicon from "@/assets/icons/favicon.svg";
import {useState} from "react";
import {useMount} from "ahooks";
import {getClientQr, getGroupQr} from "@/services/common.js";
import useIsMobile from "@/common/hooks/useIsMobile.js";

function QrComponent(){
  const [isMobile] = useIsMobile();
  const [groupValue, setGroupValue] = useState('');
  const [clientValue, setClientValue] = useState('');

  useMount(() => {
    getQrValue();
  })

  const getQrValue = async () => {
    const group = await getGroupQr();
    if(group.success){
      setGroupValue(group.data);
    }

    const client = await getClientQr();
     if(client.success){
       setClientValue(client.data);
     }
  }

  return <div className={`${isMobile ? styles.h5QrContainer:styles.qrContainer}`}>
    <div className={styles.left}>
      <div className={styles.title}>
        微信扫码入群
      </div>
      <div className={styles.subTitle}>
        KerquAi 用户体验交流群
      </div>

      <div className={styles.qr}>
        <QRCode
          errorLevel="H"
          value={groupValue}
          icon={ favicon}
          size={148}
        />
      </div>

      <div className={styles.desc}>
        跨境AI工具使用技巧交流
      </div>
    </div>
    <div className={styles.right}>
      <div className={styles.title}>
        联系客服
      </div>
      <div className={styles.subTitle}>
        @客服
      </div>

      <div className={styles.qr}>
        <QRCode
          errorLevel="H"
          value={clientValue}
          icon={ favicon}
          size={148}
        />
      </div>

      <div className={styles.desc}>
        产品功能吐槽、改进建议等
      </div>
    </div>
  </div>
}

export default QrComponent;
