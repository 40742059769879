import { useState } from "react";
import { useRequest } from "ahooks";
import { Button, Checkbox, Form, Input, Modal, Select, message } from "antd";
import LoginLogo from "../Login/logo";
import Bgl from "@/assets/images/login/bgl.png";
import Bgr from "@/assets/images/login/bgr.png";
import { useT } from "@/common/utils/translation";
import { getUserInfo, reg } from "@/services/common";
import { addShareRecord } from "@/services/invite"
import CodeBtn from "@/pages/Login/sendcode";
import { myCookie } from "@/common/utils/cookie";
import { useRootContext } from "@/common/RootContext";
import { useLoginContext } from "../components/LoginModal/index";
import stores from "@/common/utils/stores.js";

const prefixSelector = (
  <Form.Item noStyle>
    <Select style={{ width: 70 }} defaultValue="86">
      <Select.Option value="86">+86</Select.Option>
    </Select>
  </Form.Item>
);
export default function Reg() {


  return (
    <div className="bg-[rgba(245,245,245)]">
      <div
        className="flex h-screen w-screen items-center justify-center  max-lg:overflow-hidden"
        style={{
          background: `url(${Bgl}) bottom left no-repeat, url(${Bgr}) bottom right no-repeat`,
          backgroundSize: "350px",
        }}
      ><RegisterContent></RegisterContent></div>
    </div>
  );
}

export function RegisterContent() {
  let t = useT();

  // const navigate = useNavigate();
  let user = useRootContext();
  let { loading, runAsync } = useRequest(reg, {
    manual: true,
  });
  let loginContext = useLoginContext();
  let [form] = Form.useForm();
  let [agree, setAgree] = useState(false);
  const onFinish = async function (data) {
    if (!agree) {
      showModal();
      return;
    }
    let result = await runAsync(data);
    if (result.success) {
      myCookie.set("kerqu-admin-token", result.data);
      user.dispatchSetLoginStatus(true);
      // let initialize = await initializeInfo();
      message.success(t("common_registration_success"));
      getUserInfo().then((res) => {
        if (res.success) {
          user.dispatchSetUserInfo(res.data);
        }
      });
      // if (initialize.success) {
      //   myCookie.set("groupId", initialize.data.groupId);
      //   myCookie.set("kerqu-group", initialize.data.groupId);
      //   message.success(t("common_registration_success"));
      //   getUserInfo().then((res) => {
      //     if (res.success) {
      //       user.dispatchSetUserInfo(res.data);
      //     }
      //   });
      // }
        user.dispatchSetLoginVisible(false);
        // 用户注册成功之后,检查URL链接上时候有code标识, 作为接受邀请的凭证
        uploadInviteRecord()
    } else {
      message.error(result.message);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setAgree(true);
    agree = true;
    form.validateFields().then((result) => {
      if (result) {
        onFinish(form.getFieldsValue());
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const uploadInviteRecord = () => {
      const searchParams = new URLSearchParams(location.search);
      const code = searchParams.get('code');
      if(code) {
        addShareRecord({
          shareCode: code, 
          canvasId: stores.getLocalStorage("canvaslId") ,
          webglId: stores.getLocalStorage("webglId")}
          )
      }
  }

  return (
    <div className="flex justify-center items-center">
      <LoginLogo></LoginLogo>
      <div
        className="flex max-lg:w-full lg:w-[602px] items-center justify-center bg-white px-[100px] max-lg:p-0"
        style={{ boxSizing: "border-box"}}
      >
        <Form
          form={form}
          className="flex w-full flex-col justify-center"
          initialValues={{ prefix: "86" }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <p className="max-lg:mb-3 lg:mb-[70px] max-lg:text-[26px] max-lg:m-0 text-center lg:text-[30px] ">
            {t("common_register_an_account")}
          </p>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                pattern: /^1[3456789]\d{9}$/,
                message: t("common_please_enter_the_correct_phone_number"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("common_please_enter_the_correct_phone_number")}
              addonBefore={prefixSelector}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("common_please_enter_verification_code"),
              },
            ]}
            name="code"
          >
            <Input
              addonAfter={<CodeBtn></CodeBtn>}
              size="large"
              placeholder={t("common_please_enter_verification_code")}
            />
          </Form.Item>

          <Form.Item valuePropName="checked">
            <Checkbox
              checked={agree}
              onChange={(e) => {
                setAgree(e.target.checked);
              }}
            >
              {t("common_read_and_agree")}{" "}
              <a href="https://static.ai.kerqu.com/static/protocol.html" rel="noreferrer" target="_blank">
                《kerqu{t("common_user_agreement")}》
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item style={{margin:0}}>
            <Button
              disabled={loading}
              className="w-full lg:mb-2.5 max-lg:mb-2"
              type="primary"
              htmlType="submit"
              size="large"
            >
              {t("common_register")}
            </Button>
          </Form.Item>
          <Form.Item style={{margin:0}}>
            <Button
              type="link"
              className="w-full"
              size="large"
              onClick={
                () => loginContext.dispatchSetType("login")
                // navigate({
                //   pathname:
                //     "/login" +
                //     (searchParams?.get("url")
                //       ? `?url=${searchParams?.get("url")}`
                //       : ""),
                // })
              }
            >
              {t("common_go_to_login")}
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title={t("common_user_agreement_and_privacy_protection")}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={t("common_agree_and_register")}
          cancelText={t("common_disagree")}
        >
          <p>
            {t("common_read_and_agree")}
            <a href="https://static.ai.kerqu.com/static/protocol.html" rel="noreferrer" target="_blank">
              《kerqu{t("common_user_agreement")}》
            </a>
            .
          </p>
        </Modal>
      </div>
    </div>
  );
}
