import Error from "@/pages/components/SettlementBoard/Error.jsx";
import Success from "@/pages/components/SettlementBoard/Success.jsx";
import { Modal, Spin } from "antd";
import useStyle from "@/pages/components/SettlementBoard/hooks/useStyle.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { getOrderById } from "@/services/vip";
import { PAY_STATUS, openTime } from "./WechatPay";
import { usePackageTitle } from "./index";
import { useRootContext } from "@/common/RootContext";

export function ResultModal() {
  const searchParams = new URLSearchParams(location.search);
  const [visible, setVisible] = useState(!!searchParams.get("payId"));
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const { modalClassname } = useStyle({ type });
  const onCancel = () => {
    setType("");
    let url = new URL(location.href);
    url.searchParams.delete("payId");
    navigate(url.pathname + url.search);
  };
  useEffect(() => {
    if (searchParams.get("payType")) {
      setType(searchParams.get("payType"));
      return;
    }
  }, []);
  return (
    <Modal
      className={modalClassname}
      open={visible}
      onCancel={() => {
        onCancel();
        setVisible(false);
      }}
      footer={null}
    >
      <ResultContent
        error={<Error onBack={() => onCancel()} />}
        success={(title) => (
          <Success
            title={title}
            onBack={() => {
              onCancel();
              setVisible(false);
            }}
          />
        )}
        type={type}
        id={searchParams.get("payId")}
      ></ResultContent>
    </Modal>
  );
}

export function ResultContent({ id, error, success }) {
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const  rootContent= useRootContext();
  let [loading, setLoading] = useState(true);

  let packageTitle = usePackageTitle();
  const { runAsync } = useRequest(
    async (id) => {
      return await getOrderById(id);
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    let openSetTime = openTime();

    id &&
      openSetTime.open(async () => {
        let res = await runAsync(id);
        if (res.success) {
          setTitle(packageTitle(res.data.vipPackage.packageVersion));
          if (res.data.payStatus == PAY_STATUS.PAID) {
            setLoading(false);
            openSetTime.out();
            setType("success");
          } else if (res.data.payStatus == PAY_STATUS.CANCEL) {
            setLoading(false);
            openSetTime.out();
            setType("error");
          }
          // currentPlan().then((res) => {
          //   if (res.success) {
          //     rootContent.dispatchSetUserCurrentPlan(res.data)
          //   }
          // });
        }

      });
    return () => {
      openSetTime.out();
    };
  }, []);
  return (
    id && (
      <Spin tip="支付结果等待中..." spinning={loading}>
        <div className="min-h-[310px]">
          {type === "error" ? error : null}
          {type === "success" ? success(title) : null}
        </div>
      </Spin>
    )
  );
}
