import request from "./index"

export const createPlayOrder = (data) => {
    return request.post('/api/vip/createOrder',data)
  }


export const getCurrentPackage = () => {
    return request.get('/api/vip/currentPackage')
  }
export const putUpdatePackage = (data) => {
    return request.post('/api/vip/updatePackage',data)
  }


