import { useState, Suspense } from 'react';
import styles from './index.module.scss';
import { Layout, Button } from 'antd';
import HomeHeader from './components/HomeHeader';
import MenuComponent from './components/MenuComponent/index.jsx';

import SliderUser from './components/SliderUser/index.jsx';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRootContext } from '@/common/RootContext';
import { ResultModal } from './components/SettlementBoard/result';
import { OrderModal } from './components/SettlementBoard/orderModal';
import LoadingComponents from '@/pages/components/LoadingComponent';
import useIsMobile from '@/common/hooks/useIsMobile.js';
import { checkIsMobile } from '@/common/utils/tools.js';
import classNames from 'classnames';
import SvgIcon from "@/pages/components/SvgIcon/index.jsx";

const { Header, Footer, Sider, Content } = Layout;

function Home({ routerParams }) {
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const rootContentData = useRootContext();
  const [isMobile] = useIsMobile();
  const onMenuItemClick = (item) => {
    rootContentData.eventEmitter?.emit('menu-item-click', {
      key: item.key,
      keyPath: item.keyPath,
    });
  };
  return (
    <Layout className={styles.container}>
      <Sider className={styles.sider}>
        <div
          className={`${styles.menuContainer} ${styles.noUserHeight}`}
        >

          <div className={styles.log}>
            <SvgIcon
              onClick={() => {
                navigate({
                  pathname: '/'
                })
              }}
              name={"logo"}
              style={{ width: "132px", height: "26px", cursor: 'pointer' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Button
              ghost
              onClick={() => {
                window.open(import.meta.env.VITE_APP_JUMP_URL, '_self');
              }}
            >
              {
                import.meta.env.VITE_APP_ENV === 'cn' ? '前往国际版' : '前往国内版'
              }
            </Button>
          </div>

          <MenuComponent onMenuClick={onMenuItemClick} />
        </div>
      </Sider>

      <Layout >
        <Header className={styles.header}>
          <HomeHeader />
        </Header>
        <Content className={styles.content}>
          <Suspense fallback={<LoadingComponents />}>
            <Outlet />
          </Suspense>
        </Content>

      </Layout>
    </Layout>
  );
}

export default Home;
