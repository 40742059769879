import CustomerConfirm from "@/common/utils/CustomerConfirm.jsx";
import ApplicationTypeFilter from "@/pages/components/ApplicationTypeFilter/index.jsx";
import ConfirmFooter from "@/pages/components/ConfirmFooter/index.jsx";

import styles from './index.module.scss';
import {useEffect, useRef, useState} from "react";
import {Table} from "antd";
import {getApplicationList, getChannelApplication} from "@/services/application.js";
import {delRepeatData} from "@/lib/utils.js";

function ChoiceApplicationContent({choiceIds, onChange}) {

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const typeInfo = useRef();
  const [currentSelectKeys, setCurrentSelectKeys] = useState([]);

  const pageInfo = useRef({
    pageNum: 1,
    pageSize: 10,
  })

  const columns = [
    {
      title: '应用名称',
      dataIndex: 'name',
      key: 'name',
    }
  ]

  useEffect(() => {
    setCurrentSelectKeys(choiceIds || []);
  }, []);

  useEffect(() => {
    onChange?.(currentSelectKeys);
  }, [currentSelectKeys])

  const getChoiceApplicationList = async (info) => {
      const res = await getApplicationList({
        type:info.key,
        typeSubItem: info.childKey,
        name: info.value,
        createBy: 'KERQU'
      });
      if (res.success) {
        const _list = res.data.map(item => {
          return {
            ...item,
            name: item.name,
            id: item.appId,
            key: item.appId,
          }
        });
        setData(_list);
      }
  }

  return (
    <div className={styles.choiceApplication}>
      <ApplicationTypeFilter
        onChange={(item) => {
          getChoiceApplicationList(item);
        }}
      />

      <div>
        <div className={styles.label}>
          已选 {currentSelectKeys.length} 个应用
        </div>

        <div className={styles.table}>
          <Table
            rowSelection={{
              type: 'checkbox',
              onSelectAll: (selected,selectedRows) => {
                  setCurrentSelectKeys(keys => {
                    if (selected) {
                      return [...keys, ...selectedRows]
                    } else {
                      const _keys = data.map(item => item?.id);
                      return keys.filter(item => _keys.indexOf(item?.id) === -1);
                    }
                  });
              },
              onSelect: (record, selected) => {
                setCurrentSelectKeys(keys => {
                  if (selected) {
                    return [...keys, record]
                  } else {
                    return keys.filter(item => item?.id !== record.id);
                  }
                });
              },
              selectedRowKeys: currentSelectKeys.map(item => item?.id),
            }}
            scroll={{
              y: 300
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            // pagination={{
            //   total: total,
            //   showSizeChanger: false,
            //   showQuickJumper: false,
            //   pageSize: pageInfo.current.pageSize,
            //   onChange: (page) => {
            //     pageInfo.current.pageNum = page;
            //     getChoiceApplicationList();
            //   }
            // }}
          />
        </div>
      </div>
    </div>
  )
}

function ChoiceApplication(choiceIds, onChange) {
  let selectIds = [];

  const modal = CustomerConfirm({
    title: '选择应用',
    icon: null,
    width: 682,
    height: 702,
    content: <ChoiceApplicationContent
      choiceIds={choiceIds.map(item => {
        return {
          ...item,
          id:item.appId
        }
      })}
      onChange={(ids) => {
        const _list = delRepeatData(ids, 'id');
        selectIds = _list;
      }}
    />,
    footer: <ConfirmFooter
      onCancel={() => {
        modal.destroy();
      }}
      onOk={async () => {
        const status = await  onChange(selectIds);
        if (status){
          modal.destroy();
        }
      }}
    />
  });
}

export default ChoiceApplication;