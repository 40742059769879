import {
  ConfigProvider,
  Popover,
  message,
  Divider,
} from 'antd';
import {useEmotionCss} from '@ant-design/use-emotion-css';
import {useState} from 'react';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import EditPassword from '@/pages/components/EditPassword/index.jsx';
import InviteModal from '@/pages/components/InviteModal/index.jsx';

import SvgIcon from '@/pages/components/SvgIcon/index.jsx';
import {useRootContext} from '@/common/RootContext';
import {textHyposensitization} from '@/common/utils/tools';
import {outLogin} from '@/services/common';
import {useT} from '@/common/utils/translation';
import '../../index.module.scss';
import {myCookie} from '@/common/utils/cookie';
import useVipOverdueNote from './VipOverdueInfo';
import {useNavigate} from 'react-router-dom';
import {PrimaryColor} from '@/const/index.jsx';

const HeaderDropdown = () => {
  let t = useT();
  const rootContentData = useRootContext();
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const {contextHolder: vipContextHolder} = useVipOverdueNote();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const userClassname = useEmotionCss(() => {
    return {
      cursor: 'pointer',
    };
  });

  const signOutClassname = useEmotionCss(() => {
    return {
      fontSize: '0.88rem',
      paddingBottom: '6px',
      color: '#ff4d4f',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    };
  });

  const handleSetInviteModalVisible = () => {
    setInviteModalVisible(!inviteModalVisible);
    setOpen(false);
  };

  const signOut = () => {
    outLogin().then((res) => {
      if (res.success) {
        messageApi.success(t('components_exit_successfully'));
      } else {
        messageApi.error(res.message);
      }
      myCookie.delete('kerqu-admin-token');
      myCookie.delete('groupId');
      myCookie.delete('kerqu-group');
      rootContentData.dispatchSetLoginStatus(false);

      navigate({
        pathname: '/',
      });
    });
  };
  return (
      <div>
        <ConfigProvider
            theme={{components: {Button: {defaultColor: PrimaryColor}}}}
        >
          {vipContextHolder}
          {contextHolder}
          <Popover
              placement="bottomRight"
              overlayInnerStyle={{padding: '8px'}}
              content={
                <div>
                  <EditPassword onClickEditPassword={() => {setOpen(false);}}/>
                  <Divider style={{margin: '14px 0'}}/>
                  <div
                      onClick={() => {
                        signOut();
                      }}
                      className={signOutClassname}
                  >
                    <SvgIcon
                        name={'logout'}
                        style={{
                          width: '14px',
                          height: '14px',
                        }}
                    />
                    &nbsp;{t('components_sign_out')}
                  </div>
                </div>
              }
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
          >
            <div className={userClassname}>
              {textHyposensitization(
                  rootContentData?.userInfo?.admin?.phone,
                  3,
                  8,
              )}{' '}
              &nbsp;
              {open ? (
                  <UpOutlined style={{color: 'rgba(0,0,0,0.3)'}}/>
              ) : (
                  <DownOutlined style={{color: 'rgba(0,0,0,0.3)'}}/>
              )}
            </div>
          </Popover>
        </ConfigProvider>
        <InviteModal
            visible={inviteModalVisible}
            onClose={handleSetInviteModalVisible}
        />
      </div>
  );
};

export default HeaderDropdown;
