export default {

  ai_brief_background: '简述背景',
  ai_set_goals: '设定目标',
  ai_set_up_a_role: '设定角色',
  ai_smart_questions: '聪明的提问',
  ai_supplementary_requirements: '补充要求',
  ai_updating: '更新中',

  createCenter_applications_free_questions_and_answers: '次应用/自由问答次数',
  createCenter_creation_record_storage_time: '创作记录保存时间',
  createCenter_custom_apps: '个自定义应用',
  createCenter_generate_pictures: '生成图片',
  createCenter_moon: '月',
  createCenter_indivual: '个',
  createCenter_number_of_accounts: '账号数',
  createCenter_per_month: '每月',
  createCenter_storage: '存储空间',
  createCenter_unlimited: '无限',
  createCenter_unlimited_number_of_custom_apps: '无限自定义应用数量',
  createCenter_zhang_month: '张/月',

  the_variable_in_the_prompt_content_cannot_be_empty: '提示词内容里变量不能为空',
  login_send_the_verification_code: '发送验证码',
  common_login_successful: '登录成功',
  common_please_enter_the_correct_phone_number: '请输入正确手机号',
  common_please_enter_the_phone_number: '请输入手机号码',
  common_please_enter_verification_code: '请输入验证码',
  common_automatically_log_in_within_seven_days: '七天内自动登录',
  common_log_in: '登录',
  common_please_enter_password: '请输入密码',
  common_verification_code_login: '验证码登录',
  common_password_login: '密码登录',
  common_free_registration: '免费注册',
  common_registration_success: '注册成功',
  common_Login_account: '登录账号',
  components_the_verification_code_has_been_sent_please_check_it_carefully_please_do_not_trigger_it_repeatedly_within_1_minute: '验证码已发送，请注意查收；1分钟内请勿反复触发。',
  common_register_an_account: '注册账号',
  common_read_and_agree: '阅读并同意',
  common_user_agreement: '用户协议',
  common_register: '注册',
  common_go_to_login: '前往登录',
  common_reset: '重置',
  common_user_agreement_and_privacy_protection: '用户协议及隐私保护',
  common_agree_and_register: '同意并注册',
  common_disagree: '不同意',

  homeName: '首页',
  talk: '自由对话',
  smartPic: '智能作图',
  test: '测试',
  menu_amazon: '亚马逊',
  menu_article: 'B2B SEO 文章',
  menu_b2c_website_building: 'B2C 建站',
  menu_b2b_website_building: 'B2B 建站',
  menu_build_a_website: '建站',
  menu_create_app: '创建应用',
  menu_creation_center: '创作中心',
  menu_menu_other: '其他',

  menu_foreign_trade: '外贸',
  menu_foreign_trade_development_letter: '外贸开发信',
  menu_independent_station: '独立站',
  menu_independent_station_customer_service: '独立站客服',
  menu_independent_station_product_selection: '独立站选品',
  menu_keyword_planning: '关键词规划',

  menu_other_sumaitong: '速卖通',
  menu_other_etsy: 'Etsy',
  menu_other_shopee: 'Shopee',
  menu_other_ebay: 'Ebay',
  menu_other_picture_analysis: '图片分析',

  menu_me: '我',
  menu_please_select_mode_of_payment: '请选择支付方式',
  menu_listing_creation_and_optimization: 'Listing创建与优化',
  menu_my_applications: '我的应用',
  menu_product_creation: 'B2B 产品创建',
  menu_product_description: '产品描述',
  menu_product_selection_and_development: '选品与开发',
  menu_social_media: '社媒',
  menu_store_operations: '店铺运营',
  menu_ai_generated: 'AI 生成',
  menu_ai_generating: 'AI 生成中',
  menu_ai_results: 'AI 结果',
  menu_app_description_cannot_be_empty: '应用描述不能为空',
  menu_application_description: '应用描述',
  menu_application_information: '应用信息',
  menu_application_add_picture: '是否上传图片',
  menu_application_name: '应用名称',
  menu_application_name_cannot_be_empty: '应用名称不能为空',
  menu_category_selection: '类别选择',
  menu_confirm_release: '确认发布',
  menu_update_application: '更新应用',
  menu_copied_successfully: '复制成功',
  menu_copied_to_clipboard: '已复制到剪贴板',
  menu_created_successfully: '创建成功',
  menu_updated_successfully: '更新成功',
  menu_creator_name: '创作者名称',
  menu_data_returned_successfully: '数据返回成功',
  menu_describe: '描述',

  menu_chinese: '中文',
  menu_english: '英语',
  menu_spanish: '西班牙语',
  menu_german: '德语',
  menu_french: '法语',
  menu_italian: '意大利语',
  menu_japanese: '日语',
  menu_dutch: '荷兰语',
  menu_swedish: '瑞典语',
  menu_portuguese: '葡萄牙语',
  menu_russian: '俄语',
  menu_indonesian: '印尼语',
  menu_arabic: '阿拉伯语',

  menu_insert_variable_parameters: '插入变量参数',
  menu_language: '语言',
  menu_upload_img: '上传图片',

  menu_name: '名称',
  menu_parameter: '参数',
  menu_please_enter_a_description: '请输入描述',
  menu_please_enter_an_application_name: '请输入应用名称',
  menu_please_enter_application_description: '请输入应用描述',
  menu_please_enter_name: '请输入名称',
  menu_please_enter_the_creator_name: '请输入创作者名称',
  menu_please_enter_the_prompt_word_content: '请输入提示词内容',
  menu_please_fill_in_the_parameters_completely: '请参数填写完整!',
  menu_preview: '预览',
  menu_previous: '上一步',
  menu_next_step: '下一步',
  menu_prompt_word_content: '提示词内容',
  menu_prompt_word_content_cannot_be_empty: '提示词内容不能为空',
  menu_translate: '翻译',
  menu_translation_results: '翻译结果',
  menu_usage_limit_reached: '使用权限已达上限',
  menu_use_immediately: '立即使用',
  menu_your_application_has_been_created_successfully_click_the_use_now_button_to_use_it_directly: '您的应用已经创建成功，点击“立即使用”按钮可以直接可直接使用。',
  menu_your_application_has_been_updated_successfully_click_the_use_now_button_to_use_it_directly: '您的应用已经修改成功，点击“立即使用”按钮可以直接可直接使用。',
  menu_your_application_q_a_times_this_month_have_reached_the_limit_you_can_upgrade_your_package_to_continue_using_it: '您本月应用问答次数已达上限，可升级套餐继续使用',

  components_1gb_storage_space: '1GB存储空间',
  components_3_custom_apps: '3个自定义应用',
  components_5_accounts_2000_applications_free_questions_and_answers_300_pictures: '5个账号，2000次应用/自由问答，300张图片',
  components_20_accounts_unlimited_applications_free_questions_and_answers_1_000_pictures: '20个账号，无限次应用/自由问答，1000张图片',
  components_20mb_storage_space: '20MB存储空间',
  components_50_applications_free_questions_and_answers_per_month: '每月50次应用/自由问答次数',
  components_300mb_storage_space: '300MB存储空间',
  components_2000_applications_free_questions_and_answers: '2000次应用/自由问答次数',
  components_add_pictures: '添加图片',
  components_added: '已添加',
  components_administrator: '管理员',
  components_after_deleting_a_member_the_content_created_by_the_member_will_still_be_retained_do_you_want_to_continue_deleting_it: '删除成员后，该成员创建的内容仍将保留，是否继续删除？',
  components_after_inviting_external_people_to_join_the_team_it_becomes: '邀请外部人员加入团队后，成为',
  components_after_switching_identities_the_permissions_of_the_opened_pages_will_be_refreshed_please_ensure_that_the_content_you_write_is_properly_saved: '切换身份后，已打开的页面权限将刷新。请确保撰写的内容已妥善保存。',
  components_ai_picture_creation: 'AI图片创作',
  components_alipay: '支付宝',
  components_annual_fee: '年付',
  components_application_free_q_a_gpt_3_5: 'AI会话',
  components_application_free_q_a_gpt_3_5_tooltip: '本次计费周期已用点数/总点数',
  components_application_free_q_a_gpt_4: '应用/自由问答(GPT-4)',
  components_Each_use_consumes_15_gpt_3_5_usage: 'GPT-3.5每次使用消耗1点，GPT-4每次使用消耗15点，GPT-4 Vision每次使用消耗30点',
  components_application_free_q_gpt_4_vision: '应用/自由问答(GPT-4 vision)',
  components_automatically_cutting_out_pictures: '自动抠图中…',
  components_each_use_consumes_30_gpt_3_5_usage: '每次使用消耗30次GPT-3.5用量',
  components_automatically_extend_the_service_period_of_new_packages_by_73_days: '自动延长新套餐服务期 73 天',
  components_bank_card: '信用卡',
  components_day: '天',
  components_image_generation: '作图',
  components_basic_version: '基础版',
  components_basic_version_vip: '基础版·VIP',
  components_batch_save: '批量保存',
  components_billing_cycle: '计费周期',
  components_buy_it_now: '立即购买',
  components_use_it_now: '免费使用',
  components_vip_package_upgrade: '升级',
  components_vip_package_renewal: '续费',
  components_cancel: '取消',
  components_card_information: '卡信息',
  components_cardholder_s_name: '持卡人姓名',
  components_order_center: '订单中心',
  components_change_password: '修改密码',
  components_click_drag: '点击/拖拽',
  components_click_or_drag_files_into_this_area_to_upload_files: '点击或拖拽文件到此区域以上传文件',
  components_closure: '关闭',
  components_common: '共',
  components_confirm: '确认',
  components_confirm_switch: '切换',
  components_please_select_your_language: '请选择你的语言',
  components_confirm_deletion: '确认删除',
  components_confirm_payment: '确认支付',
  components_confirm_the_changes: '确认修改',
  components_congratulations_you_have_received_1_month_vip_package_basic_version: '恭喜！您已获得1个月VIP套餐·基础版',
  components_copied_successfully: '复制成功',
  components_copy_link: '复制链接',
  components_creation_records_are_kept_for_1_year: '创作记录保存时间1年',
  components_creation_records_are_kept_for_7_days: '创作记录保存时间7天',
  components_creation_records_are_kept_for_10_years: '创作记录保存时间10年',
  components_credit_card: '信用卡',
  components_cutout: '抠图',
  components_debit: '借记卡',
  components_deduction_from_original_package_balance: '原套餐结余扣减',
  components_delete: '删除',
  components_delete_app: '删除应用',
  components_delete_member: '删除成员',
  components_download_package: '打包下载',
  components_exclusive_invitation_link: '专属邀请链接',
  components_exit_successfully: '退出成功',
  components_free_to_use: '免费使用',
  components_free: '免费',
  components_free_version: '免费版',
  components_generate_1_000_pictures_month: '生成图片1000张/月',
  components_generate_10_pictures_month: '生成图片10张/月',
  components_generate_300_pictures_month: '生成图片300张/月',
  components_get_verification_code: '获取验证码',
  components_i_see: '我知道了',
  components_invite_friends: '邀请好友',
  components_invite_friends_to_get_free_vip: '邀请好友免费获得VIP',
  components_invite_members: '邀请成员',
  components_invite_via_link: '通过链接邀请',
  components_join_time: '加入时间',
  components_joined: '已加入',
  components_me: '我',
  components_link_validity_period: '链接有效期：',
  components_log_in_register: '登录注册',
  components_login_successful: '登录成功',
  components_modify_role: '修改角色',
  components_manager: '管理者',
  components_member: '成员',
  components_member_management: '成员管理',
  components_modify_the_note_name: '修改备注名',
  components_monthly_payment: '月付',
  components_monthly_updated_application_free_q_a_picture_usage_quota: '每月更新应用/自由问答，图片使用额度',
  components_most_popular: '最受欢迎',
  components_name: '名称',
  components_new_password: '新密码',
  components_new_password_is_required: '新密码是必填的',
  components_no_more_data: '没有更多数据',
  components_number_of_accounts_1: '账号数1个',
  components_number_of_accounts_5: '账号数5个',
  components_number_of_accounts_20: '账号数20个',
  components_only_owners_and_administrators_can_edit: '只有所有者和管理员可以编辑',
  components_open: '张',
  components_operate: '操作',
  components_owner: '所有者',
  components_package_payment: '套餐支付',
  components_package_version: '套餐版本',
  components_order_type: '订单类型',
  components_pay_immediately: '立即支付',
  components_pay_remaining_time: '支付剩余时间',
  components_payment_failed: '支付失败',
  components_payment_successful: '支付成功',
  components_people: '人',
  components_per_recommendation: '每推荐',
  components_picture_background_change: '图片换背景',
  components_please_enter_card_information: '请输入卡信息',
  components_please_enter_cardholder_name: '请输入持卡人姓名',
  components_please_enter_security_code: '请输入安全码',
  components_please_enter_the_validity_period: '请输入有效期',
  components_please_input_your_email: '请输入邮箱',
  components_please_pay_again: '请重新支付',
  components_please_select_the_appropriate_package_to_upgrade_renew: '请选择合适的套餐进行升级/续费',
  components_please_use_alipay_to_scan_the_qr_code_to_pay: '请使用支付宝扫码支付',
  components_please_use_wechat_to_scan_the_qr_code_to_pay: '请使用微信扫码支付',
  components_preview: '预览',
  components_professional_version: '专业版',
  components_purchase_package_confirmation_information: '购买套餐 确认信息',
  components_register_a_friend_independent_ip_and_you_will_automatically_receive_1_month: '个好友注册（独立IP），即可自动获得1个月',
  components_release_to_load_the_file: '松开即可载入文件',
  components_return: '返回',
  components_role: '角色',
  components_second: '秒',
  components_security_code: '安全码',
  components_send_verification_code_to_mobile_phone: '将验证码发送至手机',
  components_share_creation_application_records: '共享创建应用记录',
  components_share_creative_records: '共享创作记录',
  components_share_free_chat_transcripts: '共享自由对话记录',
  components_sign_out: '退出登录',
  components_smart_background_change: '智能换背景',
  components_smart_cutout: '智能抠图',
  components_subscription_fee: '订阅费',
  components_successful_operation: '操作成功',
  components_support_batch_upload_support_jpg_png_webp_format_images: '支持批量上传，支持 JPG/PNG/WEBP 格式图片',
  components_team_member: '团队成员',
  components_team_name: '团队名称',
  components_team_profile: '团队简介',
  components_team_settings: '团队设置',
  components_the_king_of_cost_performance: '性价比之王',
  components_the_link_is_copied_successfully_and_will_expire_in_14_days: '链接复制成功，14天后过期',
  components_the_copy_link_message: '请按ctrl+v键进行复制',
  components_the_package_you_are_currently_selecting_is: '您当前产品的套餐是',
  components_the_remaining_balance_after_deducting_the_balance_of_your_original_package: '您原套餐结余扣减后还剩结余金额',
  components_the_remaining_service_days_of_the_original_package_will_be_converted_into_a_balance_according_to_the_amount_and_the_original_package_balance_will_be_deducted_from_the_new_package_fee: '原套餐剩余服务天数将按照金额进行结余折算，在新套餐费用中以“原套餐结余”进行扣减。',
  components_timed_out_please_pay_again: '已超时，请重新支付',
  components_total: '总计',
  components_total2: '共',
  components_turn_on: '开启',
  components_unlimited_applications_free_questions_and_answers: '无限次应用/自由问答次数',
  components_unlimited_number_of_custom_applications: '无限次自定义应用数量',
  components_unlimited_number_of_custom_apps: '无限自定义应用数量',
  components_upgrade_package: '升级套餐',
  components_renew: '续费',
  components_upload_team_avatar: '上传团队头像',
  components_validate_the_form_and_return_all_formatted_data: '校验表单并返回格式化后的所有数据：',
  components_validity_period: '有效期',
  components_verification_code: '验证码',
  components_verification_code_error: '验证码错误',
  components_verification_code_is_required: '验证码是必填的',
  components_vip_package: 'VIP套餐',
  application_detail: '套餐详情',
  components_vip_package_basic_version: 'VIP套餐·基础版',
  components_vip_package_at: 'VIP套餐于',
  components_maturity: '到期',
  components_free_upgrade_message: '升级会员更多尊享权益',

  components_vip_package_professional_edition: 'VIP套餐-专业版',
  components_wechat: '微信',
  components_whether_to_switch_teams: '是否切换团队?',
  components_year: '年',
  components_you_have_invited_registered_users: '您已邀请注册用户：',
  components_you_have_successfully_purchased_the_vip_package_professional_version: '您已成功购买 VIP套餐-',
  components_mail: '邮箱',
  button_update_permissions: '升级权限',

  components_image_save: '保存',
  components_image_download: '下载',
  components_image_remove: '删除',

  createCenter_add_pictures: '添加图片',
  createCenter_ai_background_generator_delivers_efficient_results: 'AI背景生成器带来高效的结果',
  createCenter_ai_generated: 'AI生成',
  createCenter_ai_is_generating: 'AI生成中…',
  createCenter_ai_picture_creation: 'AI图片创作',
  createCenter_product_scene_fission: '商品场景裂变',
  createCenter_automatically_cutting_out_pictures: '自动抠图中…',
  createCenter_automatically_generate_article_topics_and_inspiration: '自动生成文章主题和灵感，',
  createCenter_background_selection: '背景选择',
  createCenter_click_the_button_below_or_drag_in_an_image: '点击下方按钮',
  createCenter_click_the_button_below_or_drag_in_an_image2: '，或拖入图片',
  createCenter_confirm_deletion_of_this_creative_content_record: '确认删除该创作内容记录',
  createCenter_content: '内容',
  createCenter_delete: '删除',
  createCenter_describe_your_background_for_example_decorated_christmas_tree_twinkling_lights: '描述你的背景…例如，装饰的圣诞树，闪烁的灯光',
  createCenter_duplicate_check_rate: '查重率',
  createCenter_example: '示例：',
  createCenter_having_trouble_finding_creative_sources_just_enter_the_description_of_the_picture_scene_you_want_and_our_artificial_intelligence_will_quickly_provide_multiple_sets_of_high_quality_pictures: '在寻找创意来源时感到困难吗？只需输入你想要的图片场景描述，我们的人工智能将快速提供多套高质量图片。',
  createCenter_split_subtitle: '上传商品场景参考图，3秒裂变出多张相似的场景图。',
  createCenter_image: '图像',
  createCenter_most_popular: '最流行',
  createCenter_no_more_data: '没有更多数据',
  createCenter_no_more_worrying_about_long_design_cycles_or_high_costs_just_upload_a_photo_and_ai_will_automatically_remove_the_background_and_apply_your_preferred_scene: '不再担心漫长的设计周期或高成本，只需上传一张照片，人工智能将自动删除背景并应用您首选的场景。',
  createCenter_only_supports_jpg_png_webp_type_image_files: '仅支持jpg,png,webp类型的图片文件',
  createCenter_original_proportions: '原始比例',
  createCenter_pictures_cannot_exceed_10m: '图片不能超过10M',
  createCenter_please_enter_an_image_description: '请输入图片描述！',
  createCenter_preview: '预览',
  createCenter_quickly_cut_out_images_in_batches: '批量快速抠图',
  createCenter_release_to_load_the_file: '松开即可载入文件',
  createCenter_scene_description: '场景描述',
  createCenter_delete_record: '删除记录',
  createCenter_seo_article_assistant: 'SEO文章助手',
  createCenter_sexy_woman_walking_on_the_streets_of_new_york_carrying_trolley_suitcase_medium_shot: '行走在纽约街头的性感女性，手提拉杆箱，中景',
  createCenter_size_selection: '尺寸选择',
  createCenter_original_similarity: '与原图相似度',
  createCenter_supports_batch_upload_of_pictures_up_to_100_pictures: '支持批量上传图片，最多100张',
  createCenter_target_word_buried_rate: '目标埋词率，',
  createCenter_trained_on_millions_of_real_world_images_our_clever_ai_can_automatically_handle_almost_any_complex_background_in_3_seconds: '在数百万张现实世界图像上训练，我们聪明的人工智能可以在3秒内自动处理几乎所有复杂的背景。',
  createCenter_whether_it_is_design_creativity_or_artistic_creation_it_can_make_your_creation_easier_and_freer: '无论是设计创意还是艺术创作，都能让您的创作更加轻松和自由。',
  components_member_full1: '当前套餐成员数已达上限，升级后可继续邀请成员',
  components_member_full2: '当前套餐成员数已达上限',
  no_application_record: '没有应用数据记录',
  cross_border_seller_ai_communication_group: '跨境卖家AI交流群',
  createCenter_enhanced_content_description: '强化内容描述',

  talk_point_cost: ' (消耗$1点) ',

  createCenter_accept_an_invitation: '接受邀请',
  createCenter_amazon_operations: '亚马逊运营',
  createCenter_are_you_sure_you_want_to_delete_this_conversation: '确定要删除这个会话吗？',
  createCenter_create_app: '创建应用',
  createCenter_delete_delete: '删除应用',
  createCenter_edit_application: '修改应用',
  createCenter_expire_after: '后失效',
  createCenter_gives_inspiration_for_niche_products_in_ten_outdoor_categories_mainly_targeting_middle_and_high_income_groups_in_the_united_states_suitable_for_sale_on_amazon: '给出十个户外品类的小众产品灵感，主要针对美国中高收入群体，适合在amazon上售卖',
  createCenter_go_to_use: '前往使用',
  createCenter_hint: '提示',
  createCenter_history_record: '历史记录',
  createCenter_independent_website_search_engine_optimization_seo: '独立站搜索引擎优化(SEO)',
  createCenter_independent_websites_and_social_media: '独立站及社媒',
  createCenter_invite_you_to_join: '邀请您加入',
  createCenter_join_in: '加入',
  createCenter_my_applications: '我的应用',
  createCenter_please_enter_custom_writing_instructions: '请输入自定义写作指令',
  createCenter_please_enter_your_name_in_the_team: '请输入你在团队中的备注名',
  createCenter_please_give_me_ten_english_brand_names_for_my_ebike_products_the_style_is_technological_and_futuristic: '请给我的ebike产品起十个英文品牌名，风格是科技感和未来感',
  createCenter_please_help_me_list_10_target_groups_for_ebike_products_and_describe_their_characteristics_including_age_gender_and_other_characteristics: '请帮我列举10个ebike 产品的目标人群，描述人群特征，包括年龄，性别和其他特征',
  createCenter_please_plan_facebook_posts_for_my_ebike_products_in_the_next_month_in_english: '请为我的ebike产品规划未来1个月的facebook posts, 用英文',
  createCenter_please_write_an_english_about_us_document_for_my_ebike_independent_station: '请给我的ebike独立站写一篇英文的 About Us文档',
  createCenter_please_write_an_seo_article_about_ebike_products_the_main_selling_points_of_the_product_are_environmental_protection_long_battery_life_and_portability_the_output_language_is_english_800_words: '请写一篇关于ebike产品的SEO文章，产品的主要卖点是环保，长续航和轻便，输出语言是英文，800字',
  createCenter_remark_name: '备注名',
  createCenter_team: '团队',
  createCenter_the_current_invitation_will_be: '当前邀请将于',
  createCenter_the_team_is_successful: '团队成功',
  createCenter__the_mobile_phone_number_to_join_is: '加入手机号为',
  createCenter_the_team_is_successful_the_mobile_phone_number_to_join_is: '团队成功，加入手机号为',
  createCenter_this_page_is_missing: '这个页面不见了',
  createCenter_welcome_to_kerqu_ai_for_free_conversation1: '欢迎使用',
  createCenter_welcome_to_kerqu_ai_for_free_conversation2: 'Kerqu.Ai',
  createCenter_welcome_to_kerqu_ai_for_free_conversation3: '自由对话',
  createCenter_write_down_a_task_and_kua_ai_will_do_the_rest_for_you_not_sure_where_to_start_click_on_the_cross_border_cases_below_for_inspiration: '写下一个任务，Kerqu.Ai可以帮你完成其余的工作。不确定从哪里开始？点击下方跨境案例以获取灵感',

  createCenter_no_conversation: '暂无历史记录',
  createCenter_upload_reference_image: '上传参考图',
  createCenter_specify_scene: '指定场景',
  createCenter_click_to_generate: '点击生成',
  createCenter_split_step_desc1: '上传你需要进行裂变的图片',
  createCenter_split_step_desc2: '在图片描述中输入你上传的图片中的元素',
  createCenter_split_step_desc3: '裂变完成，可批量下载',
};