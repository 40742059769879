import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import l_zh from '../const/locales/zh';
import l_en from '../const/locales/en';
import stores from "../common/utils/stores.js";

const resources = {
    // 语言资源文件
    en: {
        translation: {
            // 英文翻译内容
            ...l_en,
        }
    },
    zh: {
        translation: {
            // 中文翻译内容
            ...l_zh,
        }
    }
};

const defaultLng = stores.getLocalStorage('languageType');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultLng || 'zh', // 默认语言
        fallbackLng: "zh", // 如果找不到对应语言，默认使用的语言
        interpolation: {
            escapeValue: false // 不转义特殊字符
        }
    });

export default i18n;