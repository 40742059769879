import {useT} from '@/common/utils/translation';
import {Button, Result} from 'antd';

const Error = (props) => {
  let t = useT();
  return (
      <Result
          status="error"
          title={t('components_payment_failed')}
          subTitle={t('components_please_pay_again')}
          extra={[
            <Button key="buy" style={{width: '200px'}}
                    onClick={() => props.onBack()}>{t(
                'components_return')}</Button>,
          ]}
      >
      </Result>
  );
};

export default Error;