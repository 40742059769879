import {Input, Space, Tabs, Tag} from "antd";
import styles from "@/pages/CustomerApplication/index.module.scss";
import React, {useEffect, useRef, useState} from "react";
import typeMenu from "@/pages/AddApplication/ChoiceType/const.jsx";
import {useNavigate} from "react-router-dom";

const {CheckableTag} = Tag;
const {Search} = Input;

function ApplicationTypeFilter({onChange, value}){

  const [currentTabKey, setCurrentTabKey] = useState(typeMenu[0].key);
  const [childList, setChildList] = useState(typeMenu[0].children);
  const [childKey, setChildKey] = useState(typeMenu[0].children[0].key);
  const [searchValue, setSearchValue] = useState('');
  const tabsInfo = useRef({key: typeMenu[0].key , childKey: typeMenu[0].children[0].key, value: ''});

  useEffect(() => {
    if(
      value && (
        currentTabKey !== value?.key ||
        childKey !== value?.childKey
      )
    ){
      setCurrentTabKey(value.key);
      const item = typeMenu.find(item => item.key === value.key);
      setChildList(item.children || []);
      setChildKey(value.childKey);
      tabsInfo.current.key = value.key
      tabsInfo.current.childKey = value.childKey
      getList();
    } else {
      getList();
    }
  }, [value])

  const items = typeMenu.map(item => {
    return {
      label: item.label,
      key: item.key
    }
  });

  const getList = () => {
    onChange?.(tabsInfo.current)
  }

  const onTabChange = (key) => {
    setCurrentTabKey(key);

    const item = typeMenu.find(item => item.key === key);
    setChildList(item.children || []);
    setChildKey(item.children[0].key);
    tabsInfo.current = {
      key,
      childKey: item.children[0].key,
      value: '',
    };
    getList();
  }


  const onSearch = (value) => {
    setSearchValue(value);

    tabsInfo.current.value = value;
    getList();
  }

    return (
      <div
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Tabs
          items={items}
          onChange={onTabChange}
          activeKey={currentTabKey}
        />

        <div className={styles.child}>
          <Space>
            {
              childList.map(item => {
                return (
                  <CheckableTag
                    key={item.key}
                    style={{height:'28px', lineHeight:'28px'}}
                    checked={childKey === item.key}
                    onChange={() => {
                      setChildKey(item.key);
                      tabsInfo.current.childKey = item.key;
                      getList();
                    }}
                  >
                    {
                      item.label
                    }
                  </CheckableTag>
                )
              })
            }
          </Space>
        </div>

        <div style={{marginTop: '24px'}}>
          <Search
            addonBefore={'应用名称'}
            placeholder={'搜索应用名称'}
            allowClear
            value={searchValue}
            style={{width: '310px'}}
            onSearch={onSearch}
            onChange={(ev) => {
              setSearchValue(ev.target.value)
            }}
          />
        </div>
      </div>
    )

}

export default ApplicationTypeFilter;