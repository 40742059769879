import SvgIcon from '@/pages/components/SvgIcon';
import {ActionSheet, Button, Popup, Space} from 'antd-mobile';
import {useState, useContext, useEffect} from 'react';
import styles from './index.module.scss';
import {languageMap} from '@/const/index.jsx';
import {useMount} from 'ahooks';
import stores from '@/common/utils/stores';
import {useTranslation} from 'react-i18next';
import MenuComponent from '@/pages/components/MenuComponent';
import SliderUser from '@/pages/components/SliderUser';
import HeaderDropdown from '@/h5Pages/components/H5Header/HeaderDropdown.jsx';
import {VipContext} from '@/context/vip/index.jsx';
import {Popover} from 'antd';
import QrComponent from '@/pages/components/HomeHeader/QrComponent.jsx';
import VipModulePackageModal from '@/pages/components/VipPackageModal/index';
import {useT} from '@/common/utils/translation';
import {useRootContext} from '@/common/RootContext.jsx';
import useIsMobile from '@/common/hooks/useIsMobile';
import {checkNeedReload} from '@/const/tool.js';
import {useLocation} from 'react-router-dom';
import {currentPlan} from '@/services/vip.js';

function H5Header() {
  let t = useT();
  const rootContentData = useRootContext();
  const [language, setLanguage] = useState('zh');
  const [visible, setVisible] = useState(false);
  const [actions, setActions] = useState([]);
  const {i18n} = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const {dispatchSetVipModalVisible} = useContext(VipContext);
  const [isMobile] = useIsMobile();
  const location = useLocation();

  useMount(() => {

    const _language = stores.getLocalStorage('languageType');
    setLanguage(_language);

    const list = [];
    for (let key in languageMap) {
      const item = languageMap[key];
      list.push(item);
    }
    setActions(list);
  });

  const onAction = (value) => {
    setLanguage(value.key);
    i18n.changeLanguage(value.key);
    stores.saveLocalStorage('languageType', value.key);
    setVisible(false);

    if (checkNeedReload(location)) {
      window.location.reload();
    }
  };

  const openVipInfo = () => {
    dispatchSetVipModalVisible();
  };

  useEffect(() => {

  }, [visible]);

  return (
      <div className={styles.container}>
        <div className={styles.left}>
          <Space size={18}>
            <SvgIcon
                name={'h5Menu'}
                style={{height: '1.33rem', width: '1.33rem'}}
                onClick={() => {
                  setMenuVisible(true);
                }}
            />

            <div className={styles.qrButton}>
              <Popover placement="bottom" content={<QrComponent/>}>
                <SvgIcon name={'qr'} style={{
                  height: '1.33rem',
                  width: '1.33rem',
                  color: 'rgba(0,0,0,0.88)',
                }}/>
              </Popover>
            </div>
          </Space>
        </div>

        <div className={styles.right}>
          <Space
              className={styles.languageChange}
          >
            <Button
                className={styles.vipButton}
                shape={'rounded'}
                color={'primary'}
                size={'small'}
                style={{
                  padding: isMobile ? '0.1rem' : '',
                  width: language === 'zh' ? '3.8rem' : '5.8rem',
                }}
                onClick={openVipInfo}
            >
              {t('components_vip_package')}
            </Button>

            <Button
                className={styles.languageButton}
                style={{
                  padding: isMobile ? '0.1rem' : '',
                  width: language === 'zh' ? '5rem' : '5.8rem',
                }}
                onClick={() => {
                  setVisible(true);
                }}
            >
              <Space>
                {languageMap[language]?.text}
                <SvgIcon
                    name={'downArrow'}
                    className={`${styles.iconStyle} ${visible
                        ? styles.rotate180
                        : ''}`}
                    style={{height: '0.75rem', width: '0.4375rem'}}
                />
              </Space>
            </Button>

            <HeaderDropdown/>

          </Space>
        </div>

        {/*<div className={styles.arrowStyle}>*/}
        <ActionSheet
            extra={t('components_please_select_your_language')}
            cancelText={t('components_cancel')}
            onText={t('components_confirm')}
            visible={visible}
            actions={actions}
            onAction={onAction}
            onClose={() => setVisible(false)}
        />
        {/*</div>*/}

        {
            menuVisible &&
            <Popup
                visible={menuVisible}
                onMaskClick={() => {
                  setMenuVisible(false);
                }}
                position="left"
                bodyStyle={{width: '60vw'}}
            >
              <div className={styles.popContainer}>
                <div
                    className={styles.leftMenu}
                    style={{
                      height: !rootContentData.loginStatus ? '100vh' : '',
                    }}
                >
                  <MenuComponent
                      onMenuClick={(item) => {
                        setMenuVisible(false);
                        rootContentData.eventEmitter.emit('menu-item-click', {
                          key: item.key,
                          keyPath: item.keyPath,
                        });
                      }}
                  />
                </div>
                {
                    rootContentData.loginStatus && (
                        <SliderUser/>
                    )
                }
              </div>
            </Popup>
        }

        <VipModulePackageModal/>
      </div>
  );
}

export default H5Header;