import { Button, Divider, Flex, Radio } from "antd";
import DescriptionItem from "@/pages/components/SettlementBoard/DescriptionItem.jsx";
import SvgIcon from "@/pages/components/SvgIcon/index.jsx";
import useStyle from "@/pages/components/SettlementBoard/hooks/useStyle.js";
import { useT } from "@/common/utils/translation";
import { usePackageTitle } from "./index";
import useVipPackageBtnName from "@/hooks/useVipPackageBtnName.js";

function usePackageType(type, value) {
  let t = useT();
  switch (type) {
    case "ANNUAL":
      return value ? t("components_annual_fee") : t("components_year");
    case "MONTHLY":
      return value ? t("components_monthly_payment") : t("createCenter_moon");
    case "FREE":
      return t("components_free");
  }
}

const SettlementDetail = (props) => {
  let t = useT();

  let packageType = usePackageType;
  const { planBoxClassname, rightClassname, paymentCardClassname } = useStyle();
  const { vipPackageBtnName } = useVipPackageBtnName();

  let { vipPackage, payPriceRMB, payPrice, packageExtensionTime,packagePrice } =
    props.vipInfo;
  let price = payPrice ?? 0;
  console.log("props.vipInfo", props.vipInfo);
  let packageTitle = usePackageTitle();

  return (
    <div>
      <div className="title">
        {t("components_purchase_package_confirmation_information")}
      </div>
      {vipPackageBtnName[vipPackage.packageVersion]?.value === "upgrade" ? (
        <div className="sub-title">
          {t(
            "components_the_remaining_service_days_of_the_original_package_will_be_converted_into_a_balance_according_to_the_amount_and_the_original_package_balance_will_be_deducted_from_the_new_package_fee"
          )}
        </div>
      ) : (
        <div style={{ marginBottom: "1rem" }}></div>
      )}
      <Flex gap={24}>
        <div className={planBoxClassname}>
          <div style={{ fontWeight: "500", fontSize: "18px" }}>
            {packageTitle(vipPackage.packageVersion)}
          </div>
          <div style={{ marginTop: "24px", fontSize: "1.5rem" }}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "2rem",
              }}
            >
              ${packagePrice}
            </span>
            /
            <span
              style={{
                fontSize: "1rem",
              }}
            >
              {packageType(vipPackage.packageType)}
            </span>
          </div>
        </div>
        <div className={rightClassname}>
          <Divider style={{ margin: "0 0 16px 0" }} />
          <DescriptionItem
            title={t("components_package_version")}
            value={`${t("components_vip_package")}-${packageTitle(
              vipPackage.packageVersion
            )}`}
          />
          <DescriptionItem
            title={t("components_order_type")}
            value={`${vipPackageBtnName[vipPackage.packageVersion]?.name}`}
          />
          <DescriptionItem
            title={t("components_subscription_fee")}
            value={`$${packagePrice}`}
          />
          <DescriptionItem
            title={t("components_billing_cycle")}
            value={packageType(vipPackage.packageType, true)}
          />
          {vipPackageBtnName[vipPackage.packageVersion]?.value === "upgrade" ? (
            <DescriptionItem
              style={{ marginBottom: "37px" }}
              title={t("components_deduction_from_original_package_balance")}
              value={
                props.vipInfo.oldPackageDeductionAmount
                  ? `-$${props.vipInfo.oldPackageDeductionAmount}`
                  : `$${props.vipInfo.oldPackageDeductionAmount}`
              }
            />
          ) : null}
          <Divider style={{ margin: "0 0 18px 0" }} />
          <DescriptionItem
            align="center"
            title={t("components_total")}
            value={
              <span style={{ fontSize: 20 }}>{`$${
                price >= 0 ? price : 0
              }(￥${payPriceRMB})`}</span>
            }
          />
          <Divider style={{ margin: "0 0 20px 0" }} />
          {/* {price <= 0 ? (
            <div className="final-desc">
              {t(
                "components_the_remaining_balance_after_deducting_the_balance_of_your_original_package"
              )}{" "}
              $
              {(props.vipInfo.oldPackageDeductionAmount ?? 0) -
                (props.vipInfo.packagePrice ?? 0)}{" "}
              ，
              {`${t(
                "components_automatically_extend_the_service_period_of_new_packages_by_73_days"
              )} ${packageExtensionTime ?? 0} 天`}
            </div>
          ) : null} */}

          <Radio.Group onChange={props.onPaymentChange} value={props.payment}>
            <Flex justify="space-between" wrap={"wrap"} gap={12}>
              <div
                className={paymentCardClassname}
                data-value={"ALIPAY"}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: { value: e.target.dataset.value },
                  });
                }}
              >
                <Radio value={"ALIPAY"} className="payment-card-radio">
                  <Flex align="center">
                    <SvgIcon name="alipay" />
                    <div className="payment-card-label">
                      {t("components_alipay")}
                    </div>
                  </Flex>
                </Radio>
              </div>
              <div
                data-value={"WECHAT"}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: { value: e.target.dataset.value },
                  });
                }}
              >
                <Radio value={"WECHAT"} className="payment-card-radio">
                  <Flex align="center">
                    <SvgIcon name="wechatpay" />
                    <div className="payment-card-label">
                      {t("components_wechat")}
                    </div>
                  </Flex>
                </Radio>
              </div>
              <div
                data-value={"STRIPE"}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: { value: e.target.dataset.value },
                  });
                }}
              >
                <Radio value={"STRIPE"} className="payment-card-radio">
                  <Flex align="center">
                    <SvgIcon name="cardpay" />
                    <div className="payment-card-label">
                      {t("components_bank_card")}
                    </div>
                  </Flex>
                </Radio>
              </div>
              <div
                data-value={"PAYPAL"}
                className={paymentCardClassname}
                onClick={(e) => {
                  props.onPaymentChange({
                    target: { value: e.target.dataset.value },
                  });
                }}
              >
                <Radio value={"PAYPAL"} className="payment-card-radio">
                  <Flex align="center">
                    <SvgIcon name="paypal" />
                    <div className="payment-card-label">Paypal</div>
                  </Flex>
                </Radio>
              </div>
            </Flex>
          </Radio.Group>
          <Button
            type="primary"
            size="large"
            className="w-full"
            style={{ marginTop: "1.5rem" }}
            onClick={props.pay}
          >
            {t("components_confirm_payment")}
          </Button>
        </div>
      </Flex>
    </div>
  );
};

export default SettlementDetail;
