import SvgIcon from "@/pages/components/SvgIcon/index.jsx";
import styles from './index.module.scss';
import React from "react";

function CopyButton({onClick}){

  const onCopy = () => {
    onClick?.();
  }

  return (
    <div onClick={onCopy} className={styles.copyContainer}>
      <SvgIcon name={'copy'} style={{width: '24px', height:'24px'}} />
    </div>
  )
}

export default CopyButton;