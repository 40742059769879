import axios from 'axios';
import {NOT_LOGIN, PACKAGE_EQUITY_ERROR, TOKEN_INVALID} from '@/services/const.js';
import CustomerConfirm from '@/common/utils/CustomerConfirm.jsx';
import { myCookie } from '@/common/utils/cookie';
import { allLoginVisible } from '@/common/RootContext';

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
// export const API_BASE_URL = 'https://kerqu-ai-kerqu-ai-dev-shahgltcfp.cn-hangzhou.fcapp.run';
// const API_BASE_URL1 = 'https://kerqu-dtc-dev-kerqu-dtc-test-fcmoxdaqtz.cn-hangzhou.fcapp.run';
// 创建Axios实例
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const groupId = myCookie.get('groupId');
    if (groupId) {
      config.headers['kerqu-group'] = groupId;
    }
    const traderToken = myCookie.get('kerqu-admin-token');
    if (traderToken) {
      config.headers['kerqu-admin-token'] = traderToken;
    }

    if (config.responseType) {
      config.headers['responseType'] = config.responseType;
    }

    // config.headers['kerqu-domain'] = 'test';
    // config.headers['traderId'] = 47; // 需要移除的部分
    // config.headers['phone'] = 15939969105;  // 需要移除的部分

    // 在发送请求之前做些什么，例如添加身份验证信息等
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    if (!response?.data?.success) {
      const {code} = response.data;

      if (code === PACKAGE_EQUITY_ERROR) {
        CustomerConfirm({
          title: '套餐详情',
          // content: t('menu_your_application_q_a_times_this_month_have_reached_the_limit_you_can_upgrade_your_package_to_continue_using_it'),
          content: response.data.message,
          onOk: () => {

          },
        });
      } else if (code === NOT_LOGIN || code === TOKEN_INVALID) {
        // window.location.href = "/login";
        allLoginVisible(true)
      }
    }
    // 对响应数据做些什么
    return response;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  },
);

// 封装常用的请求方法
const request = {
  get: async (url, params) => {
    try {
      const response = await axiosInstance.get(url, {params});
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  post: async (url, data, options) => {
    try {
      const response = await axiosInstance.post(url, data, options);
      if (options?.fullResponse) {
        return response;
      }
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  delete: async (url, data) => {
    try {
      const response = await axiosInstance.delete(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  // 其他HTTP方法的封装方法，例如put、delete等
};

export function getFetch(url, config) {
  const cfg = {...config};
  if (!cfg.headers) {
    cfg.headers = {};
  }
  if (typeof cfg.headers['Content-Type'] === 'undefined') {
    cfg.headers['Content-Type'] = 'application/json';
  }
  if (cfg.headers['Content-Type'] === 'application/json' &&
    typeof config.body === 'object') {
    cfg.body = JSON.stringify(config.body);
  }
  const groupId = sessionStorage.getItem('groupId');
  if (groupId) {
    cfg.headers['kerqu-group'] = groupId;
  }

  const traderToken = sessionStorage.getItem('kerquTraderToken');
  if (traderToken) {
    cfg.headers['kerqu-admin-token'] = traderToken;
  }

  if (config.responseType) {
    cfg.headers['responseType'] = config.responseType;
  }
  return fetch(API_BASE_URL + '/' + url, cfg);
}

export default request;
