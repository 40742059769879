

import { Avatar } from "antd"
import {getOssRedirectUrl} from "@/lib/utils.js";

const OssAvatar = ({ src, ...props }) => {
    const url = getOssRedirectUrl(src)
    return <Avatar  
        src={url}
        style={{
            "background":  src ? `rgba(0, 0, 0, 0.06)` : "#67A6FF"
        }}
        {...props}></Avatar>
}
export default OssAvatar