export const languageMap = {
  'en': {
    text: 'English',
    key:'en',
  },
  'zh': {
    text: '简体中文',
    key: 'zh'
  }
}

export const PrimaryColor = '#7860FE';
export const PrimaryBgColor = 'rgb(245, 240, 255)';