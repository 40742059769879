import request from '@/services/index.jsx';
import {API_BASE_URL} from '@/services/index';
import {myCookie} from '@/common/utils/cookie.js';
import stores from '@/common/utils/stores.js';

export const getMyApplicationList = (pageNum, pageSize) => {
  return request.get('/api/app/listMyPage', {pageNum, pageSize});
};

export const deleteApplication = (id) => {
  return request.delete(`/api/app/delete/${id}`);
};

export const addApplication = (params) => {
  return request.post(`/api/app/add`, params);
};

// 新增一的参数，唯独多了一个id
export const updateApplication = (params) => {
  return request.post(`/api/app/edit`, params);
};

// 获取 渠道应用列表
export const getChannelApplication = (pageNum, pageSize, typeSubItem, type) => {
  return request.get(`/api/app/listPage`,
    {pageNum, pageSize, typeSubItem, type});
};

// /api/app/getById
export const getApplicationDetailById = (id) => {
  return request.get(`/api/app/getById`, {id});
};

/**
 *
 * @return {Promise<any|undefined>}
 */
export const getRecommendation = () => {
  return request.get(`/api/app/listRecommendation`);
};

export const getDetailHistoryById = (id) => {
  return request.get(`/api/creative/textDetail`, {id});
};

// AI分析结果
// {
//   "id": 0,
//   "params": [
//   {
//     "tag": "string",
//     "value": "string"
//   }
// ],
//   "model": "GPT35"
// }
export const getGenerate = (data, callBack) => {
  const controller = new AbortController();
// 从AbortController获取AbortSignal对象
  const signal = controller.signal;

  const lang = stores.getLocalStorage('languageType') || 'zh';
  let url = `${API_BASE_URL}/api/app/generate`;
  url += `?lang=${lang === 'zh' ? 'zh_CN' : 'en_US'}`;

  fetch(url, {
    signal,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'kerqu-admin-token': myCookie.get('kerqu-admin-token'),
      'kerqu-group': myCookie.get('groupId'),
    },
    body: JSON.stringify(data),
  }).then(response => {
    const reader = response.body.getReader();
    return new ReadableStream({
      start(controller) {
        function pump() {
          return reader.read().then(({done, value}) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            return pump();
          });
        }

        return pump();
      },
    });
  }).then(stream => {
    // 处理流数据
    const reader = stream.getReader();

    function processStreamData() {
      return reader.read().then(({done, value}) => {
        if (done) {
          callBack?.('', true);
          return;
        }
        // 处理每个数据块
        // console.log('收到数据块：', value);

        const decoder = new TextDecoder();
        const decodedString = decoder.decode(value);

        // const str = String.fromCharCode.apply(null, value);
        callBack?.(decodedString, false);

        return processStreamData();
      });
    }

    return processStreamData();
  }).catch(error => {
    console.error('发生错误：', error);
  });

  return controller;
  // return request.post(`/api/app/generate`, data, {
  //   responseType: 'application/json',
  //   fullResponse: true
  // });
};

// 文本翻译
export const translateText = (text, targetLang = 'zh') => {
  return request.post(`/api/language/translate`, {text, targetLang});
};

// 添加渠道 应用
export const addChannelApplication = (params) => {
  // return request.post(`/api/develop/app/channel/add`, params);
  return request.post(`/api/apps/channel/create`, params);
};

// 编辑渠道 应用
export const updateChannelApplication = (params) => {
  // return request.post(`/api/develop/edit`, params);
  return request.put(`/api/apps/channel/modify/${params.id}`, params);
};

// 根据渠道 应用 id 获取 渠道应用详情
export const getChannelApplicationById = (id) => {
  return request.get(`/api/app/getChannelById`, {id});
};

// 删除渠道 应用
export const deleteChannelApplicationById = (id) => {
  return request.get(` /api/channel/apps/delete/${id}`);
}

// 获取渠道应用详情
export const getChannelAppDetailById = (id) => {
  return request.get(`/api/channel/apps/get/detail/${id}`);
}


// 获取 首页 分组列表
export const getHomeApplicationTypeList = () => {
  return request.get(`/api/index/apps/group/list`);
}

// 首页 发布
export const onPublishHomeApplication = (data) => {
  return request.put(`/api/index/apps/release`, data);
}

//  新增 首页分组
export const addHomePageType = (title) => {
  return request.post(`/api/index/apps/group/add?title=${title}`);
}

//  删除 首页分组
export const deleteHomePageType = (id) => {
  return request.delete(`/api/index/apps/group/delete/${id}`);
}

//  更新 首页分组
export const updateHomePageType = (id, title) => {
  return request.put(`/api/index/apps/group/modify/${id}?title=${title}`);
}

// 获取分组应用
export const getHomePageApplications = (groupId) => {
  return request.get(`/api/index/apps/group/queryApp/${groupId}`);
}

// 更新分组应用
export const updateHomePageApplications = (groupId, appId) => {
  return request.put(`/api/index/apps/group/addApp/${groupId}?appId=${appId}`);
}

// 首页 分组 类型 顺序修改
export const updateHomeTypeSort = (groupId => {
  return request.put(`/api/index/apps/group/sort?groupId=${groupId}`);
})

// 获取 客户 应用
export const getCustomAppList = ({type, title, sybType}) => {
  return request.get(`/api/custom/apps/list`, {
    type, title, sybType
  });
}

// 获取渠道应用 的 导入模版
export const downloadChannelTemplate = () => {
  return request.get(`/api/apps/get/template`);
}

// 导入 渠道应用
export const importChannelApp = (cover, data) => {
  return request.post(`/api/apps/channel/export?overwrite=${cover}`, data, {
    // responseType: 'blob',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    disabledAutoMessage: true,
  });
}

// 获取 应用 列表
/**
 * type
 * string
 * 必需
 * 应用类型 AMAZON :亚马逊
 * INDEPENDENT_STATION :独立站
 * SOCIAL_MEDIA :社媒
 * FOREIGN_TRADE :外贸
 * OTHER :其他
 *
 * typeSubItem
 * string
 * 子类型
 * 可选
 *
 * name
 * string
 * 应用名称
 * 可选
 *
 * createBy
 * string
 * 必需
 * 创建者
 * KERQU: 渠道应用
 * CUSTOMER: 客户应用 KERQU :KERQU
 * CUSTOMER :CUSTOMER
 * */
export const getApplicationList = (data) => {
  return request.get(`/api/apps/list`, data);
}

export const getApplicationDetail = (appId, groupId) => {
  return request.get(`/api/apps/get/detail/${appId}?groupId=${groupId}`);
}

// 删除 渠道应用
export const deleteChannelApplication = (appId) => {
  return request.delete(`/api/apps/channel/delete/${appId}`);
}

// 修改渠道应用的顺序
export const changeChannelApplicationSort = (data) => {
  return request.put('/api/apps/channel/sort', data)
}