import styles from './index.module.scss';
import {DashOutlined, GooglePlusOutlined, MoreOutlined} from "@ant-design/icons";
import {Typography, Dropdown, Button} from 'antd';

const {Paragraph, Text} = Typography;

import editImg from '@/assets/images/common/edit.png';
import deleteImg from '@/assets/images/common/delete.png';
import React, {useEffect, useState} from "react";
import typeMenu, {typeToSvgName} from "@/pages/AddApplication/ChoiceType/const.jsx";
import SvgIcon from "@/pages/components/SvgIcon/index.jsx";
import {useT} from '@/common/utils/translation';
import useIsMobile from "@/common/hooks/useIsMobile.js";

function ApplicationCard({
                           cardInfo = {},
                           editAble,
                           editDelete,
                           className,
                           onClick,
                           onEdit,
                           style,
                           sortHandRender
                         }) {
  const [icon, setIcon] = useState();
  const [isMobile] = useIsMobile();

  let t = useT()
  useEffect(() => {
    getIcon();
  }, [cardInfo]);

  const getIcon = () => {
    const {type} = cardInfo || {};
    const iconName = typeToSvgName[type?.type];
    setIcon(iconName);
  }

  const items = [
    {
      label: <Text
        type="link"
        style={{display: 'flex', alignItems: 'center'}}
        onClick={(event) => {
          event.stopPropagation();
          onEdit?.('edit');
        }}
      >
        <img src={editImg} style={{height: '16px', width: '16px', marginRight: '8px'}}/>
        {t('createCenter_edit_application')}
      </Text>,
      key: '0',
    },
    {
      label: <Text
        type="danger"
        style={{display: 'flex', alignItems: 'center'}}
        onClick={(event) => {
          event.stopPropagation();
          onEdit?.('delete');
        }}
      >
        <img src={deleteImg} style={{height: '16px', width: '16px', marginRight: '8px'}}/>
        {t('createCenter_delete_delete')}
      </Text>,
      key: '1',
    },
  ];

  return (
    <div
      className={`${styles.container} ${className} ${onClick ? styles.hoverStyle : ''}`}
      style={{cursor: onClick ? 'pointer' : '', ...style}}

      onClick={() => {
        onClick?.();
      }}
    >
      <div>
        <div>
          <div className={styles.dragHand}>
            {
              sortHandRender
            }
          </div>
          <div style={{height: '40px'}}>
            <SvgIcon name={icon} style={{height: '40px', width: '40px'}}/>
          </div>

          <Paragraph ellipsis={{rows: 1}} className={styles.cardTitle}>
            {cardInfo.name}
          </Paragraph>

          <Paragraph ellipsis={{rows: 3}} className={styles.cardSubTitle}>
            {cardInfo.description}
          </Paragraph>
        </div>

        <div className={`${styles.flexBetween}  ${styles.bottomStyle}`}>
          <div
            style={{
              color: 'rgba(0,0,0,0.65)',
              fontSize: isMobile ? '12px' : '12px'
            }}
            className={styles.createBy}
          >
            {cardInfo.createBy}
          </div>
          <div onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}>
            {
              (editAble || editDelete) &&
              <Dropdown menu={{
                items: items.filter(item => {
                  return editDelete ? item.key === '1' : true;
                })
              }}>
                <span className={styles.editButton}>
                  <MoreOutlined style={{fontSize: '14px', transform: 'rotate(90deg)'}}/>
                  {/*<SvgIcon name={'threePoints'} style={{width: '14px', height: '14px'}}/>*/}
                </span>
              </Dropdown>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationCard;