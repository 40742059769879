class cacheMenuInfo{
  menuInfo = null;

  serMenuInfo(menu){
    this.menuInfo = menu;
  }

  getCacheInfo(){
    return this.menuInfo;
  }

}
const menuInstance =  new cacheMenuInfo();
export default menuInstance;