import {useMemo} from 'react';
import {useRootContext} from '@/common/RootContext';
import {useTranslation} from 'react-i18next';

// 套餐版本：0免费版，1基础班，2专业版
export const VIP_PACKAGE_VERSION = {
  FREE: 'FREE',
  BASIC: 'STANDARD',
  PRO: 'PROFESSIONAL',
};

/**
 * 根据当前用户的版本展示
 * 免费版  按照原本的逻辑展示
 * 基础版:   免费版 免费使用 可点，点击关闭弹窗  ; 基础版 展示 续费; 专业版 展示升级
 * 专业版: 免费版 点击关闭弹窗，基础版，展示续费， 专业版展示续费
 */
const useVipPackageBtnName = () => {
  const {t} = useTranslation();
  const {userCurrentPlan = {}} = useRootContext();
  const {vipPackage = {}} = userCurrentPlan;
  let {packageVersion} = vipPackage;

  const BTN_DEFAULT_CONFIG = useMemo(() => {
    return {
      [VIP_PACKAGE_VERSION.FREE]: {
        name: t('components_use_it_now'),
        value: 'now',
      },
      [VIP_PACKAGE_VERSION.BASIC]: {
        name: t('components_buy_it_now'),
        value: 'now',
      },
      [VIP_PACKAGE_VERSION.PRO]: {
        name: t('components_buy_it_now'),
        value: 'now',
      },
    };
  }, [t]);

  const vipPackageBtnName = useMemo(() => {
    //免费版  按照原本的逻辑展示
    if (packageVersion === VIP_PACKAGE_VERSION.FREE) return BTN_DEFAULT_CONFIG;
    // 基础版:   免费版 免费使用 可点，点击关闭弹窗  ; 基础版 展示 续费; 专业版 展示升级
    if (packageVersion === VIP_PACKAGE_VERSION.BASIC) {
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.BASIC].name = t(
          'components_vip_package_renewal');
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.BASIC].value = 'renewal';
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.PRO].name = t(
          'components_vip_package_upgrade');
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.PRO].value = 'upgrade';
    }
    // 专业版: 免费版 点击关闭弹窗，基础版，展示续费， 专业版展示续费
    if (packageVersion === VIP_PACKAGE_VERSION.PRO) {
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.BASIC].name = t(
          'components_vip_package_renewal');
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.BASIC].value = 'renewal';
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.PRO].name = t(
          'components_vip_package_renewal');
      BTN_DEFAULT_CONFIG[VIP_PACKAGE_VERSION.PRO].value = 'renewal';
    }
    return BTN_DEFAULT_CONFIG;
  }, [packageVersion, BTN_DEFAULT_CONFIG, t]);

  return {
    vipPackageBtnName,
  };
};

export default useVipPackageBtnName;