import classNames from 'classnames';
import {useT} from '@/common/utils/translation';
import {toInteger} from 'lodash';
import {useMemo} from 'react';

import {Button, Flex, Progress, Tooltip} from 'antd';
import {useEmotionCss} from '@ant-design/use-emotion-css';
import {InfoCircleOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';

const PlanProgress = (props) => {
  let t = useT();
  const textClassname = useEmotionCss(() => {
    return {
      fontSize: '0.75rem',
      color: 'rgba(0,0,0,0.88);',
    };
  });

  const gptUsed = useMemo(() => {
    return (props?.userCurrentPlan?.vipPackage?.equityAnswersCount ??
            0) -
        (props?.userCurrentPlan?.equityAnswersCount ?? 0);
  }, [
    props?.userCurrentPlan?.equityAnswersCount,
    props?.userCurrentPlan?.vipPackage?.equityAnswersCount,
  ]);

  const imageUsed = useMemo(() => {
    return (props?.userCurrentPlan?.vipPackage?.equityImageCount ??
            0) -
        (props?.userCurrentPlan?.equityImageCount ?? 0);
  }, [
    props?.userCurrentPlan?.equityImageCount,
    props?.userCurrentPlan?.vipPackage?.equityImageCount,
  ]);

  const gptPercent = useMemo(() => {
    return toInteger(gptUsed /
        (props?.userCurrentPlan?.vipPackage?.equityAnswersCount) * 100);
  }, [
    gptUsed,
    props?.userCurrentPlan?.vipPackage?.equityAnswersCount]);

  const imagePercent = useMemo(() => {
    return toInteger(imageUsed /
        (props?.userCurrentPlan?.vipPackage?.equityImageCount) *
        100);
  }, [
    imageUsed,
    props?.userCurrentPlan?.vipPackage?.equityImageCount]);

  const onClickAdd = (type) => {
    props.onClickAdd?.(type);
  };

  const addClassname = useEmotionCss(() => {
    return {
      color: '#7860FE',
    };
  });

  return (
      <div style={props.style} className={props.className}>
        {props?.userCurrentPlan?.vipPackage?.packageVersion !==
        'PROFESSIONAL' ? <div>
          <Flex className={classNames(textClassname)} justify="space-between">
            <div>{t('components_application_free_q_a_gpt_3_5')}
              <a className={addClassname}
                 onClick={() => {onClickAdd('ai');}}> +加购</a>
            </div>
            <div>{gptUsed}/{props?.userCurrentPlan?.vipPackage?.equityAnswersCount ??
                0}&nbsp;<Tooltip
                placement="bottomRight"
                arrow={{
                  pointAtCenter: true,
                }}
                title={`${t(
                    'components_application_free_q_a_gpt_3_5_tooltip')}, ${dayjs(
                    props.userCurrentPlan.nextResetTime).
                    format('YYYY-MM-DD')
                } ${t('common_reset')}`}
            >
              <InfoCircleOutlined style={{cursor: 'pointer'}}/> </Tooltip></div>
          </Flex>
          <Progress percent={gptPercent} showInfo={false} status="active"/>
        </div> : null}
        <div>
          <Flex className={classNames(textClassname)} justify="space-between">
            <div>{t('components_image_generation')}<a className={addClassname}
                                                      onClick={() => {
                                                        onClickAdd('image');
                                                      }}> +加购</a>
            </div>
            <div>{imageUsed}/{props?.userCurrentPlan?.vipPackage?.equityImageCount ??
                0}&nbsp;<Tooltip
                placement="bottomRight"
                arrow={{
                  pointAtCenter: true,
                }}
                title={`${t(
                    'components_application_free_q_a_gpt_3_5_tooltip')}, ${dayjs(
                    props.userCurrentPlan.nextResetTime).
                    format('YYYY-MM-DD')} ${t('common_reset')}`}
            >
              <InfoCircleOutlined style={{cursor: 'pointer'}}/> </Tooltip></div>
          </Flex>
          <Progress percent={imagePercent} showInfo={false} status="active"/>
        </div>

      </div>
  );
};

export default PlanProgress;