import styles from './index.module.scss'
import H5Header from '@/h5Pages/components/H5Header'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import LoadingComponents from "@/pages/components/LoadingComponent";
import { OrderModal } from '@/pages/components/SettlementBoard/orderModal';
import { ResultModal } from '@/pages/components/SettlementBoard/result';

function H5Home({routerParams}) {
  const searchParams = new URLSearchParams(location.search);
  return <div
    className={styles.container}
    style={{
      padding: routerParams?.noPadding ? '0rem' : '',
      paddingTop: routerParams?.noPadding ? '4rem' : '',
      boxSizing: 'border-box'
    }}
  >
    <div className={styles.header}>
      <H5Header/>
    </div>
    <div className={styles.content}>
      <Suspense fallback={<LoadingComponents />}>
        <Outlet/>
      </Suspense>
    </div>
    {(searchParams.get('buyConfirmId')||searchParams.get('buyConfirmId'))&&<OrderModal></OrderModal>}
      {(searchParams.get('payId')||searchParams.get('payType'))&&<ResultModal></ResultModal>}
  </div>
}

export default H5Home