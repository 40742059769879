import { Modal, message } from "antd";
import styles from "./index.module.scss";
import OssAvatar from "@/pages/components/OssAvatar/index.jsx";
import { useRef, useState, useContext } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TeamSettingModal from "./TeamInfoEditModal";
import { useT } from "@/common/utils/translation";
import { TeamInfoContext } from "./context";
import TeamMember from "@/pages/components/TeamMember/index.jsx";
import TeamInviteModal from "@/pages/components/TeamInviteModal/index.jsx";
import { getSwicthGroup } from "@/services/teamManange.js";
import {PACKAGE_STYLE_NAME} from "./index.jsx"

function TeamMangeModal() {
  const { roleList } = useContext(TeamInfoContext);
  const memberRef = useRef();
  const inviteMemberRef = useRef();
  let t = useT();
  const MEMBER_MAP = {
    OWNER: t("components_owner"),
    ADMIN: t("components_administrator"),
    USER: t("components_member"),
  };
  const [settingModalVisible, setSettingModalVisible] = useState(false);

  const handleSwitchTeam = (item) => {
    if (item.isActive) return;
    Modal.confirm({
      title: t("components_whether_to_switch_teams"),
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: t(
        "components_after_switching_identities_the_permissions_of_the_opened_pages_will_be_refreshed_please_ensure_that_the_content_you_write_is_properly_saved"
      ),
      okText: t("components_confirm_switch"),
      cancelText: t("components_cancel"),
      onOk() {
        return new Promise((reslove) => {
          getSwicthGroup(item.id)
            .then((res) => {
              if (res.success) {
                message.success("切换成功", 1, () => {
                  window.location.href = window.location.origin;
                });
              }
              reslove(true);
            })
            .catch(() => {
              message.error("操作失败");
              reslove(true);
            });
        });
      },
      onCancel() {},
    });
  };

  return (
    <div className={styles.teamModalBox}>
      <div className={styles.teamList}>
        {Array.isArray(roleList) && roleList.length
          ? [...roleList].map((item) => (
              <div
                key={item.id}
                onClick={() => handleSwitchTeam(item)}
                className={`${styles.teamItem} ${styles.hover} ${
                  item.isActive ? styles?.active : ""
                } `}
              >
                <div className={styles.left}>
                  <OssAvatar className={styles.avatar} src={item?.avatarUrl}>
                    {item?.name?.slice(0, 2)}
                  </OssAvatar>
                  <div className={styles.info}>
                    <div className="flex flex-col ml-3">
                      <span>{item?.name}</span>
                      <span className="f-32"> {MEMBER_MAP[item.role]}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <span
                    className={`${styles.tag}  ${PACKAGE_STYLE_NAME[item?.packageVersion] ?? ""}`}
                  >
                    {item?.packageName || "-"}
                  </span>
                  {
                    <i
                      style={!item?.isActive ? { background: "unset" } : {}}
                    ></i>
                  }
                </div>
              </div>
            ))
          : null}
      </div>

      <div className={styles.line}></div>

      <div className={styles.teamSetting}>
        <div
          className={`${styles.teamSetItem} ${styles.hover}`}
          onClick={() => setSettingModalVisible(!settingModalVisible)}
        >
          <i className={styles.setting}></i>
          <span>{t("components_team_settings")}</span>
        </div>
        <div
          className={`${styles.teamSetItem} ${styles.hover}`}
          onClick={() => {
            memberRef.current?.open?.();
          }}
        >
          <i className={styles.member}></i>
          <span>{t("components_member_management")}</span>
        </div>
        <div
          className={`${styles.teamSetItem} ${styles.hover}`}
          onClick={() => inviteMemberRef.current?.open?.()}
        >
          <i className={styles.invite}></i>
          <span>{t("components_invite_members")}</span>
        </div>
      </div>
      {settingModalVisible && (
        <TeamSettingModal
          open={settingModalVisible}
          closeModal={() => setSettingModalVisible(!settingModalVisible)}
        />
      )}
      <TeamMember memberRef={memberRef} />
      <TeamInviteModal inviteMemberRef={inviteMemberRef} />
    </div>
  );
}

export default TeamMangeModal;
