import LoginSubmit from "./login";
import LoginLogo from "./logo";
import Bgl from "@/assets/images/login/bgl.png";
import Bgr from "@/assets/images/login/bgr.png";

export default function LoginPage() {
  return (
    <div className="bg-[rgba(245,245,245)]">
      <div
        className="flex h-screen w-screen items-center justify-center  max-lg:overflow-hidden"
        style={{
          background: `url(${Bgl}) bottom left no-repeat, url(${Bgr}) bottom right no-repeat`,
          backgroundSize: "350px",
        }}
      ><LoginContent></LoginContent></div>
    </div>
  );
}

export function LoginContent() {
  return (
    <div className="flex">
      <LoginLogo></LoginLogo>
      <LoginSubmit></LoginSubmit>
    </div>
  );
}
