import { Avatar, Button, Space, Popover } from 'antd-mobile'
import { Divider, Flex, message, Button as BntdButton } from 'antd'
import { useEmotionCss } from '@ant-design/use-emotion-css'
import { useContext, useMemo, useState } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { VipContext } from '@/context/vip/index.jsx'
import PlanProgress from '@/pages/components/HomeHeader/PlanProgress.jsx'
import EditPassword from '@/pages/components/EditPassword/index.jsx'
import InviteModal from '@/pages/components/InviteModal/index.jsx'

import SvgIcon from '@/pages/components/SvgIcon/index.jsx'
import SettlementBoard from '@/pages/components/SettlementBoard/index.jsx'
import styles from '@/h5Pages/components/H5Header/index.module.scss'
import { useTimeout } from 'ahooks'
import { useT } from '@/common/utils/translation'
import { useRootContext } from '@/common/RootContext'
import { outLogin } from '@/services/common.js'
import { myCookie } from '@/common/utils/cookie.js'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import {PrimaryColor} from "@/const/index.jsx";

const HeaderDropdown = () => {
  let t = useT()
  let RootContext = useRootContext()
  const { dispatchSetVipModalVisible } = useContext(VipContext)
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const navigate = useNavigate()

  const handleOpenChange = (newOpen) => {
    setVisible(newOpen)
  }
  const planClassname = useEmotionCss(() => {
    return {
      backgroundColor: '#93a4bc',
      width: '200px',
      borderRadius: '8px',
      color: '#fff',
      padding: '10px 15px',
    }
  })

  const popoverClassname = useEmotionCss(() => {
    return {
      // left: '172px !important',
    }
  })

  const inviteFriendClassname = useEmotionCss(() => {
    return {
      height: '52px',
      lineHeight: '52px',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '8px',
    }
  })

  const handleSetInviteModalVisible = () => {
    setInviteModalVisible(!inviteModalVisible)
    setVisible(false)
  }

  const handleSetVipPackageMOdalVisible = () => {
    dispatchSetVipModalVisible()
    setVisible(false)
  }

  useTimeout(() => {})

  const planName = useMemo(() => {
    switch (
      RootContext?.userCurrentPlan?.vipPackage?.packageVersion
      ) {
      case 'FREE':
        return t('components_free_version')
      case 'STANDARD':
        return t('components_basic_version_vip')
      default: // PROFESSIONAL
        return t('components_professional_version') + '·VIP'
    }
  }, [RootContext?.userCurrentPlan?.vipPackage?.packageVersion])

  return (
    <div>
      {!RootContext.loginStatus ?
        <Button
          onClick={() => {RootContext.dispatchSetLoginVisible(true)}}
          style={{
            width: '5rem',
            height: '2rem',
            fontSize: '0.8rem',
          }}
        >
          {t('components_log_in_register')}
        </Button> : <Popover
          className={popoverClassname}
          destroyOnHide
          content={
            <div>
              <div className={planClassname}>
                <Flex justify="space-between" align="center">
                  <div style={{
                    fontSize: '0.88rem',
                    fontWeight: 500,
                  }}>{planName}</div>
                  <BntdButton ghost size="small"
                              style={{
                                backgroundColor: '#fff',
                                color: PrimaryColor,
                              }}
                              onClick={handleSetVipPackageMOdalVisible}>
                    {RootContext?.userCurrentPlan?.vipPackage?.packageVersion ===
                    'PROFESSIONAL' ? t('components_renew') : t(
                      'components_upgrade_package')}
                  </BntdButton>
                </Flex>
                {RootContext?.userCurrentPlan?.vipPackage?.packageVersion ===
                'FREE' ? <div
                  style={{ marginTop: '10px', fontSize: '0.75rem' }}
                >
                  {t('components_free_upgrade_message')}
                </div> : <div
                  style={{ marginTop: '10px', fontSize: '0.75rem' }}
                >
                  {t('components_vip_package_at')}{' '}
                  {dayjs(RootContext?.userCurrentPlan?.endTime).format('YYYY-MM-DD')}{' '}
                  {t('components_maturity')}
                </div>}
              </div>
              <PlanProgress
                style={{ marginTop: '16px' }}
                userCurrentPlan={RootContext?.userCurrentPlan}
              />
              <div
                onClick={handleSetInviteModalVisible}
                className={inviteFriendClassname}
              >
                {t('components_invite_friends_to_get_free_vip')}{' '}
                <RightOutlined style={{ color: 'rgba(0,0,0,0.3)' }}/>
              </div>
              <Divider style={{ margin: '6px 0 14px 0' }}/>
              <EditPassword
                onClickEditPassword={() => {setVisible(false)}}/>
              <Divider style={{ margin: '14px 0' }}/>
              <div
                onClick={() => {
                  outLogin().then((res) => {
                    if (res.success) {
                      message.success(
                        t('components_exit_successfully'))
                    } else {
                      message.error(res.message)
                    }
                    myCookie.delete('kerqu-admin-token')
                    myCookie.delete('groupId')
                    myCookie.delete('kerqu-group')
                    RootContext.dispatchSetLoginStatus(false)

                    navigate({
                      pathname: '/',
                    })
                  })
                }}
                style={{
                  fontSize: '0.88rem',
                  paddingBottom: '6px',
                  color: '#ff4d4f',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <SvgIcon
                  name={'logout'}
                  style={{
                    width: '14px',
                    height: '14px',
                  }}
                />
                &nbsp;{t('components_sign_out')}
              </div>
            </div>
          }
          visible={visible}
          trigger="click"
          onVisibleChange={handleOpenChange}
          placement="bottom-start"
        >
          <div>
            <Space
              className={styles.userInfoChange}
            >
              <Avatar src="" style={{ width: '1.8rem', height: '1.8rem' }}/>
              <SvgIcon
                name={'downArrow'}
                className={`${styles.iconStyle} ${visible
                  ? styles.rotate180
                  : ''}`}
                style={{ height: '0.75rem', width: '0.4375rem' }}
              />
            </Space>
          </div>
        </Popover>}

      <SettlementBoard visible={visibleModal}
                       onClose={(value) => {setVisibleModal(value)}}/>
      <InviteModal visible={inviteModalVisible}
                   onClose={handleSetInviteModalVisible}/>
    </div>
  )
}

export default HeaderDropdown