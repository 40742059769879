import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useRootContext } from '@/common/RootContext.jsx';
import dayjs from "dayjs"

notification.config({
  maxCount: 2,
});

// 2小时
const NOTIFIY_TIME_GAP = 60 * 60 * 2 * 1000
// 7天
const SEVEN_DAY = 7 * 24 * 60 * 60 * 1000

const useVipOverdueNote = () => {
  const [api, contextHolder] = notification.useNotification();
  const [expiryDate, setExpiryDate] = useState(false);
  const rootContentData = useRootContext()

  const openNotificationWithIcon = useCallback((type) => {
    api[type]({
      message: 'VIP套餐即将到期',
      className: 'vip-overdue-noti',
      description:
        <span>您的VIP套餐将在<span style={{ color: "red" }}>{dayjs(rootContentData?.userCurrentPlan?.endTime).format("YYYY-MM-DD HH时mm分ss秒")}</span>到期, 到期后您的VIP权益将会降级到免费版,超出权益的自定义应用、账号将被清除, 请及时续费!</span>,
    });
  }, [rootContentData?.userCurrentPlan?.endTime, api]);

  const calcVipOverdueTime = useCallback(() => {
    // VIP套餐到期前七天内,每隔两小时提醒一次弹窗
    openNotificationWithIcon("warning")
    setInterval(() => {
      openNotificationWithIcon("warning")
    }, NOTIFIY_TIME_GAP)

  }, [openNotificationWithIcon])


  useEffect(() => {
    if (!rootContentData?.userCurrentPlan?.endTime) return
    setExpiryDate(+dayjs(rootContentData?.userCurrentPlan?.endTime).valueOf() - Date.now() <= SEVEN_DAY);
  }, [rootContentData?.userCurrentPlan?.endTime]);

  useEffect(() => {
    expiryDate && calcVipOverdueTime()
  }, [expiryDate, calcVipOverdueTime])


  return {
    contextHolder
  }
}
export default useVipOverdueNote