import {useEmotionCss} from '@ant-design/use-emotion-css';
import useIsMobile from '@/common/hooks/useIsMobile.js';

const useStyle = (props) => {
  const [isMobile] = useIsMobile();
  const modalClassname = useEmotionCss(() => {
    return {
      width: '764px !important',
      '.mobile-container': {
        padding: isMobile ? '1.5rem' : '0',
      },
      '.title': {
        fontSize: '1rem',
        fontWeight: 600,
        color: 'rgba(0,0,0,0.88)',
        lineHeight: '22px',
        textAlign: 'center',
        marginBottom: '0.75rem',
      },
      '.sub-title': {
        fontSize: '0.75rem',
        color: 'rgba(0,0,0,0.65)',
        lineHeight: '18px',
        textAlign: 'center',
        marginBottom: '2rem',
      },
      '.ant-modal-content': {
        padding: '1.5rem',
      },
    };
  });

  const planBoxClassname = useEmotionCss(() => {
    return {
      height: '100px',
      width: '192px',
      background: 'rgba(22,119,255,0.04)',
      borderRadius: '8px',
      border: '1px solid rgba(22,119,255,0.15)',
      color: 'rgba(0,0,0,0.88)',
      padding: '24px 24px 30px 24px',
      flexShrink: 0,
    };
  });

  const rightClassname = useEmotionCss(() => {
    return {
      width: '100%',
      '.final-desc': {
        fontSize: '14px',
        color: 'rgba(0,0,0,0.88)',
        fontWeight: 500,
        marginTop: '36px',
        marginBottom: '24px',
      },
    };
  });

  const descriptionItemClassname = useEmotionCss(() => {
    return {
      lineHeight: '20px',
      marginBottom: '16px',
      '.item-title': {
        color: 'rgba(0,0,0,0.65)',
      },
      '.item-value': {
        color: 'rgba(0,0,0,0.88)',
        fontWeight: 500,
      },
    };
  });

  const paymentCardClassname = useEmotionCss(() => {
    return {
      width: isMobile ? '47%' : '48%',
      height: '54px',
      background: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D9D9D9',
      display: 'flex',
      cursor: 'pointer',
      '.ant-radio-wrapper': {
        alignItems: 'center',
      },
      '.payment-card-radio': {
        marginLeft: '24px',
      },
      '.payment-card-label': {
        marginLeft: '8px',
      },
    };
  });

  const WechatPayClassname = useEmotionCss(() => {
    return {
      display: 'flex',
      justifyContent: 'center',
      '.inner-box': {
        width: props?.overTime ? '400px' : '200px',
        textAlign: 'center',
        '.pay-image': {width: '122px', height: '32px'},
        '.pay-price': {
          fontSize: '1.25rem',
          fontWeight: '500',
          marginTop: '2rem',
        },
        '.pay-time': {
          fontSize: '0.875rem',
          fontWeight: '400',
          color: 'rgba(0,0,0,0.65)',
          marginTop: '4px',
          marginBottom: '1.5rem',
        },
        '.pay-desc': {
          fontSize: '0.875rem',
          fontWeight: '400',
          color: 'rgba(0,0,0,0.65)',
        },
        '.pay-qr-code': {
          // width: '200px',
          // height: '200px',
          marginBottom: '1.5rem',
        },
      },
    };
  });

  const nameClassname = useEmotionCss(() => {
    return {
      marginTop: '1.5rem',
      paddingInline: '1.5rem',
      background: 'rgba(22,119,255,0.04)',
      borderRadius: '0.5rem',
      border: '0.06rem solid rgba(22,119,255,0.15)',
      height: '4.88rem',
    };
  });

  return {
    modalClassname,
    planBoxClassname,
    descriptionItemClassname,
    rightClassname,
    paymentCardClassname,
    WechatPayClassname,
    nameClassname,
  };
};

export default useStyle;