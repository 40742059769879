import { useT } from "@/common/utils/translation";
import {Empty} from "antd";

function NotFound(){
    let t = useT()
    return (
        <div
            style={{
                height:'500px',
                display:'flex',
                alignItems:"center",
                width:'100%',
                justifyContent:'center'
            }}
        >
            <Empty description={t('createCenter_this_page_is_missing')} />
        </div>
    )
}

export default NotFound;