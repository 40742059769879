import ChinaUnionPayImage from '../../../assets/images/pay/china-union-pay.png';
import AmericanExpressImage
  from '../../../assets/images/pay/american-express.png';
import DinersClubImage from '../../../assets/images/pay/diners-club.png';
import DiscoverImage from '../../../assets/images/pay/discover.png';
import MastercardImage from '../../../assets/images/pay/mastercard.png';
import VisaImage from '../../../assets/images/pay/visa.png';
import {Space} from 'antd';
import {useEmotionCss} from '@ant-design/use-emotion-css';
import { useT } from '@/common/utils/translation';

const CardImageBox = () => {
  let t =useT()
  const cardImageBoxTitleStyle = useEmotionCss(() => {
    return {
      padding: '0 0 8px',
    };
  });
  const imageStyle = useEmotionCss(() => {
    return {
      width: '43px',
      height: '26px',
    };
  });
  return (
      <div>
        <div className={cardImageBoxTitleStyle}>{t('components_debit')}/{t('components_credit_card')}</div>
        <Space><img className={imageStyle} src={VisaImage} alt=""/><img
            className={imageStyle} src={DiscoverImage}
            alt=""/><img className={imageStyle}
                         src={DinersClubImage}
                         alt=""/><img className={imageStyle}
                                      src={MastercardImage}
                                      alt=""/><img
            className={imageStyle}
            src={AmericanExpressImage}
            alt=""/><img
            className={imageStyle}
            src={ChinaUnionPayImage}
            alt=""/></Space>
      </div>
  );
};

export default CardImageBox;