import { createContext, useState } from "react";


export let vipPackageGlobalVisible = null


export const VipContext = createContext({
    vipModalVisible: true,
    dispatchSetVipModalVisible: () => { }
})


const VipContextProvider = ({children}) => {

    const [vipModalVisible, setVipModalVisible] = useState(false)

    const dispatchSetVipModalVisible = () => {
        setVipModalVisible(!vipModalVisible)
    }
    vipPackageGlobalVisible = (state = false) => {
        setVipModalVisible(state)
    }
    return <VipContext.Provider
        value={{
            vipModalVisible,
            dispatchSetVipModalVisible
        }}
     
    >   {children}</VipContext.Provider>
}

export default VipContextProvider