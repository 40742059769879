import React, {useContext, useRef} from 'react';
import { Input} from 'antd';
import styles from './index.module.scss';
import {useEffect, useState} from "react";
import AddApplicationContext from "@/pages/AddApplication/Context.jsx";
import SvgIcon from "@/pages/components/SvgIcon/index.jsx";
import useIsMobile from "@/common/hooks/useIsMobile.js";
import { useT } from '@/common/utils/translation'

function ParamsLine({value, onChange, disabled}) {
  let t = useT()
  const [isMobile] = useIsMobile();

  const contextData = useContext(AddApplicationContext);

  const {name, description, tag} = value || {};
  const [nameText, setNameText] = useState(name);
  const [descriptionText, setDescriptionText] = useState(description);
  const timer = useRef();

  useEffect(() => {
    setNameText(value.name);
    setDescriptionText(value.description);
  }, [value]);

  return (
    <div className={styles.container} style={{width: isMobile ? ' calc(100% - 0rem)': ''}}>
      <div className={styles.line}>
        <div className={`${styles.label} ${styles.required}`}>{t('menu_name')}</div>
        <Input
          disabled={!contextData.editAble}
          value={nameText}
          placeholder={t('menu_please_enter_name')}
          onChange={(e) => {
            setNameText(e.target.value);

            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
              onChange?.({
                ...value,
                name: e.target.value,
                description: descriptionText,
              });
            }, 500 );

          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('menu_describe')}</div>
        <Input
          disabled={!contextData.editAble}
          value={descriptionText}
          placeholder={t('menu_please_enter_a_description')}
          onChange={(e) => {
            setDescriptionText(e.target.value);

            clearTimeout(timer.current);

            timer.current = setTimeout(() => {
              onChange?.({
                ...value,
                name: nameText,
                description: e.target.value,
              });
            }, 500);
          }}
        />
      </div>
      {
        contextData.extraKeys?.indexOf(tag) !== -1 ? (
          <div className={styles.deleteButton}>
            <span onClick={() => {
              contextData.onDeleteParam(value);
            }}>
              <SvgIcon name={'deleteRed'} style={{width:'16px', height:'16px'}} />
            </span>
          </div>
        ) : null
      }
    </div>
  )
}

export default React.memo(ParamsLine);