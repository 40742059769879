import {

  Popover,
} from "antd";
import styles from "./index.module.scss";
import { SwapOutlined } from "@ant-design/icons";
import { useContext, useRef } from "react";
import TeamSettingPopover from "./TeamSettingPopover"
import OssAvatar from "@/pages/components/OssAvatar/index.jsx"
"@/pages/components/TeamInviteModal/index.jsx";
import { useT } from "@/common/utils/translation";
import TeamInfoContextProvider, { TeamInfoContext } from "./context"
import {VIP_PACKAGE_VERSION} from "@/pages/components/VipPackageModal/index.jsx"

// 匹配后端返回的版本名称,用于样式展示,
export const PACKAGE_STYLE_NAME = {
 [VIP_PACKAGE_VERSION.FREE]: styles.free,
 [VIP_PACKAGE_VERSION.BASIC]: styles.base,
 [VIP_PACKAGE_VERSION.PRO]: styles.pro
}

function SliderUser() {
  let t = useT();
  const MEMBER_MAP = {
    OWNER: t("components_owner"),
    ADMIN: t("components_administrator"),
    USER: t("components_member"),
  };
  const { activedRole, popoverVisible, dispatchSetPopoverVisible } = useContext(TeamInfoContext)
  const memberRef = useRef();
  const inviteMemberRef = useRef();

  if(!activedRole.name) return
  return (
    <div className={styles.sliderConatiner}
      tabIndex={-1}
      onBlur={() => {
        setTimeout(() => dispatchSetPopoverVisible(false), 300)
      }}
    >
      <Popover
        placement="topLeft"
        trigger="click"
        overlayClassName={styles.popover}
        open={popoverVisible}
        onClick={dispatchSetPopoverVisible}
        content={activedRole ? <TeamSettingPopover
          inviteMemberModal={() => inviteMemberRef.current?.open?.()}
          memberModal={() => memberRef.current?.open?.()}
        /> : null}>
        <div className={styles.container}>
          <div className={styles.infoWarp}>
            <OssAvatar
              src={activedRole?.avatarUrl}
              id="avatar_info"
              className="w-10 h-10 mr-2 flex items-center justify-center "
            >
              {activedRole.name?.slice(0, 2)}
            </OssAvatar>
            <div className={styles.curInfo}>
              <div className=" overflow-hidden whitespace-nowrap overflow-ellipsis w-16">
                {activedRole.name}
              </div>
              <span className={`${styles.tag}  ${PACKAGE_STYLE_NAME[activedRole?.packageVersion] ?? ""}  `}>
                {activedRole.packageName}
              </span>
            </div>
          </div>

          <div className={styles.doubleArrow}>
            <SwapOutlined />
          </div>
        </div>
      </Popover>
    </div>
  );
}



const SliderTeam = () => {
  return <TeamInfoContextProvider>< SliderUser /></TeamInfoContextProvider>
}
export default SliderTeam;
