import IsMobileComponentProxy from '@/pages/components/IsMobileComponentProxy'
import H5Home from '@/h5Pages/H5Home'
import Home from '@/pages/Home'
import {useLocation} from 'react-router-dom';
import {routerList} from "@/router";
import React, {useEffect, useState} from "react";

function HomeProxy(props) {
  const location = useLocation();
  const [routerParams, setRouterParams] = useState({})

  useEffect(() => {
    const pathName = location.pathname;
    const route = findRoute(routerList, pathName);
    setRouterParams(route);
  }, [location]);

  const findRoute = (list, pathName) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.path === pathName) {
        return item;
      } else if (item.children?.length > 0) {
        const res = findRoute(item.children, pathName);
        if(res){
          return res;
        }
      }
    }
  }

  return (
    <IsMobileComponentProxy
      h5Content={<H5Home routerParams={routerParams} />}
      pcContent={<Home routerParams={routerParams} />}
    />
  )
}

export default HomeProxy