import {Menu} from "antd";
import menus from '../../../const/menus.jsx';
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useMount} from "ahooks";
import menuInstance from "@/common/cacheMenuInfo.js";
import {ergodicTree} from "@/lib/utils.js";

import menuChangeListener_instance from '@/lib/menuChangeListener';

function MenuComponent({onMenuClick}) {

  const [menusList, setMenusList] = useState(menus);
  const [selectKey, setSelectKey] = useState(['/']);
  const [openKeys, setOpenKeys] = useState();
  const menuInit = useRef(false);
  const params = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  useMount(() => {
    initOpenKeys();
  });

  useEffect(() => {
    const key = location.pathname;

    let hadCommon = false
    ergodicTree(menus,{
      childName:'children',
      itemCallBack: (item) => {
        if(item.key === key && !hadCommon){
          hadCommon = true;
        }
      }
    });

    if(hadCommon){
      setSelectKey([key]);
    }

  }, [location]);

  const initOpenKeys = () => {
    if(!menuInit.current){
      menuInit.current = true;
      const key = location.pathname;
      setSelectKey([key]);
      const parentMenu = findParent(key)

      const currentMenu = findTargetMenu(menusList, key);
      menuInstance.serMenuInfo(currentMenu)

      if (parentMenu) {
        const parentKey = parentMenu.path ? parentMenu.path : parentMenu.key
        setOpenKeys([parentKey]);
      }
    }
  }


  const findParent = (key) => {
    for (const item of menusList) {
      const children = item.children;
      if (children?.length) {
        for (const item1 of children) {
          if (item1.key === key) {
            return item;
          }
        }
      }
    }
  }

  const onClick = (item) => {

    if(!menuChangeListener_instance.onPageChangeBefore(() => {
      onPageJump(item);
    })){
      onPageJump(item);
    }
  };

  const onPageJump = (item) => {

    menuChangeListener_instance.clearList();

    const {key, keyPath} = item;
    const code = item.item.props?.search?.code;
    const priCode = item.item.props?.search?.priCode;

    let currentMenu = findTargetMenu(menusList, key);
    menuInstance.serMenuInfo(currentMenu);

    setSelectKey(keyPath);
    if (key) {
      navigate({
        pathname: key,
        search: code ? `type=${code}&priCode=${priCode}` : ''
      });

      onMenuClick?.(item);
    }
  }

  // 根据 key 找到 菜单信息
  const findTargetMenu = (list, key) => {
    for(let item of list){
      if(item.key === key){
        return item;
      } else {
        if(item.children){
          const result = findTargetMenu(item.children, key);
          if(result){
            return result;
          }
        }
      }
    }
  }

  return (
    <Menu
      onClick={onClick}
      defaultSelectedKeys={selectKey}
      selectedKeys={selectKey}
      openKeys={openKeys}
      mode="inline"
      theme="dark"
      items={menusList}
      onOpenChange={(openKeys) => {
        setOpenKeys(openKeys);
      }}
    />
  )
}

export default MenuComponent