import {lazy} from 'react'
// const ApplicationDetail = lazy(() => import('@/pages/ApplicationDetail/index.jsx'));
import ApplicationDetail from '@/pages/ApplicationDetail/index.jsx'
const ChannelPage = lazy(() => import('@/pages/ChannelApplication/ChannelPage/index.jsx'));

const paths = [
  {
    path: '/listing-create',
    key: '',
  },
  {
    path: '/store-operations',
    key: '',
  },
  {
    path: '/selection-development',
    key: '',
  },
  {
    path: '/ama-keyword-planning',
    key: '',
  },
  {
    path: '/seo',
    key: '',
  },
  {
    path: '/b2c-station-construction',
    key: '',
  },
  {
    path: '/product-description',
    key: '',
  },
  {
    path: '/independent-station-selection',
    key: '',
  },
  {
    path: '/independent-station-customer',
    key: '',
  },
  {
    path: '/keyword-planning',
    key: '',
  },
  {
    path: '/facebook',
    key: '',
  },
  {
    path: '/instagram',
    key: '',
  },
  {
    path: '/kol',
    key: '',
  },
  {
    path: '/linkedin',
    key: '',
  },
  {
    path: '/pinterest',
    key: '',
  },
  {
    path: '/quora',
    key: '',
  },
  {
    path: '/tiktok',
    key: '',
  },
  {
    path: '/twitter',
    key: '',
  },
  {
    path: '/youtube',
    key: '',
  },
  {
    path: '/b2c-seo-article',
    key: '',
  },
  {
    path: '/b2c-product-create',
    key: '',
  },
  {
    path: '/b2c-station-create',
    key: '',
  },
  {
    path: '/foreign-trade-mail',
    key: '',
  },
  {
    path: '/foreign-trade-keyword-planning',
    key: '',
  },
  {
    path: '/other-sumaitong',
    key: '',
  },
  {
    path: '/other-etsy',
    key: '',
  },
  {
    path: '/other-shopee',
    key: '',
  },
  {
    path: '/other-ebay',
    key: '',
  },
  {
    path: '/other-picture-analysis',
    key: '',
  },
];

const channelMenus = paths.map(item => {
  return {
    path: item.path,
    extra: true,
    Component: ChannelPage,
    noPadding: true,
  }
});

export default channelMenus;