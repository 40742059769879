import { Suspense } from 'react'
import styles from './index.module.scss'
import { Layout, } from 'antd'
import HomeHeader from './components/HomeHeader'
import { Outlet } from 'react-router-dom'
import AIHeader from "@/pages/components/AIHeader/index.jsx";

const { Header, Footer, Content } = Layout

function Image () {

  return (
    <Layout className={styles.container}>
      <Layout hasSider>
        <Content>
          <Suspense>
            <Outlet/>
          </Suspense>
        </Content>
      </Layout>
      {/*<Footer className={styles.footer}>Footer</Footer>*/}
    </Layout>
  )
}

export default Image
