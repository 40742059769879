
import { nanoid } from "@/lib/utils.js";
import request from './index.jsx'
import axios from "axios";
import {v4 as uuid} from 'uuid'

export const OssFileType = {
  TEMP: 'TEMP',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
}
// 获取上传凭证
export const getUploadCredentials = (query) => {
  return request.post(`/api/oss/getToken?fileType=${query.fileType}`);
};

// 文件上传函数
export const uploadFileToOSS = async (files, ossConfig, onProgress) => {

  const uploadRequests = [];
  for (let i = 0; i < files.length; i++) {
    const element = files[i];

    const formData = new FormData();
    var filename = ossConfig.keepFileName
      ? element.name
      : element.name ?
        (new Date().getTime() + "_" + element.name.replace(/^.*\.(.*)$/, (match, p1) => `${nanoid()}.${p1}`)) :
        (Date.now() + "_" + uuid());
    formData.append("Filename", filename);
    formData.append("key", (ossConfig.dir + filename).replace(/^\//, ""));
    formData.append("OSSAccessKeyId", ossConfig.accessId);
    formData.append("policy", ossConfig.policy);
    formData.append("Signature", ossConfig.signature);
    formData.append("success_action_status", "200");
    formData.append("file", element);
    const index = i
    const uploadRequest = axios.post(
        ossConfig.host,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: "text",
          onUploadProgress: (progressEvent) => {
            if (typeof onProgress === 'function' && progressEvent.lengthComputable) {
              const complete = (progressEvent.loaded / progressEvent.total) * 100 | 0
              onProgress(index, complete)
            }
          }
        })
      .then((res) => ({
        uid: nanoid(),
        status: "done",
        url: ossConfig.dir + filename,
        relativeUrl: ossConfig.dir + filename,
        absolutePath: import.meta.env.VITE_PUBLIC_OSS_HOST + "/" + ossConfig.dir + filename,
      })).catch((err) => ({
        uid: nanoid(),
        status: "error",
      }));

    uploadRequests.push(uploadRequest);
  }
  // 使用Promise.all等待所有上传请求完成
  return Promise.all(uploadRequests);
};

const proofRequest = {}
// 存储四种上传凭证
export const getProof = async (query) => {
  const ossKey = `oss-cred-${query.fileType}`
  const item = sessionStorage.getItem(ossKey)
  const proof = item ? JSON.parse(item) : null;

  // 如果凭证不存在或已过期，则重新获取凭证并存储
  if (!proof || Date.now() >= proof.expire * 1000) {
    if (proofRequest[ossKey]) {
      return new Promise((resolve, reject) => {
        proofRequest[ossKey].push(({rs: resolve, rj: reject}))
      })
    }
    proofRequest[ossKey] = []
    return new Promise(resolve =>  {
      getUploadCredentials(query).then(data => {
        if (!data.data) {
          throw new Error(data.message)
        }
        sessionStorage.setItem(ossKey, JSON.stringify(data.data));
        proofRequest[ossKey].forEach(({rs}) => rs(data.data))
        proofRequest[ossKey] = undefined
        resolve(data.data)
      }).catch(e => {
        console.error(e)
        proofRequest[ossKey].forEach(({rj}) => rj(e))
        proofRequest[ossKey] = undefined
      })
    })
  }

  // 如果凭证存在且未过期，则直接返回
  return proof;
};
