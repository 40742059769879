import { Modal, Alert } from "antd"
import {message} from "antd";
import { useEffect, useMemo, useState } from "react"
import styles from "./index.module.scss"
import CopyButton from "@/pages/components/CopyButton/index.jsx";
import { copyToClip } from "@/common/utils/tools.js";
import {getMyShareLink, addShareRecord, getShareCount} from "@/services/invite.js"
import { useT } from '@/common/utils/translation'

const INVIATE_TARGET = 10

const InviteModal = ({
    visible,
    onClose
}) => {
    let t = useT()
    const [inviteUrl, setInviteUrl] = useState("")
    const [inviteNum, setInviteNum] = useState(0)

    const handleSetVisible = () => {
        onClose && onClose()
    }

    useEffect(() => {
        if(visible) {
            // addShareRecord()
            fetchShareCount()
            fetchMyShareLink()
        }
     
    }, [visible])

    /**
     * 获取当前已经邀请人数
     */
    const fetchShareCount = () => {
        getShareCount().then(res => {
            setInviteNum(res?.data || 0)
        })
    }

    /**
     * 获取当前的分享链接
     */
    const fetchMyShareLink = () => {
        getMyShareLink(window.location.origin).then(res => {
            setInviteUrl(res?.data)
        })
    }

   const vipCount = useMemo(() => {
    return Math.floor(inviteNum / INVIATE_TARGET)
   }, [inviteNum])

    return <Modal
        open={visible}
        onCancel={handleSetVisible}
        footer={null}
        centered={true}
        className={styles.InviteModal}
    >
        <div className={styles.InviteModalBox}>
            <div className={styles.title}>{t('components_invite_friends')}</div>
            <div className={styles.des}>
                {t('components_per_recommendation')}<span>{INVIATE_TARGET}</span>{t('components_register_a_friend_independent_ip_and_you_will_automatically_receive_1_month')}<span>{t('components_vip_package_basic_version')}</span>
            </div>
            <div className={styles.urlContainer}>
                <div className={styles.subTitle}>
                    {t('components_exclusive_invitation_link')}
                    <div className={styles.urlBox}>
                        <a >{inviteUrl} </a>
                        <CopyButton
                            onClick={() => {
                                copyToClip(inviteUrl).then(() => {
                                    message.success(t('menu_copied_successfully'));
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.invitedNum}>
                {t('components_you_have_invited_registered_users')}{inviteNum}人
            </div>
            {
                vipCount ?  <div className={styles.invitedSuccessBox}>
                <Alert message={`恭喜！您已获得${vipCount}个月VIP套餐·基础版`} type="success" showIcon />
           </div> : null
            }
        
        </div>

    </Modal>
}

export default InviteModal