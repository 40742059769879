import { useEffect, useState } from "react";
import SettlementBoard from "./index";
import { useRequest } from "ahooks";
import { getPackageById } from "@/services/vip";
import { useNavigate } from "react-router-dom";
import useIsMobile from "@/common/hooks/useIsMobile";
import SettlementMobileDetail from "./SettlementMobileDetail";

export function OrderModal() {
  const searchParams = new URLSearchParams(location.search);
  const [visible, setVisible] = useState(!!searchParams.get("buyConfirmId"));
  const navigate = useNavigate();
  const [isMobile] = useIsMobile();
  let id = searchParams.get("buyConfirmId");
  return isMobile ? (
    <SettlementMobileDetail
      userVip={props.userVip}
      vipInfo={id}
      payment={payment}
      onPaymentChange={onPaymentChange}
      pay={onPay}
    />
  ) : (
    <SettlementBoard
      onCancel={(value) => {
        let url = new URL(location.href);
        url.searchParams.delete("buyConfirmId");
        navigate(url.pathname + url.search, { replace: true });
        setVisible(value);
      }}
      visible={visible}
      vipInfo={id}
    ></SettlementBoard>
  );
}
