import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import React from "react";
import {Spin} from 'antd';

function LoadingComponents() {

  return (
    <div style={{
      width: '100%',
      display:'flex',
      justifyContent:"center",
      minHeight: '200px',
    }}>
      <Spin tip={" "} />
      {/*<LoadingOutlined style={{fontSize:'40px'}}/>*/}
    </div>
  )

}

export default LoadingComponents;