import {Button, Space} from "antd";
import {t} from "i18next";

function ConfirmFooter({onCancel = () => {}, onOk = () => {}}){
  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
      <Space>
        <Button
          className={'cancelButton'}
          onClick={onCancel}
        >{t('components_cancel')}</Button>
        <Button
          type={'primary'}
          className={'primaryButton'}
          onClick={onOk}
        >{t('components_confirm')}</Button>
      </Space>
    </div>
  )
}
export default ConfirmFooter;