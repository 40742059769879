import { useT } from "@/common/utils/translation";
import WechatPayImage from "../../../assets/images/pay/wechat-pay.png";
import useStyle from "@/pages/components/SettlementBoard/hooks/useStyle.js";
import { Button, Spin, message } from "antd";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { minuteCountdown } from "@/common/utils/tools";
import { useRequest } from "ahooks";
import { getOrderById } from "@/services/vip";
import { useNavigate } from "react-router-dom";

export const PAY_STATUS = {
  UNPAID: "UNPAID",
  PAID: "PAID",
  FAILED: "FAILED",
  CANCEL: "CANCEL",
};

function setQuery(list) {
  var url = new URL(location.href);
  var params = new URLSearchParams(url.search);
  list.forEach((element) => {
    params.set(element.name, element.value);
  });
  url.search = params.toString();
  return url.pathname + url.search;
}

export function openTime() {
  let time = null;

  return {
    open: (fn) => {
      if (time) {
        clearInterval(time);
      } else {
        time = setInterval(async () => {
          await fn();
        }, 1000);
      }
    },
    out: () => {
      time && clearInterval(time);
    },
  };
}

const WechatPay = (props) => {
  let t = useT();
  const [overTime, setOvertime] = useState(false);
  const { WechatPayClassname } = useStyle({ overTime });
  const navigate = useNavigate();
  const [outTime, setOutime] = useState("10:00");
  let { data, loading, runAsync } = useRequest(
    async (id) => {
      return await getOrderById(id);
    },
    {
      manual: true,
    }
  );
  let { payPriceRMB, payPrice } = props.vipInfo;
  // const rmbPrice = useMemo(() => {
  //   const temp = price >= 0 ? price : 0
  //   return (temp * (rate || 1)).toFixed(2)
  // }, [price, rate])
  useEffect(() => {
    let time = minuteCountdown(
      9 + 59 / 60,
      (value) => {
        setOutime(value);
      },
      () => {
        setOvertime(true);
      }
    );
    time.go();
    return () => {
      time.out();
    };
  }, []);
  useEffect(() => {
    let openSetTime = openTime();
    props.url &&
      openSetTime.open(async () => {
        let res = await runAsync(props.orderData.id);
        if (!res.success) {
          return;
        }
        if (res.data.payStatus == PAY_STATUS.PAID) {
          props.setType && props.setType("success");
          return;
        }
      });

    return () => {
      openSetTime.out();
    };
  }, [props.url]);

  return (
    <div className={WechatPayClassname}>
      <div className="inner-box">
        <div className="title" style={{ marginBottom: "2rem" }}>
          {t("components_package_payment")}
        </div>
        {overTime ? (
          <div>
            <div
              style={{
                fontSize: "1.5rem",
                marginTop: "13rem",
                marginBottom: "3rem",
              }}
            >
              {t("components_timed_out_please_pay_again")}
            </div>
            <Button
              style={{ width: "12.5rem", marginBottom: "13rem" }}
              onClick={() => {
                props.setType("detail");
              }}
            >
              {t("components_i_see")}
            </Button>
          </div>
        ) : (
          <div style={{ marginBottom: "40px" }}>
            <img className="pay-image" src={WechatPayImage} alt="" />
            <div className="pay-price">
              ${payPrice} (¥{payPriceRMB})
            </div>
            <div className="pay-time flex justify-center gap-x-1">
              <span>{t("components_pay_remaining_time")}:</span>
              <span className="w-[40px]">{outTime}</span>
            </div>
            <div className="pay-desc">
              {t("components_please_use_wechat_to_scan_the_qr_code_to_pay")}
            </div>
            <div className="pay-qr-code mt-4">
              <Spin spinning={!props.url} tip="Loading" size="small">
                <div className="h-[200px] w-[200px]">
                  {props.url && <QRCodeSVG className='w-full h-full' value={props.url}></QRCodeSVG>}
                </div>
              </Spin>
            </div>
            <Button
              size="large"
              style={{ width: "12.5rem" }}
              onClick={() => {
                props.setType("detail");
              }}
            >
              {t("components_cancel")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WechatPay;
