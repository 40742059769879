import Markdown from 'react-markdown'
import rangeParser from 'parse-numeric-range'
import {PrismLight as SyntaxHighlighter} from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'
import './index.scss'

import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript.js'
import java from 'react-syntax-highlighter/dist/cjs/languages/prism/java.js'
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python.js'
import php from 'react-syntax-highlighter/dist/cjs/languages/prism/php.js'
import csharp from 'react-syntax-highlighter/dist/cjs/languages/prism/csharp.js'
import cpp from 'react-syntax-highlighter/dist/cjs/languages/prism/cpp.js'
import css from 'react-syntax-highlighter/dist/cjs/languages/prism/css.js'
import ruby from 'react-syntax-highlighter/dist/cjs/languages/prism/ruby.js'
import c from 'react-syntax-highlighter/dist/cjs/languages/prism/c.js'
import objectivec from 'react-syntax-highlighter/dist/cjs/languages/prism/objectivec.js'
import swift from 'react-syntax-highlighter/dist/cjs/languages/prism/swift.js'
import scala from 'react-syntax-highlighter/dist/cjs/languages/prism/scala.js'
import shell from 'react-syntax-highlighter/dist/cjs/languages/prism/shell-session.js'
import go from 'react-syntax-highlighter/dist/cjs/languages/prism/go.js'
import r from 'react-syntax-highlighter/dist/cjs/languages/prism/r.js'
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript.js'
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown.js'
import kotlin from 'react-syntax-highlighter/dist/cjs/languages/prism/kotlin.js'
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json.js'
import xml from 'react-syntax-highlighter/dist/cjs/languages/prism/xml-doc.js'
import sql from 'react-syntax-highlighter/dist/cjs/languages/prism/sql.js'
import matlab from 'react-syntax-highlighter/dist/cjs/languages/prism/matlab.js'
import less from 'react-syntax-highlighter/dist/cjs/languages/prism/less.js'
import rust from 'react-syntax-highlighter/dist/cjs/languages/prism/rust.js'
import dart from 'react-syntax-highlighter/dist/cjs/languages/prism/dart.js'
import django from 'react-syntax-highlighter/dist/cjs/languages/prism/django.js'
import erlang from 'react-syntax-highlighter/dist/cjs/languages/prism/erlang.js'
import http from 'react-syntax-highlighter/dist/cjs/languages/prism/http.js'
import lisp from 'react-syntax-highlighter/dist/cjs/languages/prism/lisp.js'
import lua from 'react-syntax-highlighter/dist/cjs/languages/prism/lua.js'
import nginx from 'react-syntax-highlighter/dist/cjs/languages/prism/nginx.js'

import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import cls from "classnames";
import {CopyOutlined} from "@ant-design/icons";
import {useCallback} from "react";
import {copyToClip} from "@/common/utils/tools.js";
import {message} from "antd";
import {MarkdownLoadingPic} from "@/pages/Talk/index.jsx";

SyntaxHighlighter.registerLanguage('javascript', javascript)
SyntaxHighlighter.registerLanguage('java', java)
SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('php', php)
SyntaxHighlighter.registerLanguage('csharp', csharp)
SyntaxHighlighter.registerLanguage('cpp', cpp)
SyntaxHighlighter.registerLanguage('css', css)
SyntaxHighlighter.registerLanguage('ruby', ruby)
SyntaxHighlighter.registerLanguage('c', c)
SyntaxHighlighter.registerLanguage('objectivec', objectivec)
SyntaxHighlighter.registerLanguage('swift', swift)
SyntaxHighlighter.registerLanguage('scala', scala)
SyntaxHighlighter.registerLanguage('shell', shell)
SyntaxHighlighter.registerLanguage('go', go)
SyntaxHighlighter.registerLanguage('r', r)
SyntaxHighlighter.registerLanguage('typescript', typescript)
SyntaxHighlighter.registerLanguage('markdown', markdown)
SyntaxHighlighter.registerLanguage('kotlin', kotlin)
SyntaxHighlighter.registerLanguage('json', json)
SyntaxHighlighter.registerLanguage('xml', xml)
SyntaxHighlighter.registerLanguage('html', xml)
SyntaxHighlighter.registerLanguage('sql', sql)
SyntaxHighlighter.registerLanguage('matlab', matlab)
SyntaxHighlighter.registerLanguage('less', less)
SyntaxHighlighter.registerLanguage('rust', rust)
SyntaxHighlighter.registerLanguage('dart', dart)
SyntaxHighlighter.registerLanguage('django', django)
SyntaxHighlighter.registerLanguage('erlang', erlang)
SyntaxHighlighter.registerLanguage('http', http)
SyntaxHighlighter.registerLanguage('lisp', lisp)
SyntaxHighlighter.registerLanguage('lua', lua)
SyntaxHighlighter.registerLanguage('nginx', nginx)

const syntaxTheme = oneDark

const MarkdownSyntaxHighlighter = ({node, className, ...props}) => {
  const hasLang = /language-(\w+)/.exec(className || '');
  const hasMeta = node?.data?.meta;

  const handleClick = useCallback(() => {
    copyToClip(props.children).then(() => {
      void message.success('已复制')
    })
  }, [props.children])
  const applyHighlights = (applyHighlights) => {
    if (hasMeta) {
      const RE = /{([\d,-]+)}/;
      const metadata = node.data.meta?.replace(/\s/g, '');
      const strlineNumbers = RE?.test(metadata)
        ? RE?.exec(metadata)[1]
        : '0';
      const highlightLines = rangeParser(strlineNumbers);
      const highlight = highlightLines;
      const data = highlight.includes(applyHighlights)
        ? 'highlight'
        : null;
      return { data };
    } else {
      return {};
    }
  };
  const codeContent = props.children
  return hasLang ? (
    <div className="markdown-message-code-wrapper">
      <div className="markdown-message-code-header">
        <div className="markdown-message-code-language">{hasLang[1]}</div>
        <div className="markdown-message-code-action" onClick={handleClick}>
          <CopyOutlined />Copy code
        </div>
      </div>
      <SyntaxHighlighter
        showLineNumbers={true}
        style={syntaxTheme}
        customStyle={{margin: 0, borderRadius: '0 0 .5rem .5rem'}}
        language={hasLang[1]}
        PreTag="div"
        className="markdown-message-code"
        wrapLines={hasMeta}
        lineNumberStyle={{minWidth: '3em', paddingRight: '.5em'}}
        useInlineStyles={true}
        lineProps={applyHighlights}
      >
        {codeContent?.endsWith(MarkdownLoadingPic) ? codeContent.substring(0, props.children.length - MarkdownLoadingPic.length) : codeContent}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code className={className} {...props} />
  )
}

const MarkdownComponents = {
  code: MarkdownSyntaxHighlighter
}

const MarkdownMessage = ({content, className, style, ...otherProps}) => {
  return (
    <div className={cls('markdown-message', className)} style={style}>
      <Markdown
        components={MarkdownComponents}
        remarkPlugins={[remarkGfm]}
        {...otherProps}
      >{content}</Markdown>
    </div>
  )
}

export default MarkdownMessage