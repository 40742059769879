import { EditOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { ProForm, ProFormText } from '@ant-design/pro-components'
import { useInterval, useRequest } from 'ahooks'
import { useT } from '@/common/utils/translation'
import { outLogin, sendCode, updatePassword } from '@/services/common.js'
import { useRootContext } from '@/common/RootContext.jsx'
import { textHyposensitization } from '@/common/utils/tools.js'
import { myCookie } from '@/common/utils/cookie.js'

const EditPassword = ({ onClickEditPassword }) => {
  let t = useT()
  const rootContentData = useRootContext()
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(60)
  const [interval, setInterval] = useState(undefined)
  const [codeError, setCodeError] = useState(false)
  const formRef = useRef()
  const [messageApi, contextHolder] = message.useMessage()
  const clear = useInterval(() => {
    setCount(count - 1)
  }, interval)

  useEffect(() => {
    if (count === 0) {
      setInterval(undefined)
      clear()
    }
  }, [count])

  const { runAsync: runSendCode, loading: sendCodeLoading } = useRequest(sendCode,
    { manual: true })
  const {
    runAsync: runUpdatePassword,
    loading: updatePasswordLoading,
  } = useRequest(updatePassword,
    {
      manual: true, onSuccess: (res) => {
        if (res.success) {
          messageApi.open({ type: 'success', content: t('components_change_password') })
          outLogin().then((res) => {
            if (res.success) {
              message.success(t('components_exit_successfully'))
              myCookie.delete('kerqu-admin-token')
              sessionStorage.removeItem('kerquTraderToken')
              rootContentData.dispatchSetLoginStatus(false)
              rootContentData.dispatchSetLoginVisible(true)
            } else {
              message.error(res.message)
            }
          })
          setOpen(false)
        } else {
          if (res.code === 'VERIFY_CODE_ERROR') {
            setCodeError(true)
          } else {
            messageApi.open({ type: 'error', content: res.message })
          }
        }
      },
    })

  const getCapture = async () => {
    if (sendCodeLoading) {
      return
    }
    const phone = rootContentData?.userInfo?.admin?.phone
    if (!phone) {
      return
    }
    setInterval(1000)
    setCount(60)
    await runSendCode({ phone })
  }

  const onOk = () => {
    formRef.current?.validateFieldsReturnFormatValue?.().then(async (values) => {
      await runUpdatePassword(values)
    })
  }

  useEffect(() => {
    if (!open) {
      formRef.current?.setFieldsValue({ code: '', password: '' })
    }
  }, [open])

  return (
    <>
      {contextHolder}
      <div style={{ fontSize: '0.88rem', paddingTop: '6px', paddingInline: '10px' }} className="text-center cursor-pointer" onClick={() => {
        setOpen(true)
        onClickEditPassword()
      }}>
        <EditOutlined/>&nbsp;{t('components_change_password')}
      </div>
      {open ? <Modal destroyOnClose centered open={open}
                     title={t('components_change_password')}
                     onCancel={() => { setOpen(false) }} onOk={onOk}
                     cancelText="取消" okText="确定"
                     confirmLoading={updatePasswordLoading}>
        <div style={{ marginTop: '24px' }}>{t(
          'components_send_verification_code_to_mobile_phone')} {textHyposensitization(
          rootContentData?.userInfo?.admin?.phone,
          3,
          8,
        )}</div>
        <ProForm formRef={formRef} style={{ marginTop: '24px' }} submitter={{
          resetButtonProps: { style: { display: 'none' } },
          submitButtonProps: { style: { display: 'none' } },
        }}>
          <ProFormText
            name="code"
            label={t('components_verification_code')}
            rules={[
              {
                required: true,
                message: t(
                  'components_verification_code_is_required'),
              }]}
            fieldProps={{
              suffix: (interval ? <div>{count}{t(
                'components_second')}</div> : <a
                onClick={() => getCapture()}>{t(
                'components_get_verification_code')}</a>),
            }}
            validateStatus={codeError ? 'error' : ''}
            help={codeError ? t('components_verification_code_error') : ''}
          />
          <ProFormText.Password name="password"
                                label={t('components_new_password')} rules={[
            {
              required: true,
              message: t('components_new_password_is_required'),
            }]}/>
        </ProForm>
      </Modal> : null}
    </>
  )
}

export default EditPassword