/**
 * vip套餐弹窗
 */
import {Modal, Button} from 'antd';
import {Popup, Swiper} from 'antd-mobile';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {VipContext} from '@/context/vip/index.jsx';
import {useRootContext} from '@/common/RootContext.jsx';
import {getVipPackageList, getPackageById} from '@/services/vip.js';
import useIsMobile from '@/common/hooks/useIsMobile';
import styles from './index.module.scss';
import SettlementBoard from '../SettlementBoard/index';
import {useRequest} from 'ahooks';
import {useTranslation} from 'react-i18next';
import useVipPackageBtnName from '@/hooks/useVipPackageBtnName.js';
import {PrimaryColor} from "@/const/index.jsx";

// 套餐版本：0免费版，1基础班，2专业版
export const VIP_PACKAGE_VERSION = {
  FREE: 'FREE',
  BASIC: 'STANDARD',
  PRO: 'PROFESSIONAL',
};

// 套餐类型：0年付，1月付 -1 免费
const PAY_TYPE_MAP = {
  FREE: 'FREE',
  MONTH: 'MONTHLY',
  YEAR: 'ANNUAL',
};

// GPT类型
const GPT_TYPE_MAP = {
  GPT35: 'GPT-3.5',
  GPT4: 'GPT-4',
  GPT4_VISION: 'GPT-4 Vision',
};

// 套餐元素类型预设
/**
 * 根据当前用户的版本展示
 * 免费版  按照原本的逻辑展示
 * 基础版:   免费版 免费使用 可点，点击关闭弹窗  ; 基础版 展示 续费; 专业版 展示升级
 * 专业版: 免费版 点击关闭弹窗，基础版，展示续费， 专业版展示续费
 */

const useVIP_PACKAGE_DEFAULT_CONFIG = () => {
  const {vipPackageBtnName} = useVipPackageBtnName();
  return [
    {
      type: VIP_PACKAGE_VERSION.FREE,
      btn: vipPackageBtnName[VIP_PACKAGE_VERSION.FREE].name,
      btn_style: {
        background: 'rgba(0,0,0,0.88)',
        color: '#fff',
      },
    },
    {
      type: VIP_PACKAGE_VERSION.BASIC,
      btn: vipPackageBtnName[VIP_PACKAGE_VERSION.BASIC].name,
      tag_style: {
        background: '#EDF4FF',
        color: PrimaryColor,
      },
      btn_style: {},
    },
    {
      type: VIP_PACKAGE_VERSION.PRO,
      btn: vipPackageBtnName[VIP_PACKAGE_VERSION.PRO].name,
      tag_style: {
        background: '#FEF7E3',
        color: '#FF561E',
      },
    },
  ];
};

// 最大限制
const UNLIMITE = 999999;

// 年
const YEAR_DAYS = 365;

// 内存单位
const STORAGE_SIZE = 1024;

/**
 * 转换时间
 * @param {*} day
 * @returns
 */
const Time = (day) => {
  const {t} = useTranslation();
  if (day % YEAR_DAYS) {
    return (
        <>
          {day} {t('components_day')}
        </>
    );
  }
  return (
      <>
        {day / YEAR_DAYS} {t('components_year')}
      </>
  );
};

/**
 * 转换占用内存大小
 * @param {*} size
 * @returns
 */
const size = (size) => {
  if (size / STORAGE_SIZE < 1000) {
    return (size / STORAGE_SIZE).toFixed(0) + 'MB';
  } else {
    return (size / (STORAGE_SIZE * STORAGE_SIZE)).toFixed(0) + 'GB';
  }
};

/**
 * 权益配置
 * @param {*} param0
 * @returns
 */
const useInterest = ({
  type,
  equityAnswersCount,
  equityGpt,
  equityImageCount,
  equityAppCount,
  equityAccountCount,
  equitySaveDays,
  equityStorage,
}) => {
  const {t} = useTranslation();

  return [
    ` ${
        equityAnswersCount === UNLIMITE
            ? t('createCenter_unlimited')
            : (type === VIP_PACKAGE_VERSION.FREE
                ? t('createCenter_per_month')
                : '') +
            ' ' +
            equityAnswersCount
    } ${t('createCenter_applications_free_questions_and_answers')}`,
    `${equityGpt.map((_) => GPT_TYPE_MAP[_]).filter(Boolean).join('/')} `,
    `${t('createCenter_generate_pictures')} ${equityImageCount} ${t(
        'createCenter_zhang_month',
    )}`,
    `${
        equityAppCount === UNLIMITE
            ? t('components_unlimited_number_of_custom_apps')
            : equityAppCount + t('createCenter_custom_apps')
    }`,
    `${t('createCenter_number_of_accounts')} ${equityAccountCount} ${t(
        'createCenter_indivual',
    )}`,
    <>
      {t('createCenter_creation_record_storage_time')} {Time(equitySaveDays)}
    </>,
    <>
      {size(equityStorage)} {t('createCenter_storage')}
    </>,
  ];
};

const VipModulePackageModal = () => {
  const {t, i18n} = useTranslation();
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState();
  let interest = useInterest;
  let VIP_PACKAGE_DEFAULT_CONFIG = useVIP_PACKAGE_DEFAULT_CONFIG();
  const PAY_TYPE = [
    {lable: t('components_monthly_payment'), value: PAY_TYPE_MAP.MONTH},
    {lable: t('components_annual_fee'), value: PAY_TYPE_MAP.YEAR},
  ];
  const [isMobile] = useIsMobile();
  const {vipModalVisible, dispatchSetVipModalVisible} =
      useContext(VipContext);
  const [currentUserVipPlan, setCurrentVipPlan] = useState('');
  const [currentSelect, setCurrentSelect] = useState('');
  const [payType, setPayType] = useState(PAY_TYPE_MAP.YEAR);
  const [data, setData] = useState([]);
  const rootContext = useRootContext();

  useEffect(() => {
    // 重置数据
    setPayType(PAY_TYPE_MAP.YEAR);
  }, [vipModalVisible]);

  useEffect(() => {
    if (rootContext.loginStatus) {
      setCurrentVipPlan(rootContext?.userCurrentPlan?.vipPackage?.packageName);
    } else {
      setCurrentVipPlan('');
    }
  }, [rootContext]);

  useEffect(() => {
    // getVipPackageList().then((res) => {
    //   setData(res?.data?.items);
    // });
  }, [i18n.language]);
  // 渲染VIP套餐数据
  const renderData = useMemo(() => {
    const _data = data?.filter(
        (item) =>
            item.packageType === payType ||
            item.packageType === VIP_PACKAGE_VERSION.FREE,
    ).map((item) => {
      const defaultConfig = VIP_PACKAGE_DEFAULT_CONFIG.find(
          (_) => _.type === item.packageVersion,
      );
      return {
        ...item,
        ...defaultConfig,
        year_price: item?.packagePrice
            ? (item?.packagePrice * 12).toFixed(1)
            : 0,
        year_m_price: item?.packagePrice,
        month_price: item?.packagePrice,
      };
    });
    return _data;
  }, [data, payType]);

  const handleSelectPackage = (pkg) => {
    // 未登录需要触发登录弹窗
    if (!rootContext.loginStatus) {
      dispatchSetVipModalVisible();
      rootContext.dispatchSetLoginVisible(true);
      return;
    }
    setCurrentSelect(pkg.packageName);
    if (pkg.type === VIP_PACKAGE_VERSION.FREE) {
      dispatchSetVipModalVisible();
    } else {

      setId(pkg.id);
      dispatchSetVipModalVisible();
      setVisible(true);
    }
  };

  const VipContent = () => (
      <div
          className={`${styles.vipPackageModalBox} ${
              isMobile ? styles.mobileVipPackageModalBox : ''
          }`}
      >
        <div className={styles.headerBox}>
          <div className={styles.btnsWarp}>
            {PAY_TYPE?.map((_) => (
                <span
                    key={_.value}
                    onClick={() => {
                      setPayType(_.value);
                    }}
                    className={payType == _.value ? styles.actived : {}}
                >
              {_.lable}
            </span>
            ))}
          </div>
        </div>
        {currentUserVipPlan && (
            <div className={styles.selecedtInfoBox}>
              {t('components_the_package_you_are_currently_selecting_is')}
              <span>
            &nbsp;{t('components_vip_package')}-{currentUserVipPlan}&nbsp;
          </span>
              ，
              <div className={isMobile ? '' : 'inline-block'}>
                {t(
                    'components_please_select_the_appropriate_package_to_upgrade_renew',
                )}
              </div>
            </div>
        )}

        <div className={styles.packageBox}>

          {renderData?.map((item, i) => (
              <div className={styles.packageItme} key={item.type + i}>
                <div className={styles.packageName}>
                  <span className={styles.name}>{item.packageName}</span>
                  {item.packageLabel && (
                      <div
                          className={styles.tag}
                          style={item?.tag_style ? item?.tag_style : {}}
                      >
                        {item.packageLabel}
                      </div>
                  )}
                </div>
                <div className={styles.des}>{item.packageInfo}</div>
                <div className={styles.price}>
                <span>
                  <b>
                    $
                    {payType == PAY_TYPE_MAP.YEAR
                        ? item?.year_m_price
                        : item?.month_price}
                  </b>
                  /{t('createCenter_moon')}
                </span>
                  {payType == PAY_TYPE_MAP.YEAR && !!item?.year_price && (
                      <span>
                    ${item?.year_price}/{t('components_year')}
                  </span>
                  )}
                </div>
                <Button
                    className={styles.btn}
                    onClick={() => {
                      handleSelectPackage(item);
                    }}
                    style={item?.btn_style && item?.btn_style}
                >
                  {item.btn}
                </Button>
                <div className={styles.interest}>
                  {interest(item)?.map((_, idx) => (
                      <p key={idx} className="whitespace-normal">
                        <i></i>
                        <span>{_}</span>
                      </p>
                  ))}
                </div>
              </div>
          ))}

        </div>
      </div>
  );
  return isMobile ? (
      <>
        <Modal
            open={vipModalVisible}
            className={styles.mobileModal}
            footer={null}
            onCancel={dispatchSetVipModalVisible}
        >
          <VipContent/>
        </Modal>
        {
            id && <SettlementBoard
                onCancel={(value) => {
                  setVisible(value);
                }}
                visible={visible}
                id={id}
                onBack={() => {
                  dispatchSetVipModalVisible(true);
                  setVisible(false);
                }}
            />
        }
      </>
  ) : (
      <>
        {
            id && <SettlementBoard
                onCancel={(value) => {
                  setVisible(value);
                }}
                visible={visible}
                id={id}
                onBack={() => {
                  dispatchSetVipModalVisible(true);
                  setVisible(false);
                }}
            ></SettlementBoard>
        }
        <Modal
            open={vipModalVisible}
            className={styles.modal}
            footer={null}
            onCancel={dispatchSetVipModalVisible}
        >
          <VipContent/>
        </Modal>
      </>
  );
};

export default VipModulePackageModal;
