import {useImperativeHandle, useState} from 'react';
import {Modal} from 'antd';
import MemberTable from '@/pages/components/TeamMember/MemberTable.jsx';
import {useT} from '@/common/utils/translation';

const TeamMember = (props) => {
  let t = useT();
  const [open, setOpen] = useState(false);
  useImperativeHandle(props.memberRef, () => {
    return {
      open: () => {
        setOpen(true);
      },
    };
  });

  const onOpenTeamInviteModal = () => {
    setOpen(false);
  };

  return (
      <div className="member-modal">
        <Modal
            centered
            title={t('components_member_management')}
            footer={null}
            width="55rem"
            open={open}
            onCancel={() => {
              setOpen(false);
            }}
        >
          <MemberTable onOpenTeamInviteModal={onOpenTeamInviteModal}/>
        </Modal>
      </div>
  );
};

export default TeamMember;
