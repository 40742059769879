import cls from 'classnames';

const SvgIcon = (props) => {
  const {
    name,
    prefix = 'icon',
    style,
    className,
    onClick,
    useSpan,
  } = props;
  const symbolId = `#${prefix}-${name}`;
  return (
      useSpan ? <span role="img" aria-label="order" className="anticon"><svg
          aria-hidden="true" style={style}
          className={cls('svg-icon', className)}
          onClick={onClick}>
        <use href={symbolId}/>
      </svg></span> : <svg aria-hidden="true" style={style}
                           className={cls('svg-icon', className)}
                           onClick={onClick}>
        <use href={symbolId}/>
      </svg>
  );
};

export default SvgIcon;
