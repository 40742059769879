import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import {Button} from 'antd';
import {useEmotionCss} from '@ant-design/use-emotion-css';

const BackIcon = ({onClick}) => {
  const buttonClassname = useEmotionCss(() => {
    return {
      position: 'absolute',
      top: '17px',
      insetInlineStart: '17px',
      width: '22px !important',
      height: '22px',
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.45)',
      zIndex: '1010',
      fontWeight: '600',
      lineHeight: 1,
      textDecoration: 'none',
      border: 0,
      outline: 0,
      'svg': {
        pointerEvents: 'none',
      },
      '.ant-btn-icon': {
        display: 'flex',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: '22px',
        justifyContent: 'center',
        textTransform: 'none',
        textRendering: 'auto',
      },
    };
  });
  return (
      <Button
          type="text"
          onClick={() => onClick?.()}
          className={buttonClassname}
          icon={<LeftOutlined size={16}/>}
      />
  );
};

export default BackIcon;