import {ProForm, ProFormText} from '@ant-design/pro-components';
import CardImageBox from '@/pages/components/SettlementBoard/CardImageBox.jsx';
import {CreditCardOutlined} from '@ant-design/icons';
import { useT } from '@/common/utils/translation';

const CardPay = () => {
  let t = useT()
  return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{width: '440px'}}>
          <div className="title" style={{marginBottom: '2rem'}}>{t('components_package_payment')}</div>
          <CardImageBox/>
          <ProForm requiredMark={false} submitter={{
            submitButtonProps: {
              style: {width: '100%'},
              size: 'large',
            },
            resetButtonProps: {style: {display: 'none'}},
            searchConfig: {submitText: t('components_pay_immediately')},
          }}>
            <ProForm.Group>
              <ProFormText label={t('components_card_information')} name="card" width="lg"
                           rules={[{required: true, message: t('components_please_enter_card_information')}]}
                           placeholder="1234 5678 9012 3456" fieldProps={{
                suffix: <CreditCardOutlined
                    style={{color: 'rgba(0, 0, 0, 0.25)'}}/>,
              }}/>
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText label={t('components_validity_period')} name="time" placeholder="MM/YY"
                           rules={[{required: true, message: t('components_please_enter_the_validity_period')}]}/>
              <ProFormText label={t('components_security_code')} name="code" placeholder={t('components_security_code')}
                           rules={[{required: true, message: t('components_please_enter_security_code')}]}/>
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText label={t('components_cardholder_s_name')} name="name" width="lg"
                           placeholder={t('components_please_enter_cardholder_name')}
                           rules={[
                             {
                               required: true,
                               message: t('components_please_enter_cardholder_name'),
                             }]}/>
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText label={t('components_mail')} name="email" placeholder={t('components_please_input_your_email')}
                           width="lg"
                           rules={[{required: true, message: t('components_please_input_your_email')}]}/>
            </ProForm.Group>
          </ProForm>
        </div>
      </div>
  );
};

export default CardPay;