
import request from "@/services/index";

// 我的分享链接
export const getMyShareLink = (redirect) => {
    return request.get(`/api/share/my?redirect=` + redirect);
}



// 添加分享记录
export const addShareRecord = (data) => {
    return request.post(`/api/share/add`, {
        ...data
    });
}


// 获取分析人数
export const getShareCount = () => {
    return request.get(`/api/share/count`);
}
    