import {useState} from "react";
import ApplicatinList from "@/pages/CustomerApplication/ApplicatinList.jsx";

function CustomerApplication() {

  const [steps, setSteps] = useState(1);

  return (
    <>
      {steps === 1 && <ApplicatinList />}
    </>
  )
}

export default CustomerApplication;