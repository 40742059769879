import {myCookie} from '@/common/utils/cookie';
import axios from 'axios';

// const API_BASE_URL = 'https://kerqu-dtc-dev-kerqu-dtc-test-fcmoxdaqtz.cn-hangzhou.fcapp.run';
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL_DTC;

// 创建Axios实例
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
    (config) => {
      const groupId = sessionStorage.getItem('groupId');
      if (groupId) {
        config.headers['kerqu-group'] = groupId;
      }

      const traderToken = myCookie.get('kerqu-admin-token');
      if (traderToken) {
        config.headers['kerqu-admin-token'] = traderToken;
      }


      // 在发送请求之前做些什么，例如添加身份验证信息等
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    },
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
    (response) => {
      // 对响应数据做些什么
      return response;
    },
    (error) => {
      // 对响应错误做些什么
      return Promise.reject(error);
    },
);

// 封装常用的请求方法
const LoginRequest = {
  get: async (url, params) => {
    try {
      const response = await axiosInstance.get(url, {params});
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  post: async (url, data) => {
    try {
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  put: async (url, data) => {
    try {
      const response = await axiosInstance.put(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  delete: async (url, data) => {
    try {
      const response = await axiosInstance.delete(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  // 其他HTTP方法的封装方法，例如put、delete等
};

export default LoginRequest;
