import {useTranslation} from "react-i18next";

function MenuName(props) {
    const {keyName} = props;
    const {t} = useTranslation();
    return (
        <span>{t(keyName)}</span>
    )
}

export default MenuName;