import styles from './index.module.scss';
import React from "react";

function HeaderTitle({title, rightRender}) {
  return <div
    className={styles.HeaderTitleContainer}
  >
    <span>{title}</span>
    {rightRender}
  </div>
}
export default HeaderTitle;