export default {
  components_after_switching_identities_the_permissions_of_the_opened_pages_will_be_refreshed_please_ensure_that_the_content_you_write_is_properly_saved: 'After switching identities, the permissions of the opened pages will be refreshed. Please ensure that the content you write is properly stored.',
  ai_brief_background: 'Brief background',
  ai_set_goals: 'Set goals',
  ai_set_up_a_role: 'Set up a role',
  createCenter_the_team_is_successful: 'The team is successful',
  createCenter__the_mobile_phone_number_to_join_is: 'The mobile phone number to join is',
  ai_smart_questions: 'Smart questions',
  ai_supplementary_requirements: 'Supplementary requirements',
  ai_updating: 'Updating',
  application_detail: 'Application detail',
  button_update_permissions: 'Upgrade permissions',
  common_Login_account: 'Login account',
  common_agree_and_register: 'Agree and register',
  common_automatically_log_in_within_seven_days: 'Automatically log in within seven days',
  common_disagree: 'D_taisagree',
  common_free_registration: 'Free registration',
  common_go_to_login: 'Go to login',
  common_reset: 'Reset',
  common_log_in: 'Log in',
  common_login_successful: 'Login successful',
  common_password_login: 'Password login',
  common_please_enter_password: 'Please enter password',
  common_please_enter_the_correct_phone_number: 'Please enter the correct phone number',
  common_please_enter_the_phone_number: 'Please enter the phone number',
  common_please_enter_verification_code: 'Please enter verification code',
  common_read_and_agree: 'Read and agree',
  common_register: 'Register',
  common_register_an_account: 'Register an account',
  common_registration_success: 'Registration success',
  common_user_agreement: 'User agreement',
  common_user_agreement_and_privacy_protection: 'User agreement and privacy protection',
  common_verification_code_login: 'Verification code login',
  components_1gb_storage_space: '1gb storage space',
  components_3_custom_apps: '3 custom apps',
  components_5_accounts_2000_applications_free_questions_and_answers_300_pictures: '5 accounts, 2000 applications/free questions and answers, 300 pictures',
  components_20_accounts_unlimited_applications_free_questions_and_answers_1_000_pictures: '20 accounts, unlimited applications/free questions and answers, 1,000 pictures',
  components_20mb_storage_space: '20mb storage space',
  components_50_applications_free_questions_and_answers_per_month: '50 applications/free questions and answers per month',
  components_300mb_storage_space: '300mb storage space',
  components_2000_applications_free_questions_and_answers: '2000 applications/free questions and answers',
  components_Each_use_consumes_15_gpt_3_5_usage: 'Gpt-3.5 consumes 1 point per use, gpt-4 consumes 15 points per use, and gpt-4 vision consumes 30 points per use',
  components_Year: 'Year',
  components_add_pictures: 'Add pictures',
  components_added: 'Added',
  components_administrator: 'Administrator',
  components_after_deleting_a_member_the_content_created_by_the_member_will_still_be_retained_do_you_want_to_continue_deeting_it: 'After deleting a member the content created by the member will still be retained. do you want to continue deleting it',
  components_after_inviting_external_people_to_join_the_team_it_becomes: 'After inviting external people to join the team, it becomes',
  components_after_switching_identities_the_permissions_of_the_opened_pages_will_be_refreshed_please_ensure_that_the_conent_you_write_is_properly_saved: 'After switching identities the permissions of the opened pages will be refreshed please ensure that the content you write is properly saved',
  components_ai_picture_creation: 'Ai picture creation',
  components_alipay: 'Alipay',
  components_annual_fee: 'Annual fee',
  components_application_free_q_a_gpt_3_5: 'AI q&a',
  components_application_free_q_a_gpt_3_5_tooltip: 'Used points/total points in this billing cycle',
  components_application_free_q_a_gpt_4: 'Application/free q&a (gpt-4)',
  components_application_free_q_gpt_4_vision: 'Application/free q&a (gpt-4 vision)',
  components_automatically_cutting_out_pictures: 'Automatically cutting out pictures',
  components_automatically_extend_the_service_period_of_new_packages_by_73_days: 'Automatically extend the service period of new packages by 73 days',
  components_bank_card: 'Credit card',
  components_basic_version: 'Basic version',
  components_basic_version_vip: 'Basic version·vip',
  components_batch_save: 'Batch save',
  components_billing_cycle: 'Billing cycle',
  components_buy_it_now: 'Buy it now',
  components_cancel: 'Cancel',
  components_card_information: 'Card information',
  components_cardholder_s_name: 'Cardholder\'s name',
  components_order_center: 'Order Center',
  components_change_password: 'Change password',
  components_click_drag: 'Click/drag',
  components_click_or_drag_files_into_this_area_to_upload_files: 'Click or drag files into this area to upload files',
  components_closure: 'Closure',
  components_common: 'Common',
  components_confirm: 'Confirm',
  components_confirm_deletion: 'Confirm deletion',
  components_confirm_payment: 'Confirm payment',
  components_confirm_switch: 'Switch',
  components_confirm_the_changes: 'Confirm the changes',
  components_congratulations_you_have_received_1_month_vip_package_basic_version: 'Congratulations you have received 1-month vip package·basic version',
  components_copied_successfully: 'Copied successfully',
  components_copy_link: 'Copy link',
  components_creation_records_are_kept_for_1_year: 'Creation records are kept for 1 year',
  components_creation_records_are_kept_for_7_days: 'Creation records are kept for 7 days',
  components_creation_records_are_kept_for_10_years: 'Creation records are kept for 10 years',
  components_credit_card: 'Credit card',
  components_cutout: 'Cutout',
  components_day: 'Day',
  components_debit: 'Debit',
  components_deduction_from_original_package_balance: 'Deduction from original package balance',
  components_delete: 'Delete',
  components_delete_app: 'Delete app',
  components_delete_member: 'Delete member',
  components_download_package: 'Download package',
  components_each_use_consumes_30_gpt_3_5_usage: 'Each use consumes 30 gpt-3.5 usage',
  components_exclusive_invitation_link: 'Exclusive invitation link',
  components_exit_successfully: 'Exit successfully',
  components_free: 'Free',
  components_free_to_use: 'Free to use',
  components_free_upgrade_message: 'Upgrade membership for more exclusive benefits',
  components_free_version: 'Free version',
  components_generate_1_000_pictures_month: 'Generate 1,000 pictures/month',
  components_generate_10_pictures_month: 'Generate 10 pictures/month',
  components_generate_300_pictures_month: 'Generate 300 pictures/month',
  components_get_verification_code: 'Get verification code',
  components_i_see: 'I see',
  components_image_generation: 'Image generation',
  components_invite_friends: 'Invite friends',
  components_invite_friends_to_get_free_vip: 'Invite friends to get free vip',
  components_invite_members: 'Invite members',
  components_invite_via_link: 'Invite via link',
  components_join_time: 'Join time',
  components_joined: 'Joined',
  components_link_validity_period: 'Link validity period',
  components_log_in_register: 'Log in register',
  components_login_successful: 'Login successful',
  components_mail: 'Mail',
  components_manager: 'Manager',
  components_maturity: 'Maturity',
  components_me: 'Me',
  components_member: 'Member',
  components_member_full1: 'The current package has reached the maximum number of members. after upgrading, you can continue to invite members',
  components_member_full2: 'The current package has reached the maximum number of members',
  components_member_management: 'Member management',
  components_modify_role: 'Modify role',
  components_modify_the_note_name: 'Modify the note name',
  components_monthly: 'Monthly',
  components_monthly_payment: 'Monthly payment',
  components_monthly_updated_application_free_q_a_picture_usage_quota: 'Monthly updated application/free q&a, picture usage quota',
  components_most_popular: 'Most popular',
  components_name: 'Name',
  components_new_password: 'New password',
  components_new_password_is_required: 'New password is required',
  components_no_more_data: 'No more data',
  components_number_of_accounts_1: 'Number of account: 1',
  components_number_of_accounts_5: 'Number of account: 5',
  components_number_of_accounts_20: 'Number of account: 20',
  components_only_owners_and_administrators_can_edit: 'Only owners and administrators can edit',
  components_open: 'Open',
  components_operate: 'Operate',
  components_order_type: 'Order type',
  components_owner: 'Owner',
  components_package_payment: 'Package payment',
  components_package_version: 'Package version',
  components_pay_immediately: 'Pay immediately',
  components_pay_remaining_time: 'Pay remaining time',
  components_payment_failed: 'Payment failed',
  components_payment_successful: 'Payment successful',
  components_people: 'People',
  components_per_recommendation: 'Per recommendation',
  components_picture_background_change: 'Picture background change',
  components_please_enter_card_information: 'Please enter card information',
  components_please_enter_cardholder_name: 'Please enter cardholder name',
  components_please_enter_security_code: 'Please enter security code',
  components_please_enter_the_validity_period: 'Please enter the validity period',
  components_please_input_your_email: 'Please input your email',
  components_please_pay_again: 'Please pay again',
  components_please_select_the_appropriate_package_to_upgrade_renew: 'Please select the appropriate package to upgrade/renew',
  components_please_select_your_language: 'Please select your language',
  components_please_use_alipay_to_scan_the_qr_code_to_pay: 'Please use alipay to scan the qr code to pay',
  components_please_use_wechat_to_scan_the_qr_code_to_pay: 'Please use wechat to scan the qr code to pay',
  components_preview: 'Preview',
  components_professional_version: 'Professional version',
  components_purchase_package_confirmation_information: 'Purchase package confirmation information',
  components_register_a_friend_independent_ip_and_you_will_automatically_receive_1_month: 'Register a friend (independent ip) and you will automatically receive 1 month',
  components_release_to_load_the_file: 'Release to load the file',
  components_renew: 'Renew',
  components_return: 'Return',
  components_role: 'Role',
  components_image_save: 'save',
  components_image_download: 'download',
  components_image_remove: 'remove',
  components_second: 'Second',
  components_security_code: 'Security code',
  components_send_verification_code_to_mobile_phone: 'Send verification code to mobile phone',
  components_share_creation_application_records: 'Share creation application records',
  components_share_creative_records: 'Share creative records',
  components_share_free_chat_transcripts: 'Share free chat transcripts',
  components_sign_out: 'Sign out',
  components_smart_background_change: 'Smart background change',
  components_smart_cutout: 'Smart cutout',
  components_subscription_fee: 'Subscription fee',
  components_successful_operation: 'Successful operation',
  components_support_batch_upload_support_jpg_png_webp_format_images: 'Support batch upload, support jpg/png/webp format images',
  components_team_member: 'Team member',
  components_team_name: 'Team name',
  components_team_profile: 'Team profile',
  components_team_settings: 'Team settings',
  components_the_copy_link_message: 'Please press ctrl+v to copy',
  components_the_king_of_cost_performance: 'The king of cost performance',
  components_the_link_is_copied_successfully_and_will_expire_in_14_days: 'The link is copied successfully and will expire in 14 days.',
  components_the_package_you_are_currently_selecting_is: 'The package you are currently selecting is',
  components_the_remaining_balance_after_deducting_the_balance_of_your_original_package: 'The remaining balance after deducting the balance of your original package',
  components_the_remaining_service_days_of_the_original_package_will_be_converted_into_a_balance_according_to_the_amount_and_the_original_package_balance_will_be_deducted_from_the_new_package_fee: 'The remaining service days of the original package will be converted into a balance according to the amount, and the original package balance will be deducted from the new package fee.',
  components_the_remaining_service_days_of_the_original_package_will_be_converted_into_a_balance_according_to_the_amountand_the_original_package_balance_will_be_deducted_from_the_new_package_fee: 'The remaining service days of the original package will be converted into a balance according to the amount, and the original package balance will be deducted from the new package fee.',
  components_the_verification_code_has_been_sent_please_check_it_carefully_please_do_not_trigger_it_repeatedly_within_1_minute: 'The verification code has been sent please check it carefully please do not trigger it repeatedly within 1 minute',
  components_timed_out_please_pay_again: 'Timed out, please pay again',
  components_total: 'Total',
  components_total2: 'Total',
  components_turn_on: 'Turn on',
  components_unlimited_applications_free_questions_and_answers: 'Unlimited applications/free questions and answers',
  components_unlimited_number_of_custom_applications: 'Unlimited number of custom applications',
  components_unlimited_number_of_custom_apps: 'Unlimited number of custom apps',
  components_upgrade_package: 'Upgrade package',
  components_upload_team_avatar: 'Upload team avatar',
  components_use_it_now: 'Use it now',
  components_validate_the_form_and_return_all_formatted_data: 'Validate the form and return all formatted data',
  components_validity_period: 'Validity period',
  components_verification_code: 'Verification code',
  components_verification_code_error: 'Verification code error',
  components_verification_code_is_required: 'Verification code is required',
  components_vip_package: 'Vip package',
  components_vip_package_at: 'Vip package at',
  components_vip_package_basic_version: 'Vip package·basic version',
  components_vip_package_expires_on_2023_11_29: 'Vip package expires on 2023-11-29',
  components_vip_package_professional_edition: 'Vip package-professional edition',
  components_vip_package_renewal: 'Renewal',
  components_vip_package_upgrade: 'Upgrade',
  components_wechat: 'Wechat',
  components_whether_to_switch_teams: 'Whether to switch teams',
  components_year: 'Year',
  components_you_have_invited_registered_users: 'You have invited registered user:',
  components_you_have_successfully_purchased_the_vip_package_professional_version: 'You have successfully purchased the vip package-',
  createCenter_accept_an_invitation: 'Accept invitation',
  createCenter_add_pictures: 'Add pictures',
  createCenter_ai_background_generator_delivers_efficient_results: 'Ai background generator delivers efficient results',
  createCenter_ai_generated: 'Ai generated',
  createCenter_ai_is_generating: 'Ai is generating',
  createCenter_ai_picture_creation: 'Ai picture creation',
  createCenter_amazon_operations: 'Amazon operations',
  createCenter_applications_free_questions_and_answers: 'Applications/free questions and answers',
  createCenter_are_you_sure_you_want_to_delete_this_conversation: 'Are you sure you want to delete this conversation?',
  createCenter_automatically_cutting_out_pictures: 'Automatically cutting out pictures',
  createCenter_automatically_generate_article_topics_and_inspiration: 'Automatically generate article topics and inspiration,',
  createCenter_background_selection: 'Background selection',
  createCenter_click_the_button_below_or_drag_in_an_image: 'Click the button below',
  createCenter_click_the_button_below_or_drag_in_an_image2: ' or drag in an image',
  createCenter_confirm_deletion_of_this_creative_content_record: 'Confirm deletion of this creative content record',
  createCenter_content: 'Content',
  createCenter_create_app: 'Create app',
  createCenter_creation_record_storage_time: 'Creation record storage time',
  createCenter_custom_apps: 'Custom apps',
  createCenter_delete: 'Delete',
  createCenter_delete_delete: 'Delete',
  createCenter_delete_record: 'Delete record',
  createCenter_describe_your_background_for_example_decorated_christmas_tree_twinkling_lights: 'Describe your background...for example, decorated christmas tree, twinkling lights',
  createCenter_duplicate_check_rate: 'Duplicate check rate',
  createCenter_edit_application: 'Edit application',
  createCenter_example: 'Exampl:',
  createCenter_expire_after: 'Expire after',
  createCenter_generate_pictures: 'Generate pictures',
  createCenter_gives_inspiration_for_niche_products_in_ten_outdoor_categories_mainly_targeting_middle_and_high_income_groups_in_the_united_states_suitable_for_sale_on_amazon: 'Gives inspiration for niche products in ten outdoor categories, mainly targeting middle and high-income groups in the united states, suitable for sale on amazon.',
  createCenter_go_to_use: 'Go to use',
  createCenter_having_trouble_finding_creative_sources_just_enter_the_description_of_the_picture_scene_you_want_and_our_artificial_intelligence_will_quickly_provide_multiple_sets_of_high_quality_pictures: 'Having trouble finding creative sources? just enter the description of the picture scene you want and our artificial intelligence will quickly provide multiple sets of high-quality pictures.',
  createCenter_hint: 'Hint',
  createCenter_history_record: 'History record',
  createCenter_image: 'Image',
  createCenter_independent_website_search_engine_optimization_seo: 'Independent website search engine optimization (seo)',
  createCenter_independent_websites_and_social_media: 'Independent websites and social media',
  createCenter_indivual: 'Indivual',
  createCenter_invite_you_to_join: 'Invite you to join',
  createCenter_join_in: 'Join in',
  createCenter_moon: 'Moon',
  createCenter_most_popular: 'Most popular',
  createCenter_my_applications: 'My application',
  createCenter_no_conversation: 'No conversation records',
  createCenter_no_more_data: 'No more data',
  createCenter_no_more_worrying_about_long_design_cycles_or_high_costs_just_upload_a_photo_and_ai_will_automatically_remove_the_background_and_apply_your_preferred_scene: 'No more worrying about long design cycles or high costs, just upload a photo and ai will automatically remove the background and apply your preferred scene.',
  createCenter_split_subtitle: 'Upload reference images of product scenes, and within 3 seconds, multiple similar scene images will be generated.',
  createCenter_number_of_accounts: 'Number of accounts',
  createCenter_only_supports_jpg_png_webp_type_image_files: 'Only supports jpg, png, webp type image files',
  createCenter_original_proportions: 'Original',
  createCenter_per_month: 'Per month',
  createCenter_pictures_cannot_exceed_10m: 'Pictures cannot exceed 10m',
  createCenter_please_enter_an_image_description: 'Please enter an image description!',
  createCenter_please_enter_custom_writing_instructions: 'Please enter custom writing instructions',
  createCenter_please_enter_your_name_in_the_team: 'Please enter your name in the team',
  createCenter_please_give_me_ten_english_brand_names_for_my_ebike_products_the_style_is_technological_and_futuristic: 'Please give me ten english brand names for my ebike products. the style is technological and futuristic.',
  createCenter_please_help_me_list_10_target_groups_for_ebike_products_and_describe_their_characteristics_including_age_gender_and_other_characteristics: 'Please help me list 10 target groups for ebike products and describe their characteristics, including age, gender, and other characteristics.',
  createCenter_please_plan_facebook_posts_for_my_ebike_products_in_the_next_month_in_english: 'Please plan facebook posts for my ebike products in the next month in english.',
  createCenter_please_write_an_english_about_us_document_for_my_ebike_independent_station: 'Please write an english about us document for my ebike independent station.',
  createCenter_please_write_an_seo_article_about_ebike_products_the_main_selling_points_of_the_product_are_environmental_protection_long_battery_life_and_portability_the_output_language_is_english_800_words: 'Please write an seo article about ebike products. the main selling points of the product are environmental protection, long battery life, and portability. the output language is english, 800 words.',
  createCenter_preview: 'Preview',
  createCenter_quickly_cut_out_images_in_batches: 'Quickly cut out images in batches',
  createCenter_release_to_load_the_file: 'Release to load the file',
  createCenter_remark_name: 'Remark name',
  createCenter_scene_description: 'Scene description',
  createCenter_enhanced_content_description: 'Enhanced content description',
  createCenter_original_similarity: 'Original Similarity',
  createCenter_seo_article_assistant: 'Seo article assistant',
  createCenter_product_scene_fission: 'Product scene fission',
  createCenter_sexy_woman_walking_on_the_streets_of_new_york_carrying_trolley_suitcase_medium_shot: 'Sexy woman walking on the streets of new york, carrying trolley suitcase, medium shot',
  createCenter_size_selection: 'Size selection',
  createCenter_storage: 'Storage',
  createCenter_supports_batch_upload_of_pictures_up_to_100_pictures: 'Supports batch upload of pictures, up to 100 pictures',
  createCenter_target_word_buried_rate: 'Target word buried rate,',
  createCenter_team: 'Team',
  createCenter_the_current_invitation_will_be: 'The current invitation will be',
  createCenter_the_team_is_successful_the_mobile_phone_number_to_join_is: 'The team is successful, the mobile phone number to join is',
  createCenter_this_page_is_missing: 'This page is missing',
  createCenter_trained_on_millions_of_real_world_images_our_clever_ai_can_automatically_handle_almost_any_complex_background_in_3_seconds: 'Trained on millions of real-world images, our clever ai can automatically handle almost any complex background in 3 seconds.',
  createCenter_unlimited: 'Unlimited',
  createCenter_unlimited_number_of_custom_apps: 'Unlimited number of custom apps',
  createCenter_welcome_to_kerqu_ai_for_free_conversation1: 'Welcome to',
  createCenter_welcome_to_kerqu_ai_for_free_conversation2: 'Kerqu.ai',
  createCenter_welcome_to_kerqu_ai_for_free_conversation3: 'For free conversation',
  createCenter_whether_it_is_design_creativity_or_artistic_creation_it_can_make_your_creation_easier_and_freer: 'Whether it is design creativity or artistic creation, it can make your creation easier and freer.',
  createCenter_write_down_a_task_and_kua_ai_will_do_the_rest_for_you_not_sure_where_to_start_click_on_the_cross_border_cases_below_for_inspiration: 'Write down a task, and Kerqu.Ai will do the rest for you. not sure where to start? click on the cross-border cases below for inspiration.',
  createCenter_zhang_month: 'Zhang/month',

  talk_point_cost: ' (cost $1 point) ',

  cross_border_seller_ai_communication_group: 'Cross border seller ai communication group',
  homeName: 'Home',
  login_send_the_verification_code: 'Send',
  menu_ai_generated: 'Ai generated',
  menu_ai_generating: 'Ai generating',
  menu_ai_results: 'Ai results',
  menu_amazon: 'Amazon',
  menu_app_description_cannot_be_empty: 'App description cannot be empty',
  menu_application_description: 'Application description',
  menu_application_information: 'Application information',
  menu_application_add_picture: 'Whether to upload images',
  menu_application_name: 'Application name',
  menu_application_name_cannot_be_empty: 'Application name cannot be empty',
  menu_arabic: 'Arabic',
  menu_article: 'B2B seo article',
  menu_b2c_website_building: 'B2C website building',
  menu_b2b_website_building: 'B2B website building',

  menu_build_a_website: 'Build a website',
  menu_category_selection: 'Category selection',
  menu_chinese: 'Chinese',
  menu_confirm_release: 'Confirm release',
  menu_copied_successfully: 'Copied successfully',
  menu_copied_to_clipboard: 'Copied to clipboard',
  menu_create_app: 'Create app',
  menu_created_successfully: 'Created successfully',
  menu_creation_center: 'Creation center',
  menu_menu_other: 'Other',

  menu_creator_name: 'Creator name',
  menu_data_returned_successfully: 'D_ta returned successfully',
  menu_describe: 'Describe',
  menu_dutch: 'Dutch',
  menu_english: 'English',
  menu_foreign_trade: 'Foreign trade',
  menu_foreign_trade_development_letter: 'Foreign trade development letter',
  menu_french: 'French',
  menu_german: 'German',
  menu_independent_station: 'Online Stores',
  menu_independent_station_customer_service: 'Independent station customer service',
  menu_independent_station_product_selection: 'Independent station product selection',
  menu_indonesian: 'Indonesian',
  menu_insert_variable_parameters: 'Insert variable parameters',
  menu_italian: 'Italian',
  menu_japanese: 'Japanese',
  menu_keyword_planning: 'Keyword planning',

  menu_other_sumaitong: 'AliExpress ',
  menu_other_etsy: 'Etsy',
  menu_other_shopee: 'Shopee',
  menu_other_ebay: 'Ebay',
  menu_other_picture_analysis: 'Picture analysis',

  menu_language: 'Language',
  menu_upload_img: 'Upload image',

  menu_listing_creation_and_optimization: 'Listing Optimization',
  menu_me: 'Me',
  menu_my_applications: 'My applications',
  menu_next_step: 'Next step',
  menu_name: 'Name',
  menu_parameter: 'Parameter',
  menu_please_enter_a_description: 'Please enter a description',
  menu_please_enter_an_application_name: 'Please enter an application name',
  menu_please_enter_application_description: 'Please enter application description',
  menu_please_enter_name: 'Please enter name',
  menu_please_enter_the_creator_name: 'Please enter the creator name',
  menu_please_enter_the_prompt_word_content: 'Please enter the prompt word content',
  menu_please_fill_in_the_parameters_completely: 'P_ease fill in the parameters completely',
  menu_please_select_mode_of_payment: 'Please select mode of payment',
  menu_portuguese: 'Portuguese',
  menu_preview: 'Preview',
  menu_previous: 'Previous',
  menu_product_creation: 'B2B product creation',
  menu_product_description: 'Product description',
  menu_product_selection_and_development: 'Product Research',
  menu_prompt_word_content: 'Prompt word content',
  menu_prompt_word_content_cannot_be_empty: 'Prompt word content cannot be empty',
  menu_russian: 'Russian',
  menu_social_media: 'Social media',
  menu_spanish: 'Spanish',
  menu_store_operations: 'Store operations',
  menu_swedish: 'Swedish',
  menu_translate: 'Translate',
  menu_translation_results: 'Translation results',
  menu_update_application: 'Update application',
  menu_updated_successfully: 'Updated successfully',
  menu_usage_limit_reached: 'Usage limit reached',
  menu_use_immediately: 'Use immediately',
  menu_your_application_has_been_created_successfully_click_the_use_now_button_to_use_it_directly: 'Your application has been created successfully click the use now button to use it directly',
  menu_your_application_has_been_updated_successfully_click_the_use_now_button_to_use_it_directly: 'Your application has been updated successfully click the use now button to use it directly',
  menu_your_application_q_a_times_this_month_have_reached_the_limit_you_can_upgrade_your_package_to_continue_using_it: 'Your application q&a times this month have reached the limit you can upgrade your package to continue using it',
  myApplication_accept_an_invitation: 'Accept an invitation',
  myApplication_amazon_operations: 'Amazon operations',
  myApplication_are_you_sure_you_want_to_delete_this_conversation: 'Are you sure you want to delete this conversation?',
  myApplication_create_app: 'Create app',
  myApplication_delete_delete: 'Delete delete',
  myApplication_edit_application: 'Edit application',
  myApplication_expire_after: 'Expire after',
  myApplication_gives_inspiration_for_niche_products_in_ten_outdoor_categories_mainly_targeting_middle_and_high_income_groups_in_the_united_states_suitable_for_sle_on_amazon: 'Gives inspiration for niche products in ten outdoor categories, mainly targeting middle and high-income groups in the united states, suitable for sale on amazon',
  myApplication_go_to_use: 'Go to use',
  myApplication_hint: 'Hint',
  myApplication_history_record: 'History record',
  myApplication_independent_website_search_engine_optimization_seo: 'Independent website search engine optimization (seo)',
  myApplication_independent_websites_and_social_media: 'Independent websites and social media',
  myApplication_invite_you_to_join: 'Invite you to join',
  myApplication_join_in: 'Join in',
  myApplication_my_applications: 'My applications',
  myApplication_please_enter_custom_writing_instructions: 'Please enter custom writing instructions',
  myApplication_please_enter_your_name_in_the_team: 'Please enter your name in the team',
  myApplication_please_give_me_ten_english_brand_names_for_my_ebike_products_the_style_is_technological_and_futuristic: 'Please give me ten english brand names for my ebike products. the style is technological and futuristic.',
  myApplication_please_help_me_list_10_target_groups_for_ebike_products_and_describe_their_characteristics_including_age_gender_and_other_characteristics: 'Please help me list 10 target groups for ebike products and describe their characteristics, including age, gender and other characteristics.',
  myApplication_please_plan_facebook_posts_for_my_ebike_products_in_the_next_month_in_english: 'Please plan facebook posts for my ebike products in the next month, in english',
  myApplication_please_write_an_english_about_us_document_for_my_ebike_independent_station: 'Please write an english about us document for my ebike independent station',
  myApplication_please_write_an_seo_article_about_ebike_products_the_main_selling_points_of_the_product_are_environmental_protection_long_battery_life_and_portablity_the_output_language_is_english_800_words: 'Please write an seo article about ebike products. the main selling points of the product are environmental protection, long battery life and portability. the output language is english, 800 words.',
  myApplication_remark_name: 'Remark name',
  myApplication_team: 'Team',
  myApplication_the_current_invitation_will_be: 'The current invitation will be',
  myApplication_the_team_is_successful_the_mobile_phone_number_to_join_is: 'The team is successful, the mobile phone number to join is',
  myApplication_this_page_is_missing: 'This page is missing',
  myApplication_welcome_to_kerqu_ai_for_free_conversation: 'Welcome to kerqu.Ai for free conversation',
  myApplication_write_down_a_task_and_kua_ai_will_do_the_rest_for_you_not_sure_where_to_start_click_on_the_cross_border_cases_below_for_inspiration: 'Write down a task and kua.ai will do the rest for you. not sure where to start? click on the cross-border cases below for inspiration',
  no_application_record: 'No application data records',
  smartPic: 'Smart picture',
  talk: 'Communicate',
  test: 'Test',
  the_variable_in_the_prompt_content_cannot_be_empty: 'The variable in the prompt content cannot be empty',
  createCenter_upload_reference_image: 'Upload reference image',
  createCenter_specify_scene: 'Specify scene',
  createCenter_click_to_generate: 'Click to generate',
  createCenter_split_step_desc1: 'Upload the image you need for fission',
  createCenter_split_step_desc2: 'Enter the elements from the image you uploaded in the image description',
  createCenter_split_step_desc3: 'Fission completed, can be downloaded in bulk',
};
