import { useEffect, useImperativeHandle, useState } from "react";
import SettlementDetail from "@/pages/components/SettlementBoard/SettlementDetail.jsx";
import CardPay from "@/pages/components/SettlementBoard/CardPay.jsx";
import { useT } from "@/common/utils/translation";
import { Modal, Spin } from "antd";
import useStyle from "@/pages/components/SettlementBoard/hooks/useStyle.js";
import WechatPay, {
  openTime,
} from "@/pages/components/SettlementBoard/WechatPay.jsx";
// import AliPay from "@/pages/components/SettlementBoard/AliPay.jsx";

import { createPlayOrder } from "@/services/settlement";
import { useRequest } from "ahooks";
import useIsMobile from "@/common/hooks/useIsMobile.js";
import { Popup } from "antd-mobile";
import SettlementMobileDetail from "@/pages/components/SettlementBoard/SettlementMobileDetail.jsx";
import Error from "./Error";
import Success from "./Success";
import useVipPackageBtnName from "@/hooks/useVipPackageBtnName";
import BackIcon from "../BackIcon";
import { getPackageById, getVxAuth } from "@/services/vip";
import { checkIsMobile, isWeiXin } from "@/common/utils/tools";
import { ResultContent } from "./result";

function newWin(html) {
  const div = document.createElement("divform");
  div.innerHTML = html;
  document.body.appendChild(div);
  document.forms[0].submit();
}
export function numberType(type) {
  switch (type) {
    case "FREE":
      return 0;
    case "STANDARD":
      return 1;
    case "PROFESSIONAL":
      return 2;
    default:
      return -1;
  }
}

export const usePackageTitle = () => {
  let t = useT();
  return (type) => {
    if (type === "STANDARD") {
      return t("components_basic_version");
    } else if (type === "PROFESSIONAL") {
      return t("components_professional_version");
    } else {
      return t("components_free_version");
    }
  };
};

async function vxQuery() {
  // let ticket = await getSsapiTicket({ url: location.href });
  let vxRes = await getVxAuth({ returnUrl: location.href });
  alert(JSON.stringify(vxRes)+'22222');
  return vxRes;
}
function onBridgeReady({appId,timeStamp,nonceStr,packages,paySign}) {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId, //公众号ID，由商户传入
      timeStamp, //时间戳，自1970年以来的秒数
      nonceStr, //随机串
      package: packages,
      signType: "RSA", //微信签名方式：
      paySign, //微信签名
    },
    function (res) {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      }
    }
  );
}

const SettlementBoard = (props) => {
  let t = useT();

  let { vipPackageBtnName } = useVipPackageBtnName();
  const [type, setType] = useState("detail");
  const [payment, setPayment] = useState();
  const [wxUrl, setWxUrl] = useState();
  const [packageData, setPackageData] = useState();
  const [isMobile] = useIsMobile();
  const [orderId, setOrderId] = useState("");
  const { modalClassname } = useStyle({ type });
  const searchParams = new URLSearchParams(location.search);
  let openSetTime = openTime();

  let { loading: packageLoading, runAsync: packageRun } = useRequest(
    async (id, pay) => {
      let list = (await getPackageById(id, pay)).data;
      return list;
    },
    {
      manual: true,
      onSuccess: (res) => {
        setPackageData(res);
      },
    }
  );
  useEffect(() => {
    props.id && packageRun(props.id, payment);
  }, [props.id, payment]);
  let url = location.href;
  let { data: orderData, runAsync: orderRun } = useRequest(
    async () => {
      url.endsWith("/") && url.replace(/\/$/, "");
      return await createPlayOrder({
        preOrderId: searchParams.get("openid") || undefined,
        packageId: props.id,
        orderType:
          vipPackageBtnName[packageData.vipPackage.packageVersion].name ==
          t("components_vip_package_renewal")
            ? "CHARGE"
            : "UPGRADE",
        currency: payment == "WECHAT" || payment == "ALIPAY" ? "CNY" : "USD",
        payChannel: payment,
        returnUrl: url,
        cancelUrl: url,
        payType: checkIsMobile() ? "H5" : "PC",
        subject: "套餐支付",
        body: "套餐支付",
      });
    },
    { manual: true }
  );
  useImperativeHandle(props.settlementBoardRef, () => {
    return {
      open: () => {
        props.onCancel && props.onCancel(true);
      },
    };
  });
  const onPaymentChange = (e) => {
    setPayment(e.target.value);
  };

  let packageTitle = usePackageTitle();
  const toPay = () => {
    switch (payment) {
      // case "ALIPAY":
      //   setType("ali");
      //   break;
      case "WECHAT":
        setType("wechat");
        break;
      // case "STRIPE":
      //   setType("card");
      //   break;
    }
  };
  const onCancel = () => {
    setType("detail");
    setPayment(undefined);
    setWxUrl("");
    props.onCancel && props.onCancel(false);
  };
  const onPay = () => {
    if (payment) {
      if (isWeiXin()) {
        vxQuery().then((res) => {
          orderRun().then((res) => {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
              }
            } else {
              onBridgeReady();
            }
            alert(JSON.stringify(res),'33333');
          });
        });
      } else {
        orderRun().then((res) => {
          if (res.success) {
            if (!packageData?.payPrice) {
              setType("success");
            } else {
              if (!checkIsMobile()) {
                toPay();
              }

              if (checkIsMobile()) {
                setType("result");
                setOrderId(res.data.id);
              }
              if (payment == "ALIPAY" && res.data.payOrder.info.form) {
                newWin(res.data.payOrder.info.form);
              }
              if (payment == "PAYPAL" && res.data.payOrder.url) {
                location.href = res.data.payOrder.url;
              }
              if (payment == "STRIPE" && res.data.payOrder.url) {
                location.href = res.data.payOrder.url;
              }
              if (payment == "WECHAT") {
                if (res.data.payOrder.url) {
                  location.href = res.data.payOrder.url;
                } else {
                  setWxUrl(res.data.payOrder.info.code_url);
                }
              } else {
                // onCancel();
                // let url = new URL(location.href);
                // url.searchParams.set("payId", res.data.id);
                // navigate(url.pathname + url.search);
              }
            }
          }
        });
      }
    }
  };
  useEffect(() => {
    return () => {
      openSetTime.out();
    };
  }, []);
  const back = () => {
    if (type === "wechat") {
      setType("detail");
    } else if (type === "detail") {
      props.onBack?.();
    }
  };
  return (
    <>
      {isMobile ? (
        <Popup
          className={modalClassname}
          visible={props.visible}
          onMaskClick={() => {
            onCancel();
          }}
          bodyStyle={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            minHeight: "40vh",
          }}
        >
          {["wechat", "detail"].includes(type) ? (
            <BackIcon onClick={() => back()} />
          ) : null}
          <Spin tip={t("ai_updating") + "..."} spinning={packageLoading}>
            {type === "detail" ? (
              <div className="min-h-[400px] w-full">
                {packageData && (
                  <SettlementMobileDetail
                    vipInfo={packageData}
                    payment={payment}
                    onPaymentChange={onPaymentChange}
                    pay={onPay}
                  />
                )}
              </div>
            ) : null}
          </Spin>
          {type === "card" ? <CardPay /> : null}
          {type === "result" && (
            <ResultContent
              success={(title) => (
                <Success
                  title={title}
                  onBack={() => {
                    onCancel();
                    setType("detail");
                  }}
                />
              )}
              error={<Error onBack={() => onCancel()} />}
              id={orderId}
            ></ResultContent>
          )}
        </Popup>
      ) : (
        <Modal
          styles={{
            body: ["success", "error"].includes(type)
              ? {
                  minHeight: "484px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {},
          }}
          destroyOnClose={true}
          className={modalClassname}
          open={props.visible}
          onCancel={() => {
            onCancel();
          }}
          footer={null}
        >
          {["wechat", "detail"].includes(type) ? (
            <BackIcon onClick={() => back()} />
          ) : null}
          <Spin tip={t("ai_updating") + "..."} spinning={packageLoading}>
            {type === "detail" ? (
              <div className="min-h-[400px] w-full">
                {packageData && (
                  <SettlementDetail
                    userVip={props.userVip}
                    vipInfo={packageData}
                    payment={payment}
                    pay={onPay}
                    onPaymentChange={onPaymentChange}
                  />
                )}
              </div>
            ) : null}
            {type === "card" ? <CardPay /> : null}
            {type === "wechat" ? (
              <WechatPay
                setType={(type) => {
                  setType(type);
                }}
                vipInfo={packageData}
                orderData={orderData && orderData.data}
                url={wxUrl}
                onCancel={() => {
                  onCancel();
                }}
              />
            ) : null}
            {/* {type === "ali" ? <AliPay onCancel={() => onCancel()} /> : null} */}
            {type === "error" ? (
              <Error onBack={() => setType("detail")} />
            ) : null}
            {type === "success" ? (
              <Success
                title={packageTitle(
                  packageData && packageData.vipPackage.packageVersion
                )}
                onBack={() => {
                  onCancel();
                }}
              />
            ) : null}
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default SettlementBoard;
