class MenuChangeListener {
  listenerList = [];

  clearList(){
    this.listenerList = [];
  }

  onPageChangeBefore(callBack){
    const canJump = this.listenerList.some(fn => {
      return fn?.(callBack);
    });

    return canJump;
  }

  addPageChangeListener(callBackF){
    this.listenerList.push(callBackF);
  }

}

export default new MenuChangeListener();