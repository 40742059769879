import Obj from './locales/en/menus'
export function translate(type) {
  let obj = {};
  for (const key in Obj) {
    obj[key] = formatString(Obj[key]);
  }
  // list.forEach((k) => {
  //   let list = k.split("|");
  //   obj[
  //   "ai=" +
  //   list[0]
  //       .toLowerCase()
  //       .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => "_" + chr)
  //       ] = type ? list[1] : list[0];
  // });
  return obj;
}

const list = [
  "smart questions|聪明的提问",
  "Set up a role|设定角色",
  "Brief background|简述背景",
  "Set goals|定目标",
  "Supplementary requirements|补要求"

]



function formatString(value) {
  if (!value) {
    return
  }
  // 将字符串转换为小写
  const lowercaseValue = value.toLowerCase();
  // 获取首字母并转换为大写
  const firstLetter = lowercaseValue[0].toUpperCase();
  // 获取剩余字符并转换为小写
  const restOfString = lowercaseValue.slice(1);
  // 返回格式化后的字符串
  return firstLetter + restOfString;
}

const noReloadPath = ['/add-application']
export const checkNeedReload = (locationInfo) =>{
  if(noReloadPath.indexOf(locationInfo.pathname) !== -1 && locationInfo.search === ''){
    return  false;
  }
  return true
}
