import { useRootContext } from "@/common/RootContext";
import { Modal } from "antd";
import { createContext, useContext, useState } from "react";
import { LoginContent } from "@/pages/Login";
import { RegisterContent } from "@/pages/Register";
import { Modal as ModalMobile } from "antd-mobile";
import "./index.scss";

let LoginContext = createContext();

export function useLoginContext() {
  return useContext(LoginContext);
}

export function LoginModal() {
  let rootContext = useRootContext();
  let [type, setType] = useState("login");
  return (
    <LoginContext.Provider
      value={{
        dispatchSetType: (value) => {
          setType(value);
        },
      }}
    >
      <div className="max-lg:hidden">
        <Modal
          getContainer={false}
          wrapClassName="loginModal"
          // zIndex={200}
          width={962}
          open={rootContext.loginVisible}
          footer={null}
          onCancel={() => {
            rootContext.dispatchSetLoginVisible(false);
          }}
        >
          {type === "register" && (
            <div className="flex justify-center items-center">
              <div
                onClick={() => {
                }}
                className="relative"
              >
                <RegisterContent></RegisterContent>
                {/* <SvgIcon
              onClick={() => {
                console.log(222);
                rootContext.dispatchSetLoginVisible(false);
              }}
              className="absolute top-4 right-4 z-50 cursor-pointer"
              style={{ width: "16px", height: "16px" }}
              name={"del"}
            ></SvgIcon> */}
              </div>
            </div>
          )}
          {type === "login" && (
            <div className="flex justify-center items-center">
              <div
                onClick={() => {
                }}
                className="relative"
              >
                <LoginContent
                  setType={(value) => {
                    setType(value);
                  }}
                />
                {/* <SvgIcon
              onClick={() => {
                console.log(222);
                rootContext.dispatchSetLoginVisible(false);
              }}
              className="absolute top-4 right-4 z-50 cursor-pointer"
              style={{ width: "16px", height: "16px" }}
              name={"del"}
            ></SvgIcon> */}
              </div>
            </div>
          )}
        </Modal>
      </div>

      <div className="lg:hidden">
        <ModalMobile
          closeOnMaskClick={true}
          visible={rootContext.loginVisible}
          onClose={() => {
            rootContext.dispatchSetLoginVisible(false);
          }}
          content={
            <div className="">
              {type == "register" && (
                <div className="flex justify-center items-center">
                  <div
                    onClick={() => {
                    }}
                    className="relative"
                  >
                    <RegisterContent></RegisterContent>
                    {/* <SvgIcon
              onClick={() => {
                console.log(222);
                rootContext.dispatchSetLoginVisible(false);
              }}
              className="absolute top-4 right-4 z-50 cursor-pointer"
              style={{ width: "16px", height: "16px" }}
              name={"del"}
            ></SvgIcon> */}
                  </div>
                </div>
              )}
              {type == "login" && (
                <div className="flex justify-center items-center">
                  <div
                    onClick={() => {
                    }}
                    className="relative"
                  >
                    <LoginContent
                      setType={(value) => {
                        setType(value);
                      }}
                    ></LoginContent>
                    {/* <SvgIcon
              onClick={() => {
                console.log(222);
                rootContext.dispatchSetLoginVisible(false);
              }}
              className="absolute top-4 right-4 z-50 cursor-pointer"
              style={{ width: "16px", height: "16px" }}
              name={"del"}
            ></SvgIcon> */}
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>
    </LoginContext.Provider>
  );
}
