// eslint-disable-next-line no-unused-vars
import React, {lazy} from 'react';
import {createBrowserRouter} from 'react-router-dom';

import NotFound from '../pages/NotFound/index.jsx';
import channelMenus from '@/router/channelMenu.js';
import HomeProxy from '@/pages/HomeProxy';
import Image from '@/pages/Image';
import Register from '@/pages/Register';
import Login from '@/pages/Login';
import CustomerApplication from "@/pages/CustomerApplication";
import HomeApplication from "@/pages/HomeApplication";

// const ImageMatting = lazy(
//     () => import('../pages/Image/ImageMatting/index.jsx'));
// const HomeComponent = lazy(() => import('../pages/home/index.jsx'));
// const ImageAIComponent = lazy(() => import('../pages/Image/AI/index.jsx'));
// const ImageSplitComponent = lazy(
//     () => import('../pages/Image/Split/index.jsx'));
// const Talk = lazy(() => import('../pages/Talk/index.jsx'));
// const SmartPic = lazy(() => import('../pages/SmartPic/index.jsx'));
const OrderCenter = lazy(() => import('../pages/Order/List/index.jsx'));
// const MyApplication = lazy(() => import('../pages/MyApplication/index.jsx'));
const AddApplication = lazy(() => import('../pages/AddApplication/index.jsx'));
// const CreateCenter = lazy(() => import('../pages/CreateCenter/index.jsx'));
const ApplicationDetail = lazy(
    () => import('@/pages/ApplicationDetail/index.jsx'));
const ChannelApplicationManage = lazy(
    () => import('@/pages/ChannelApplicationManage/index.jsx'));
// const SmartBackground = lazy(() => import('@/pages/Image/SmartBackground'));
const InviteTeam = lazy(() => import('@/pages/Invite/Team'));
const Salesman = lazy(() => import('@/pages/Marketing/Salesman'));
const RedemptionCode = lazy(() => import('@/pages/Marketing/RedemptionCode'));
const CustomerList = lazy(() => import('@/pages/Customer/List'));
const CustomerDetail = lazy(() => import('@/pages/Customer/Detail'));
const SourceList = lazy(() => import('@/pages/Customer/Sources.jsx'));
const InviteList = lazy(() => import('@/pages/Customer/invite/List.jsx'));
const InviteDetail = lazy(() => import('@/pages/Customer/invite/Detail'));
const EnterpriseList = lazy(() => import('@/pages/Enterprise/List'));
const EnterpriseForm = lazy(() => import('@/pages/Enterprise/Form.jsx'));
// const Login = lazy(() => import('@/pages/Login'))
// const Register = lazy(() => import('@/pages/Register'))


const Dashboard = lazy(() => import('@/pages/Dashboard'))

export const routerList = [
  // {
  //   path: '/image',
  //   element: <Image/>,
  //   children: [
  //     {
  //       path: '/image/ai',
  //       extra: true,
  //       Component: ImageAIComponent,
  //     },
  //     {
  //       path: '/image/split',
  //       extra: true,
  //       Component: ImageSplitComponent,
  //     },
  //     {
  //       path: '/image/matting',
  //       extra: true,
  //       Component: ImageMatting,
  //     },
  //     {
  //       path: '/image/background',
  //       extra: true,
  //       Component: SmartBackground,
  //     },
  //   ],
  // },
  {
    path: '/login',
    element: <Login/>,
  },
  {
    path: '/reg',
    element: <Register/>,
  },
  {
    path: '/invite',
    element: <Image/>,
    children: [
      {
        path: '/invite/team',
        extra: true,
        Component: InviteTeam,
      },
    ],
  },
  {
    path: '/',
    element: <HomeProxy />,
    children: [
      {
        path: '',
        extra: true,
        Component: Dashboard,
      },
      {
        path: '/home',
        extra: true,
        Component: Dashboard,
      },
      {
        path: '/channel-application',
        extra: true,
        Component: ChannelApplicationManage,
      },
      {
        path: '/home-application',
        extra: true,
        Component: HomeApplication,
      },
      {
        path: '/customer-application',
        extra: true,
        Component: CustomerApplication,
      },
      {
        path: '/customer/list',
        extra: true,
        // Component: MyApplication,
        Component: CustomerList,
      },
      {
        path: '/customer/:id/detail',
        extra: true,
        key: '2222',
        menuKey: '/customer/list',
        Component: CustomerDetail,
      },
      {
        path: '/customer/invite',
        extra: true,
        Component: InviteList,
      },
      {
        path: '/customer/:id/invite-detail',
        extra: true,
        key: '111',
        menuKey: '/customer/invite',
        Component: InviteDetail,
      },
      {
        path: '/customer/sources',
        extra: true,
        Component: SourceList,
      },
      {
        path:  '/order-center',
        extra: true,
        Component: OrderCenter,
      },
      {
        path: '/marketing/salesman',
        extra: true,
        // Component: CreateCenter,
        Component: Salesman,
      },
      {
        path: '/marketing/redemption-code',
        extra: true,
        // Component: CreateCenter,
        Component: RedemptionCode,
      },
      {
        path: '/add-channel-application',
        extra: true,
        Component: AddApplication,
      },
      // {
      //   path: '/listing-create',
      //   extra: true,
      //   Component: ListingCreate,
      // },
      {
        path: '/application-detail',
        extra: true,
        Component: ApplicationDetail,
      },
      {
        path: '/view-application',
        extra: true,
        Component: AddApplication,
      },
      // ...channelMenus,
      {
        path: '/enterprise/list',
        extra: true,
        Component: EnterpriseList
      },
      {
        path: '/enterprise/form/create',
        extra: true,
        Component: EnterpriseForm
      },
      {
        path: '/enterprise/form/:id/update',
        extra: true,
        Component: EnterpriseForm
      },
    ],
  },
  {
    path: '*',
    element: <NotFound/>, // 404页面组件
  },
];
const routes = createBrowserRouter(routerList);
console.log('routes', routes)

export default routes;