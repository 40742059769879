import { createContext, useState, useEffect, useMemo } from "react";
import { getMyTeamList } from "@/services/teamManange.js";

export const TeamInfoContext = createContext({
    vipModalVisible: true,
    dispatchSetVipModalVisible: () => { }
})


const TeamInfoContextProvider = ({ children }) => {
    const [roleList, setRoleList] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false)

    useEffect(() => {
        fetchMyTeamList()
    }, []);



    const fetchMyTeamList = () => {
        getMyTeamList().then((res) => {
            setRoleList(res?.data || []);
        });
    }


    const setPopoverVisibleAction = (state) => {
        if(state !== undefined) {
            setPopoverVisible(state)
        } else {
            setPopoverVisible(!popoverVisible)
        }
       
    }

    const activedRole = useMemo(() => {
        return roleList?.find(role => role.isActive) || {}
    }, [roleList])

    return <TeamInfoContext.Provider
        value={{
            roleList,
            activedRole,
            popoverVisible,
            dispatchGetMyTeamList: fetchMyTeamList,
            dispatchSetPopoverVisible: setPopoverVisibleAction
        }}

    >   {children}</TeamInfoContext.Provider>
}

export default TeamInfoContextProvider