import request from '@/services/index';
import LoginRequest from '@/services/loginRequest';

// 登录初始化
export const initializeInfo = () => {
  return request.post(`/api/group/initialize`);
};

// 登录
export const login = (data) => {
  return LoginRequest.post(`/sso/admin/login`,data);
};

// 注册
export const reg = (data) => {
  return LoginRequest.post(`/sso/admin/register`, data);
};

// 获取用户 信息
export const getUserInfo = () => {
  return LoginRequest.get(`/sso/admin/info`);
};

// 退出登录
export const outLogin = () => {
  return LoginRequest.post(`/sso/admin/logout`);
};

/**
 * 发送验证码
 * @return {Promise<*|undefined>}
 * @param data { phone }
 */
export const sendCode = (data) => {
  return LoginRequest.post(`/sso/admin/sendCode`, data);
};

/**
 * 更新密码
 * @param data { code, password }
 * @return {Promise<any|undefined>}
 */
export const updatePassword = (data) => {
  return LoginRequest.put(`/sso/admin/password/reset`, data);
};

/**
 * 获取 群 二维码
 * */
export const getGroupQr = () => {
  return request.get(`/api/share/qun`);
};

/**
 * 获取 客户 二维码
 * */
export const getClientQr = () => {
  return request.get(`/api/share/kefu`);
};