import {useTranslation} from "react-i18next";

export function useT(){
  let {t} = useTranslation()
  return (id, ...args) => {
    let text = t(id)
    if (!args || args.length === 0) {
      return text
    }
    for (let i = 0; i < args.length; i++) {
      const arg = args[i]
      text = text.replaceAll('$' + (i + 1), arg)
    }
    return text
  }
}