/**
 * localstorage 与 sessionStorage的 封装
 * */
const stores = {
    saveSessionStorage(key, value) {
        const mySessionStorage = window.sessionStorage;
        mySessionStorage.setItem(key, JSON.stringify(value));
    },
    getSessionStorage(key) {
        const mySessionStorage = window.sessionStorage;
        return JSON.parse(mySessionStorage.getItem(key));
    },
    removeSessionStorage(key) {
        const mySessionStorage = window.sessionStorage;
        mySessionStorage.removeItem(key);
    },

    saveLocalStorage(key, value){
        const myLocalStorage = window.localStorage;
        myLocalStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
        const myLocalStorage = window.localStorage;
        try {
            return JSON.parse(myLocalStorage.getItem(key))
        } catch (err){
            return key;
        }
    },
    removeLocalStorage(key) {
        const myLocalStorage = window.localStorage;
        myLocalStorage.removeItem(key);
    },
}

export default stores;