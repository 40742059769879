import {useEmotionCss} from '@ant-design/use-emotion-css';

const useStyle = () => {
  const modalClassname = useEmotionCss(() => {
    return {
      marginTop: '1.5rem',
      '.title': {
        fontSize: '0.88rem',
        // fontWeight: 500,
        color: 'rgba(0,0,0,0.65)',
        marginBottom: '1rem',
      },
      '.link': {
        marginTop: '0.5rem',
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.65)',
        '.link-day': {color: 'rgba(22, 119, 255, 1)'},
      },
    };
  });

  return {modalClassname};
};

export default useStyle;