import HeaderTitle from "@/pages/components/HeaderTitle";
import React, {useRef, useState} from "react";
import styles from './index.module.scss';
import {Tabs, Space, Tag, Input, Pagination, Empty} from "antd";
import typeMenu from "@/pages/AddApplication/ChoiceType/const";
import {SearchOutlined} from "@ant-design/icons";
import ApplicationCard from "@/pages/MyApplication/ApplicationCard/index.jsx";
import {useNavigate} from "react-router-dom";
import ApplicationTypeFilter from "@/pages/components/ApplicationTypeFilter/index.jsx";
import {getApplicationList, getCustomAppList} from "@/services/application.js";
import LoadingComponent from "@/pages/components/LoadingComponent/index.jsx";


function ApplicatinList() {
  const navigate = useNavigate();
  const filterInfo = useRef();
  const pageInfo = useRef({
    pageSize: 20,
    pageNum: 1,
  })
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [cardList, setCardList] = useState([
    {
      icon: 'amaApp',
      name: "穿在身上大",
      description: "啊是打算打算啊实打实大",
      createBy: 'wuyun',
      id: 'xxxxx'
    },
  ]);


  const getAppList = async (info) => {

    setLoading(true);
    setCardList([]);

    const res = await getApplicationList({
      type: info.key,
      typeSubItem: info.childKey,
      name: info.value,
      createBy: 'CUSTOMER'
    });

    if (res.success) {
      setCardList(res.data.map(item => {
        return {
          ...item,
          type: {
            type: item.type
          }
        }
      }));
    }
    setLoading(false);

  }

  return (
    <div className={styles.container}>
      <HeaderTitle title={'客户应用'}/>

      <div className={styles.content}>
        <div className={styles.filter}>
          <ApplicationTypeFilter
            onChange={(info) => {
              // filterInfo.current = item;
              getAppList(info);
            }}
          />
        </div>

        <div className={styles.cardContent}>

          {
            loading && (
              <LoadingComponent/>
            )
          }

          {
            !loading && cardList.length === 0 ? (
              <div>
                <Empty description={'暂无数据'}/>
              </div>
            ) : (
              <Space wrap={true}>
                {
                  cardList.map(item => {
                    return (
                      <ApplicationCard
                        cardInfo={item}
                        onClick={() => {
                          navigate({
                            pathname: '/view-application',
                            search: `id=${item.appId}&groupId=${item.groupId}`
                          })
                        }}
                      />
                    )
                  })
                }
              </Space>
            )
          }

        </div>

      </div>

      <div className={styles.pageLine}>
        {/*<Pagination*/}
        {/*  current={pageInfo.current.pageNum}*/}
        {/*  total={total}*/}
        {/*  onChange={(page, pageSize) => {*/}
        {/*    pageInfo.current.pageNum = page;*/}
        {/*    getAppList();*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
    </div>
  )
}

export default ApplicatinList;