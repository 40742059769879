import {useT} from '@/common/utils/translation';
import {Button, ConfigProvider, Result} from 'antd';

const Success = (props) => {
  let t = useT();
  return (
      <ConfigProvider theme={{
        components: {
          Result: {
            iconFontSize: 64,
            titleFontSize: 16,
          },
        },
      }}>
        <Result
            status="success"
            title={t('components_payment_successful')}
            subTitle={t(
                    'components_you_have_successfully_purchased_the_vip_package_professional_version') +
                props.title}
            extra={[
              <Button key="buy" style={{width: '200px'}}
                      onClick={() => props.onBack()}>{t(
                  'components_i_see')}</Button>,
            ]}
        >
        </Result>
      </ConfigProvider>
  );
};

export default Success;