/**
 * 这里主要 放置 公共 方法
 * */
import { isString, startsWith } from 'lodash';
import {getProof, uploadFileToOSS} from "@/services/upload.js";
import {getOssRedirectUrl, isOssKey} from "@/lib/utils.js";

// 处置文本到剪贴板
export function copyToClip(content, tips) {
  return new Promise((resolve, reject) => {
    console.log('clipboard', navigator.clipboard.writeText)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    } else {
      const textArea = document.createElement('TextArea');
      document.body.appendChild(textArea);
      textArea.innerText = content;
      textArea.select();
      document.execCommand("copy");
      resolve();

      setTimeout(() => {
        document.body.removeChild(textArea);
      });
      resolve();
    }
  })
}

/** 获取url参数 */
export const urlToToObj = (url) => {
  const obj = {};
  if (url) {
    const params = url.split('?')[1].split('&');
    params.map((item) => (obj[item.split('=')[0]] = decodeURIComponent(item.split('=')[1])));
  }
  return obj;
};

/*对象转url参数*/
export const splitQueryParams = (params) => {
  const tempParams = [];
  if (Object.keys(params).length <= 0) return tempParams;
  for (let key in params) {
    tempParams.push(`${key}=${params[key]}`);
  }

  return tempParams.join('&');
};


// 转blob
export const convertRes2Blob = (data, filename = '下载内容') => {
  const blob = new Blob([data], { type: 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, decodeURI(filename));
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', decodeURI(filename));
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}


/**
 * @_js在树形对象数组结构中查找对象
 */
export const breadthQuery = (tree, name, id) => {
  let stark = [];
  stark = stark.concat(tree);
  while (stark.length) {
    const temp = stark.shift();
    if (temp.children) {
      stark = stark.concat(temp.children);
    }
    if (temp[name] === id) {
      return temp;
    }
  }
}

/**
 * 相同name组成新数组
 * @param arr
 * @param title
 * @returns
 */
export const treeBoth = (arr, title) => {
  const tempArr = [];
  const afterData = [];
  for (let k = 0; k < arr.length; k++) {
    if (tempArr.indexOf(arr[k][title]) === -1) {
      afterData.push({
        [title]: arr[k][title],
        content: [arr[k]],
      });
      tempArr.push(arr[k][title]);
    } else {
      for (let j = 0; j < afterData.length; j++) {
        if (afterData[j][title] == arr[k][title]) {
          afterData[j].content.push(arr[k]);
          break;
        }
      }
    }
  }
  return afterData;
};

/**
 * @对象数组去重
 */
export const objectArrayDeduplication = (arr, uniId) => {
  const res = new Map();
  return arr.filter(
    (item) => !res.has(item[uniId]) && res.set(item[uniId], 1),
  );
};

/**
 * 去除对象空值
 * @param obj
 * @returns
 */
export const removeProperty = (obj) => {
  Object.keys(obj).forEach((item) => {
    if (obj[item] === '' || obj[item] === undefined || obj[item] === null || obj[item] === 'null')
      delete obj[item];
  });
  return obj;
};

export const MobileBreakPoint = 768

/**
 * 检查是不是移动端
 * */
export const checkIsMobile = () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const screenWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;

  return false;
  // return isMobile && screenWidth < MobileBreakPoint;
}

// 去掉重复数据
export const removeRepeatData = (list, getKey) => {
  const tempKey = [];
  const tempList = [];

  list.forEach(item => {
    const key = getKey(item);
    if (!tempKey.includes(key)) {
      tempKey.push(key);
      tempList.push(item);
    }
  });

  return tempList;
}

// phone 脱敏
export const textHyposensitization = (str, start, end) => {

  if (!str || str.toString().length === 0) {
    return '';
  }

  const len = end - start;
  const replaceText = "*".repeat(len);

  const startStr = str.substring(0, start);
  const endStr = str.substring(end);

  return `${startStr}${replaceText}${endStr}`;

}


export const refreshRem = () => {
  // const screenWidth = window.innerWidth;
  const screenWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
  const finalSize = screenWidth / 375 * 16;
  return finalSize;
}

/**
 * 获取url的pathname
 * @param urls Array
 * @return string
 */
export const getPathname = (urls) => {
  const removeFirst = (str) => {
    if (startsWith(str, '/')) {
      return str.substring(1, str.length)
    }
    return ''
  }
  if (Array.isArray(urls)) {
    return urls.map((url) => {
      if (isString(url)) {
        const str = new URL(url).pathname
        return removeFirst(str)
      }
      return ''
    })
  }
  const str = new URL(urls).pathname
  return removeFirst(str)
}

// 倒计时函数 参数接受多少分钟
export function minuteCountdown(timeString,fn,out) {
  let time = null
  function startCountdown(){
    let totalSeconds = timeString * 60;
    
    return setInterval(() => {
      if (totalSeconds <= 0) {
        clearInterval(time);
        out()
        return;
      }
      const displayMinutes = Math.floor(totalSeconds / 60);
      const displaySeconds = totalSeconds % 60;
      fn(`${displayMinutes}:${displaySeconds < 10 ? '0' : ''}${displaySeconds}`)
      console.log(`${displayMinutes}:${displaySeconds < 10 ? '0' : ''}${displaySeconds}`);

      totalSeconds--;
    }, 1000);
  }
  return {
    go:()=>{
      if (time) {
        clearInterval(time)
        time = startCountdown()
      }else{
        time = startCountdown()
      }
    },
    out:()=>{
      if(time){
        clearInterval(time)
      }
    }
  }
}

// 检查是不是微信生态
export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

export const OssFileType = {
  TEMP: 'TEMP',
  IMAGE: 'IMAGE',
  GROUP: 'GROUP',
  DOCUMENT: 'DOCUMENT',
  BATCH: 'BATCH',
}

export async function uploadFIle(file, fileType) {
  const proof = await getProof({fileType: fileType ? fileType : OssFileType.DOCUMENT})
  const [ossRes] = await uploadFileToOSS([file], proof)
  const url = ossRes.url;
  const src = isOssKey(url) ? getOssRedirectUrl(url) : url;

  return {url, src}
}
