import React  from 'react'
import ReactDOM from 'react-dom/client'
import './index.css';
import "virtual:svg-icons-register";
import App from "@/App";
import stores from "@/common/utils/stores.js";

window.global = window;

const language =  stores.getLocalStorage("languageType");
if(!language){
  stores.saveLocalStorage("languageType", 'zh');
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
