import RootContextProvider from '@/common/RootContext';
import {I18nextProvider} from 'react-i18next';
import i18n from '@/i18next';
import VipContextProvider from '@/context/vip';
import {LoginModal} from '@/pages/components/LoginModal/index';
import {RouterProvider} from 'react-router-dom';
import routes from '@/router';
import React, {useEffect} from 'react';
import useIsMobile from '@/common/hooks/useIsMobile';
import {translate} from '@/const/tool.js';
import {checkIsMobile, refreshRem} from '@/common/utils/tools';
import {useMount, useUnmount} from 'ahooks';
import {setBroswerFingerprint} from '@/lib/utils.js';
import {ConfigProvider} from 'antd';
import {PrimaryColor} from '@/const/index.jsx';

export function ThemeContainer({children}){
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token，影响范围大
          colorPrimary: PrimaryColor,
          colorLink: PrimaryColor,
          colorInfo: PrimaryColor,
          // borderRadius: 2,

          // 派生变量，影响范围小
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}

function App() {
  const [isMobile] = useIsMobile();

  useEffect(() => {
    setRootFontSize();
  }, [isMobile]);

  // 用于获取当前浏览的的指纹标识
  useEffect(() => {
    setBroswerFingerprint();
  }, []);

  useMount(() => {
    setRootFontSize();

    window.addEventListener('resize', setRootFontSize);
    if ('orientationchange' in window) {
      window.addEventListener('orientationchange', setRootFontSize);
    }
  });

  useUnmount(() => {
    window.removeEventListener('resize', setRootFontSize);
    if ('orientationchange' in window) {
      window.removeEventListener('orientationchange', setRootFontSize);
    }
  });

  const setRootFontSize = () => {
    const html = document.querySelector('html');
    if (checkIsMobile()) {
      const fontSize = refreshRem();
      html.style.fontSize = fontSize + 'px';
    } else {
      html.style.fontSize = '';
    }
  };

  return (
      <ThemeContainer>
        <RootContextProvider>
          <I18nextProvider i18n={i18n}>
            <VipContextProvider>
              <RouterProvider router={routes}/>
              <LoginModal></LoginModal>
            </VipContextProvider>
          </I18nextProvider>
        </RootContextProvider>
      </ThemeContainer>
  );
}

export default App;
