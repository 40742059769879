import {createContext, useContext, useState} from "react";
import {useMount} from "ahooks";
import EventEmitter from 'events'
import {getUserInfo} from "@/services/common";
import {Spin, message} from 'antd';
import useIsMobile from "@/common/hooks/useIsMobile";
import {myCookie} from "./utils/cookie";
import stores from "@/common/utils/stores.js";

export let allLoginVisible =null

export const RootContext = createContext({});

export function useRootContext() {
  return useContext(RootContext)
}

function RootContextProvider({children}) {
  const [loginStatus, setLoginStatus] = useState(false);
  const [init, setInit] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isMobile] = useIsMobile();
  const [userCurrentPlan, setUserCurrentPlan] = useState();
  const [loginVisible, setLoginVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('zh');
  const [eventEmitter, setEventEmitter] = useState();
  const [viewVersion, setViewVersion] = useState('cn'); // cn 国内版，com 国际版

  // const {data: aiImageCenterListRes} = useRequest(getAIImageCenterList, {});

  allLoginVisible=()=>{
    setLoginVisible(true)
    setLoginStatus(false)
    myCookie.delete('kerqu-admin-token')
    myCookie.delete('groupId')
  }

  useMount(() => {
    setViewVersion(import.meta.env.VITE_APP_ENV);

    onInit();
    setEventEmitter(new EventEmitter())
    const  language= stores.getLocalStorage("languageType");
    setCurrentLanguage(language || 'zh');

  });

  // useEffect(() => {
  //   if(loginStatus){
  //     onInit();
  //   }
  // }, [loginStatus]);

  const onInit = async () => {
    // 先 移除 groupId
    sessionStorage.removeItem('groupId');
    sessionStorage.removeItem('kerqu-group');

    if (myCookie.get('kerqu-admin-token')) {
      setLoginStatus(true);

      getUserInfo().then((res) => {
        console.log('res', res)
        if (res.success) {
          setUserInfo(res.data)
        } else {
          message.error(res.message);
        }
      })

      // 获取 groupId 数据
      // let init = await initializeInfo()
      // if (init.success) {
      //   // 添加到 sessionStorage， 接口请求的时候 携带到 header 上面
      //   sessionStorage.setItem('groupId', init.data.groupId);
      //   myCookie.set("groupId", init.data.groupId);
      //   myCookie.set("kerqu-group", init.data.groupId);
      //
      // } else {
      //   message.error(init.message);
      // }

    }

    // 临时 写死
    // sessionStorage.setItem('groupId', '1722464545387188224');
    // setLoginStatus(true);

    setInit(true)

  }

  return (
    <RootContext.Provider value={{
      eventEmitter,
      loginStatus,
      userInfo,
      isMobile,
      userCurrentPlan,
      loginVisible,
      currentLanguage,
      setCurrentLanguage,
      viewVersion,
      // dispatchViewVersion: (type) => {
      //   setViewVersion(type);
      // },
      aiImageCenterList: [],
      dispatchSetUserInfo: (data) => {
        setUserInfo(data)
      },
      dispatchSetLoginVisible: (value) => {
        setLoginVisible(value)
      },
      dispatchSetLoginStatus: (value) => {
        setLoginStatus(value)
      },
      dispatchSetUserCurrentPlan: (value) => {
        setUserCurrentPlan(value)
      }
    }}>
      {
        init ? children : (
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center'
          }}>
            <Spin tip="初始化中" size="large">
              <div style={{width: '200px'}}/>
            </Spin>
          </div>
        )
      }
    </RootContext.Provider>
  )
}

export default RootContextProvider;