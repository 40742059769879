import request from '@/services/index';

// 我的团队列表
export const getMyTeamList = () => {
  return request.get(`/api/group/myGroups`);
};

// 获取团队
export const getGroupByGroupId = (groupId) => {
  return request.get(`/api/group/${groupId}`);
};

// 编辑团队
export const getEditGroup = (props) => {
  return request.post(`/api/group/edit`, props);
};

// 登录初始化
export const getGroupInitialize = () => {
  return request.post(`/api/group/initialize`);
};

// 切换团队
export const getSwicthGroup = (groupId) => {
  return request.post(`/api/group/switch/${groupId}`);
};

// 创建团队邀请
export const getCreateGroupInvite = (params) => {
  return request.get(`/api/group/invite`, params);
};

// 查询团队邀请
export const getQueryInviteByCode = (params) => {
  return request.get(`/api/group/queryInviteCode`, params);
};

/**
 *
 * @param data { inviteCode: string }
 * @return {Promise<axios.AxiosResponse<any>|any|undefined>}
 */
export const joinGroup = (data) => {
  return request.post(`/api/group/join`, data);
};

// 团队成员列表
export const getGroupMemberList = () => {
  return request.get(`/api/member/list`);
};

// 删除团队成员
export const deleteGroupMemberList = (groupId, userId) => {
  return request.delete(`/api/member/delete/${groupId}/${userId}`);
};

// 编辑团队成员
export const editGroupMember = (data) => {
  return request.post(`/api/member/edit`, data);
};
   
    
    