import {Button, ConfigProvider, message, Modal, Select, Space} from 'antd';
import {useImperativeHandle, useState} from 'react';
import {useRequest} from 'ahooks';
import {getCreateGroupInvite} from '@/services/teamManange.js';
import copy from 'copy-to-clipboard';
import useStyle from '@/pages/components/TeamInviteModal/hooks/useStyle.js';
import {useT} from '@/common/utils/translation';
import useIsMobile from '@/common/hooks/useIsMobile.js';

const TeamInviteModal = (props) => {
  let t = useT();
  const [visible, setVisible] = useState(false);
  const [invite, setInvite] = useState('USER');
  const [isMobile] = useIsMobile();
  const [messageApi, contextHolder] = message.useMessage();

  const handleSetVisible = () => {
    setVisible(!visible);
  };

  useImperativeHandle(props.inviteMemberRef, () => {
    return {
      open: () => {
        handleSetVisible();
      },
      close: () => {
        handleSetVisible();
      },
    };
  });

  const options = [
    {
      label: <span>{t(
          'components_after_inviting_external_people_to_join_the_team_it_becomes')}
        <span style={{color: 'rgba(22, 119, 255, 1)'}}>团队成员</span></span>,
      value: 'USER',
    }, {
      label: <span>{t(
          'components_after_inviting_external_people_to_join_the_team_it_becomes')}
        <span style={{color: 'rgba(22, 119, 255, 1)'}}>管理者</span></span>,
      value: 'ADMIN',
    }];

  const {data, loading} = useRequest(() => getCreateGroupInvite({
    role: invite,
    redirect: `${window.location.origin}/invite/team`,
  }), {
    refreshDeps: [invite],
    ready: visible,
  });

  const copyUrl = () => {
    if (data.data.redirectUrl) {
      copy(data.data.redirectUrl, {
        message: t(
            'components_the_copy_link_message'),
      });
      messageApi.open({
        type: 'success',
        content: t(
            'components_the_link_is_copied_successfully_and_will_expire_in_14_days'),
      });
    }
  };

  const {modalClassname} = useStyle();

  return (
      <>
        <ConfigProvider theme={{components: {Message: {zIndexPopup: 10000}}}}>
          {contextHolder}
        </ConfigProvider>
        {props.submitter ? <div onClick={() => {
          setVisible(true);
          props.onOpen?.();
        }}>{props.submitter}</div> : null}
        <Modal
            centered
            title={t('components_invite_members')}
            open={visible}
            width="32.5rem"
            onCancel={handleSetVisible}
            styles={{content: {padding: '20px 24px 32px 24px'}}}
            footer={null}
        >
          <div className={modalClassname}>
            <div className="title">{t('components_invite_via_link')}</div>
            <div>
              {isMobile ? <div>
                <Select
                    value={invite}
                    onChange={(e) => {
                      setInvite(e);
                    }}
                    style={{width: '100%', marginBottom: '0.63rem'}}
                    options={options}
                />
                <Button
                    type="primary"
                    loading={loading}
                    onClick={() => { copyUrl(); }}
                >{t(
                    'components_copy_link')}
                </Button>
              </div> : <Space.Compact block>
                <Select value={invite}
                        onChange={(e) => {
                          setInvite(e);
                        }}
                        style={{width: '80%'}}
                        options={options}></Select>
                <Button
                    loading={loading}
                    type="primary"
                    onClick={() => { copyUrl(); }}>
                  {t(
                      'components_copy_link')}
                </Button>
              </Space.Compact>}
            </div>
            <div className="link">{t('components_link_validity_period')}<span
                className="link-day">14天</span></div>
          </div>
        </Modal>
      </>
  );
};

export default TeamInviteModal;