import useIsMobile from "@/common/hooks/useIsMobile";

function IsMobileComponentProxy({pcContent, h5Content}) {
  const [isMobile] = useIsMobile();
  return (
    <>
      {
        isMobile ? h5Content : pcContent
      }
    </>
  )
}

export default IsMobileComponentProxy;