import { useState } from "react";
import { Button, Form, message } from "antd";

import { useT } from "@/common/utils/translation";
import { sendCode } from "@/services/common";

const CodeBtn = function ({ fieldName , type, text }) {
  let t = useT();
  if(!fieldName){
    fieldName="phone";
  }
  if(!type){
    type = "text";
  }
  if(!text){
    text=t("login_send_the_verification_code");
  }
  const [messageApi, contextHolder] = message.useMessage();
  let form = Form.useFormInstance();
  const phone = Form.useWatch(fieldName, form);
  const onSend = function () {
    if (phone && form.getFieldError(fieldName).length === 0) {
      setClickble(false);
      return sendCode({phone:form.getFieldValue(fieldName)}).then((result) => {
        if (result.success) {
          messageApi.open({
            type: "success",
            content: t(
              "components_the_verification_code_has_been_sent_please_check_it_carefully_please_do_not_trigger_it_repeatedly_within_1_minute"
            ),
          });
        } else {
          messageApi.open({
            type: "error",
            content: result.message,
          });
        }
      });
    }
  };
  let [count, setCount] = useState(60);
  let [clickble, setClickble] = useState(true);
  let btn;
  if (count > 0 && clickble == false) {
    setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    btn = (
      <Button disabled={true} type="text" className='p-0 lg:w-[100px]'>
        {count}S
      </Button>
    );
  } else {
    if (count === 0) {
      setClickble(true);
      setCount(60);
    }

    btn = (
      <Button type={type} onClick={onSend} className='p-0 lg:w-[100px]'>
        {text}
      </Button>
    );
  }

  return (
    <>
      {contextHolder}
      {btn}
    </>
  );
};
export default CodeBtn;
