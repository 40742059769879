import {
    
    Modal,
    Form,
    Input,
    message,
    Button,
    Switch,
    Row,
    Alert,
    Upload,
} from "antd";
import styles from "./index.module.scss";
import { useEffect, useMemo, useState, useContext } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
    getGroupByGroupId,
    getEditGroup,
} from "@/services/teamManange.js";
import { useT } from "@/common/utils/translation";
import OssAvatar from "@/pages/components/OssAvatar/index.jsx"
import { TeamInfoContext } from "./context"
import { getProof, OssFileType, uploadFileToOSS } from "@/services/upload.js";

const AcceptFileTypes = ['image/jpeg', 'image/png', 'image/webp']
const MaxFileSize = 1024 * 1024 * 10

function TeamSettingModal({ open, closeModal, }) {
    let t = useT();
    const [gruopInfo, setGroupInfo] = useState({});
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const { activedRole, dispatchGetMyTeamList } = useContext(TeamInfoContext)
    const [avatarUrl, setAvatarUrl] = useState("")
    const [uploadAvatarLoading, setUploadAvatarLoading ] = useState(false)
    useEffect(() => {
        getGroupByGroupId(activedRole.id).then((res) => {
            setGroupInfo(res?.data);
            form.setFieldsValue(res?.data);
            setAvatarUrl(res?.data?.avatarUrl)
        });
    }, [activedRole, form]);


    const handleOk = () => {
        closeModal(!open);
    };

    /** 团队成员不能进行编辑操作 */
    const isDisableEdit = useMemo(() => {
        return activedRole.role === "USER"
    }, [activedRole])



    const beforeUpload = async (file) => {
        if (!AcceptFileTypes.includes(file.type)) {
            message.error(t('createCenter_only_supports_jpg_png_webp_type_image_files'))
            return
          }
          if (file.size > MaxFileSize) {
            message.error(t('createCenter_pictures_cannot_exceed_10m'))
            return
          }
        setUploadAvatarLoading(true)
        const proof = await getProof({ fileType: OssFileType.GROUP })
        const [res] = await uploadFileToOSS([file], proof)
        setUploadAvatarLoading(false)
        setAvatarUrl(res?.relativeUrl)
    };

    const onFinish = () => {
        if (loading || isDisableEdit || uploadAvatarLoading) return
        form.validateFields().then((params) => {
            setLoading(true)
            getEditGroup({
                id: activedRole.id,
                avatarUrl,
                ...params,
            }).then((res) => {
                if (res.success) {
                    message.success(t("components_successful_operation"))
                    // 修改成功之后需要重新刷新数据
                    dispatchGetMyTeamList()
                    // 关闭
                    closeModal(!open);
                }
                setLoading(false)
            });
        })
       
       
    };



    return (
        <Modal
            open={open}
            title={t("components_team_settings")}
            onCancel={handleOk}
            className={styles.teamSettingModal}
            footer={() => (
                <Row >
                    {isDisableEdit ? <Alert
                        className={"w-full text-center"}
                        message={t("components_only_owners_and_administrators_can_edit")}
                        type="info"
                    /> : <Button
                        loading={loading}
                        className={"w-full"}
                        type="primary"
                        htmlType={"submit"}
                        onClick={onFinish}
                    >
                        {t("components_confirm_the_changes")}
                    </Button>}
                </Row>
            )}
        >
            <div className={styles.settingContentBox}>
                <div className="flex flex-col items-center">
                    <OssAvatar
                        src={avatarUrl}
                        className="w-10 h-10 flex items-center justify-center ">
                        {gruopInfo?.name?.slice(0, 2)}
                    </OssAvatar>
                    <Upload
                       
                        disabled={isDisableEdit}
                        beforeUpload={beforeUpload}
                        itemRender={() => null}
                    >
                        <Button  loading={uploadAvatarLoading}  disabled={isDisableEdit} className="mt-2" icon={<UploadOutlined />}>
                            {t("components_upload_team_avatar")}
                        </Button>
                    </Upload>
                </div>
                <Form
                    form={form}
                    disabled={isDisableEdit}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{
                        ...gruopInfo,
                    }}
                >
                    <Form.Item
                        name="name"
                        className={"mt-5"}
                        label={t("components_team_name")}
                        rules={[{ required: true, message: "请输入" + t("components_team_name")}, { type: "string", max: 20 }]}
                    >
                        <Input
                            placeholder={"请输入" + t("components_team_name")}
                            count={{
                                show: true,
                                max: 15,
                                strategy: (txt) => txt.length,
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="intro"
                        label={t("components_team_profile")}
                        rules={[{ required: true,  message: "请输入" + t("components_team_profile") }, { type: "string", max: 150 }]}
                    >
                        <Input.TextArea placeholder={"请输入" + t("components_team_profile")} className={styles.textAreaStyle}  showCount maxLength={150} />
                    </Form.Item>
                    <div className="flex justify-between flex-row items-center mb-6 mt-9 ">
                        <span>{t("components_share_free_chat_transcripts")}</span>
                        <Form.Item
                            name="shareConversation"
                            label=""
                            valuePropName="checked"
                            className="mb-0"
                        >
                            <Switch
                                checkedChildren={t("components_turn_on")}
                                unCheckedChildren={t("components_closure")}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex justify-between flex-row items-center  mb-6">
                        <span>{t("components_share_creation_application_records")}</span>
                        <Form.Item   className="mb-0" name="shareApp" label="" valuePropName="checked">
                            <Switch
                                checkedChildren={t("components_turn_on")}
                                unCheckedChildren={t("components_closure")}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex justify-between flex-row items-center mb-8">
                        <span>{t("components_share_creative_records")}</span>
                        <Form.Item   className="mb-0" name="shareWorks" label="" valuePropName="checked">
                            <Switch
                                checkedChildren={t("components_turn_on")}
                                unCheckedChildren={t("components_closure")}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default TeamSettingModal