import {useState} from 'react';
import {useMount, useUnmount} from 'ahooks';
import {checkIsMobile} from '@/common/utils/tools';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useMount(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
  });

  useUnmount(() => {
    window.removeEventListener('resize', checkMobile);
  });

  const checkMobile = () => {
    const status = checkIsMobile();
    setIsMobile(status);
  };
  // return [isMobile];
  return [false];
}

export default useIsMobile;
