import {Modal} from "antd";
import {t} from 'i18next'
import {ConfigProvider} from 'antd';
import {ThemeContainer} from "@/App.jsx";

function CustomerConfirm(props){
  return Modal.confirm({
    centered: true,
    cancelText: t('components_cancel'),
    okText: t('components_confirm'),
    ...props,
    content: <ThemeContainer>{props.content}</ThemeContainer>
  });
}
export default CustomerConfirm;

export function customerSuccess(props) {
  return Modal.success({
    closable:true,
    centered: true,
    cancelText: t('components_cancel'),
    okText: t('components_confirm'),
    ...props,
    content: <ThemeContainer>{props.content}</ThemeContainer>
  });
}


