import request from '@/services/index';
import requestNoError from '@/services/responseNoError';

// 获取套餐列表
export const getVipPackageList = () => {
  return request.get(`/api/vip/listPackage`);
};

// 套餐购买确认
export const getBuyConfirm = () => {
  return request.post(`/api/vip/buyConfirm`);
};

export const currentPlan = async () => {
  return request.get(`/api/vip/currentPackage`);
};

// 获取套餐信息
export const getPackageById = (id,payChannel) => {
  return request.get(`/api/vip/buyConfirm/${id}`,{
    payChannel
  });
};

// 获取订单信息
export const getOrderById = (id) => {
  return requestNoError.get(`/api/vip/getOrderById/${id}`);
};

// 获取订单信息
export const buyConfirm = (id) => {
  return request.get(`/api/vip/buyConfirm/${id}`);
};


export function getVxAuth(params){
 return request.get(`/api/wechat/oAuth2`,params)
}



export function getSsapiTicket(params){
 return request.get(`/api/wechat/getJsapiTicket`,params)
}