import SvgIcon from "@/pages/components/SvgIcon";
import MenuName from "@/pages/components/MenuName/index.jsx";
import React from "react";
export const typeToSvgName = {
    'AMAZON': 'amaApp',
    'INDEPENDENT_STATION': 'siteApp',
    'SOCIAL_MEDIA': 'socialApp',
    'FOREIGN_TRADE': 'tradeApp',
    'OTHER': 'otherApp',
}

const typeMenu = [
    {
        key: 'AMAZON',
        label: <MenuName keyName={'menu_amazon'}/>,
        icon: <SvgIcon name={'amaApp'} style={{height: '14px', width: '14px', marginRight: '12px'}}/>,
        children: [
            {
                key: 'AMAZON_1',
                label: <MenuName keyName={'menu_listing_creation_and_optimization'}/>,
            },
            {
                key: 'AMAZON_2',
                label:<MenuName keyName={'menu_store_operations'}/> ,
            },
            {
                key: 'AMAZON_3',
                label:<MenuName keyName={'menu_product_selection_and_development'}/>,
            },
            {
                key: 'AMAZON_4',
                label:<MenuName keyName={'menu_keyword_planning'}/>,
            }
        ]
    },
    {
        key: 'INDEPENDENT_STATION',
        label: <MenuName keyName={'menu_independent_station'}/>,
        icon: <SvgIcon name={'siteApp'} style={{height: '14px', width: '14px', marginRight: '12px'}}/>,
        children: [
            {
                key: 'INDEPENDENT_STATION_1',
                label: 'SEO',
            }, {
                key: 'INDEPENDENT_STATION_2',
                label:<MenuName keyName={'menu_b2c_website_building'}/> ,
            }, {
                key: 'INDEPENDENT_STATION_3',
                label:<MenuName keyName={'menu_product_description'}/> ,
            }, {
                key: 'INDEPENDENT_STATION_4',
                label:<MenuName keyName={'menu_independent_station_product_selection'}/> ,
            }, {
                key: 'INDEPENDENT_STATION_5',
                label:<MenuName keyName={'menu_independent_station_customer_service'}/> ,
            }, {
                key: 'INDEPENDENT_STATION_6',
                label: <MenuName keyName={'menu_keyword_planning'}/>,
            }
        ]
    },
    {
        key: 'SOCIAL_MEDIA',
        label: <MenuName keyName={'menu_social_media'}/> ,
        icon: <SvgIcon name={'socialApp'} style={{height: '14px', width: '14px', marginRight: '12px'}}/>,
        children: [
            {
                key: 'SOCIAL_MEDIA_1',
                label: 'Facebook',
            }, {
                key: 'SOCIAL_MEDIA_2',
                label: 'Instagram',
            }, {
                key: 'SOCIAL_MEDIA_3',
                label: 'KOL',
            }, {
                key: 'SOCIAL_MEDIA_4',
                label: 'Linkedin',
            }, {
                key: 'SOCIAL_MEDIA_5',
                label: 'Pinterest',
            }, {
                key: 'SOCIAL_MEDIA_6',
                label: 'Quora',
            }, {
                key: 'SOCIAL_MEDIA_7',
                label: 'Tiktok',
            }, {
                key: 'SOCIAL_MEDIA_8',
                label: 'Twitter',
            },{
                key: 'SOCIAL_MEDIA_9',
                label: 'Youtube',
            },
        ]
    },
    {
        key: 'FOREIGN_TRADE',
        label: <MenuName keyName={'menu_foreign_trade'}/>,
        icon: <SvgIcon name={'tradeApp'} style={{height: '14px', width: '14px', marginRight: '12px'}}/>,
        children: [
            {
                key: 'FOREIGN_TRADE_1',
                label:<MenuName keyName={'menu_article'}/>,
            }, {
                key: 'FOREIGN_TRADE_2',
                label: <MenuName keyName={'menu_product_creation'}/>,
            }, {
                key: 'FOREIGN_TRADE_3',
                label: <MenuName keyName={'menu_b2c_website_building'}/>,
            }, {
                key: 'FOREIGN_TRADE_4',
                label:<MenuName keyName={'menu_foreign_trade_development_letter'}/>,
            }, {
                key: 'FOREIGN_TRADE_5',
                label:<MenuName keyName={'menu_keyword_planning'}/>,
            },
        ]
    },
    {
        key: 'OTHER',
        label: <MenuName keyName={'menu_menu_other'}/>,
        icon: <SvgIcon name={'otherApp'} style={{height: '14px', width: '14px', marginRight: '12px'}}/>,
        children: [
            {
                key: 'OTHER_1',
                label:<MenuName keyName={'menu_other_sumaitong'}/>,
            }, {
                key: 'OTHER_2',
                label: <MenuName keyName={'menu_other_etsy'}/>,
            }, {
                key: 'OTHER_3',
                label: <MenuName keyName={'menu_other_shopee'}/>,
            }, {
                key: 'OTHER_4',
                label:<MenuName keyName={'menu_other_ebay'}/>,
            }, {
                key: 'OTHER_5',
                label:<MenuName keyName={'menu_other_picture_analysis'}/>,
            },
        ]
    },
];

export default typeMenu;