import {ProTable} from '@ant-design/pro-components';
import {List, Grid} from 'antd-mobile';
import {Alert, Button, Flex, Input, Popconfirm, Radio, Space} from 'antd';
import {useRef, useState} from 'react';
import TeamInviteModal from '@/pages/components/TeamInviteModal/index.jsx';
import {
  deleteGroupMemberList,
  editGroupMember,
  getGroupMemberList,
} from '@/services/teamManange.js';
import {useRequest} from 'ahooks';
import {useT} from '@/common/utils/translation';
import MenuName from '../MenuName/index';
import useIsMobile from '@/common/hooks/useIsMobile.js';
import {useRootContext} from '@/common/RootContext.jsx';

const roleOptions = [
  {
    label: <MenuName keyName="components_administrator"></MenuName>,
    value: 'ADMIN',
  },
  {label: <MenuName keyName="components_member"></MenuName>, value: 'USER'},
];

const MemberTable = (props) => {
  let t = useT();
  const rootContextData = useRootContext();
  const [name, setName] = useState();
  const [role, setRole] = useState();
  const [total, setTotal] = useState(0);
  const [me, setMe] = useState();
  const actionRef = useRef();
  const [isMobile] = useIsMobile();
  const {runAsync: runDeleteGroupMemberList} = useRequest(
      deleteGroupMemberList,
      {
        manual: true,
        onSuccess: (res) => {
          if (res.success) {
            actionRef.current?.reload?.();
          }
        },
      },
  );

  const getList = async () => {
    const res = await getGroupMemberList();
    if (res.success) {
      setTotal(res.data?.length);
      const tempMe = res.data?.find((v) => v.me);
      setMe(tempMe);
    }
    return {
      success: res.success,
      data: res.data ?? [],
    };
  };

  const mobileList = useRequest(getList, {
    ready: isMobile,
  });

  const {runAsync: runEditGroupMember} = useRequest(editGroupMember, {
    manual: true,
    onSuccess: async (res) => {
      if (res.success) {
        if (!isMobile) {
          actionRef.current?.reload?.();
        } else {
          await mobileList.runAsync();
        }
      }
    },
  });

  const onRoleChange = ({target: {value}}) => {
    setRole(value);
  };

  const canEditRole = (row) => {
    if (row.role === 'OWNER') {
      return false;
    }
    if (me.role === 'OWNER') {
      return !row.me;
    }
    if (me.role === 'ADMIN') {
      return true;
    }
    if (me.role === 'USER') {
      return false;
    }
  };

  const canDelete = (row) => {
    if (row.me) {
      return false;
    }
    if (row.role === 'OWNER') {
      return false;
    }
    if (me.role === 'OWNER') {
      return !row.me;
    }
    if (me.role === 'ADMIN') {
      return true;
    }
    if (me.role === 'USER') {
      return false;
    }
  };

  const canEditRemark = (row) => {
    if (me.role === 'OWNER') {
      return true;
    }
    if (me.role === 'ADMIN') {
      return row.role !== 'OWNER';
    }
    if (me.role === 'USER') {
      return row.role === 'USER';
    }
  };

  const MeICON = () => {
    return <Flex
        justify="center"
        align="center"
        style={{
          marginLeft: '0.5rem',
          height: '1.5rem',
          width: '1.75rem',
          background: 'rgba(22, 119, 255, 1)',
          borderRadius: '0.75rem',
          color: '#fff',
          fontSize: '0.75rem',
          flexShrink: 0,
        }}
    >
      {t('menu_me')}
    </Flex>;
  };

  const columns = [
    {
      title: t('components_name'),
      dataIndex: 'remark',
      // width: '40%',
      render: (_, row) => (
          <div>
            <Flex>
              <div>{row.remark}</div>
              {row.me ? (
                  <MeICON/>
              ) : null}
            </Flex>
          </div>
      ),
    },
    {
      title: t('components_join_time'),
      dataIndex: 'createTime',
      hideInMobile: true,
    },
    {
      title: t('components_role'),
      dataIndex: 'role',
      renderText: (text) => {
        switch (text) {
          case 'OWNER':
            return t('components_owner');
          case 'ADMIN':
            return t('components_administrator');
          default:
            return t('components_member');
        }
      },
      hideInMobile: true,
    },
    {
      title: t('components_operate'),
      dataIndex: 'id',
      valueType: 'option',
      width: '210px',
      fixed: 'right',
      render: (_, row) => <Space>{canEditRole(row) ? (
          <Popconfirm
              key="role"
              icon={null}
              title={t('components_modify_role')}
              description={
                <Radio.Group
                    style={{marginBlock: '10px'}}
                    options={roleOptions}
                    onChange={onRoleChange}
                    value={role}
                />
              }
              onOpenChange={(open) => {
                if (open) {
                  setRole(row.role);
                }
              }}
              zIndex={3000}
              placement="bottom"
              onConfirm={async () => {
                await runEditGroupMember({id: row.id, role});
              }}
          >
            <a>
              <div>{t('components_modify_role')}</div>
            </a>
          </Popconfirm>
      ) : null}
        {canEditRemark(row) ? (
            <Popconfirm
                key="remark"
                icon={null}
                title={t('components_modify_the_note_name')}
                description={
                  <Input
                      style={{marginBlock: '10px'}}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                  />
                }
                zIndex={3000}
                placement="bottom"
                onConfirm={async () => {
                  await runEditGroupMember({id: row.id, remark: name});
                }}
            >
              <a>
                <div>{t(
                    'components_modify_the_note_name')}</div>
              </a>
            </Popconfirm>
        ) : null}
        {canDelete(row) ? (
            <Popconfirm
                key="delete"
                title={t('components_delete_member')}
                zIndex={3000}
                placement="bottomRight"
                description={t(
                    'components_after_deleting_a_member_the_content_created_by_the_member_will_still_be_retained_do_you_want_to_continue_deleting_it')}
                onConfirm={() => runDeleteGroupMemberList(row.groupId,
                    row.userId)}
            >
              <a>
                <div style={{color: 'rgba(255, 77, 79, 1)'}}>{t(
                    'components_delete')}</div>
              </a>
            </Popconfirm>
        ) : null}</Space>,
    },
  ];

  return (
      <>
        {rootContextData?.userCurrentPlan?.equityAccountCount ===
        total ? <Alert
            style={{marginTop: '24px'}}
            message={rootContextData?.userCurrentPlan?.vipPackage?.packageVersion ===
            'PROFESSIONAL' ? t('components_member_full2') : t(
                'components_member_full1')} type="info"
            showIcon/> : null}
        {isMobile ? <Flex justify="space-between" align="center"
                          style={{marginBlock: '16px'}}>
          <span
              style={{
                fontSize: '0.88rem',
                color: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 400,
              }}
          >
            {t('components_joined')} {total} {t(
              'components_people')}/{t(
              'components_total2')} {rootContextData?.userCurrentPlan?.equityAccountCount} {t(
              'components_people')}
          </span>
          <TeamInviteModal
              key="invite"
              submitter={
                <Button type="primary" key="1"
                        disabled={rootContextData?.userCurrentPlan?.equityAccountCount ===
                            total}>
                  {t('components_invite_members')}
                </Button>
              }
              onOpen={() => props.onOpenTeamInviteModal?.()}
          />
        </Flex> : null}
        {isMobile ? <List>
          {mobileList?.data?.data?.map((item) => (<List.Item
              key={item.userId}
              description={<div>
                <div style={{marginBottom: '5px'}}>{item.role}</div>
                <Grid columns={3} gap={8}>
                  {canEditRole(item) ? (
                      <Popconfirm
                          key="role"
                          icon={null}
                          title={t('components_modify_role')}
                          description={
                            <Radio.Group
                                style={{marginBlock: '10px'}}
                                options={roleOptions}
                                onChange={onRoleChange}
                                value={role}
                            />
                          }
                          onOpenChange={(open) => {
                            if (open) {
                              setRole(item.role);
                            }
                          }}
                          zIndex={3000}
                          placement="bottom"
                          onConfirm={async () => {
                            await runEditGroupMember({id: item.id, role});
                          }}
                      >
                        <Button type="primary" ghost>{t(
                            'components_modify_role')}</Button>
                      </Popconfirm>
                  ) : null}
                  {canEditRemark(item) ? (
                      <Popconfirm
                          key="remark"
                          icon={null}
                          title={t('components_modify_the_note_name')}
                          description={
                            <Input
                                style={{marginBlock: '10px'}}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                value={name}
                            />
                          }
                          zIndex={3000}
                          placement="bottom"
                          onConfirm={async () => {
                            await runEditGroupMember(
                                {id: item.id, remark: name});
                          }}
                      >
                        <Button type="primary" style={{paddingInline: '0'}}
                                ghost>{t(
                            'components_modify_the_note_name')}</Button>
                      </Popconfirm>
                  ) : null}
                  {canDelete(item) ? (
                      <Popconfirm
                          key="delete"
                          title={t('components_delete_member')}
                          zIndex={3000}
                          placement="bottomRight"
                          description={t(
                              'components_after_deleting_a_member_the_content_created_by_the_member_will_still_be_retained_do_you_want_to_continue_deleting_it')}
                          onConfirm={() => runDeleteGroupMemberList(
                              item.groupId,
                              item.userId)}
                      >
                        <Button type="primary" ghost danger>
                          {t('components_delete')}
                        </Button>
                      </Popconfirm>
                  ) : null}
                </Grid>
              </div>}
          >
            <Flex align="center" justify="space-between">
              <div>{item.remark}</div>
              <div>{item.createTime}</div>
            </Flex>
          </List.Item>))}
        </List> : <ProTable
            cardProps={{
              bodyStyle: {
                paddingInline: 0,
                paddingBlock: '8px 16px',
              },
            }}
            actionRef={actionRef}
            columns={columns}
            rowKey="id"
            options={false}
            search={false}
            pagination={false}
            // scroll={{x: isMobile ? '32rem' : 'max-content'}}
            toolbar={isMobile ? undefined : {
              title: (
                  <span
                      style={{
                        fontSize: '0.88rem',
                        color: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 400,
                      }}
                  >
            {t('components_joined')} {total} {t(
                      'components_people')}/{t(
                      'components_total2')} {rootContextData?.userCurrentPlan?.equityAccountCount} {t(
                      'components_people')}
          </span>
              ),
              actions: [
                <TeamInviteModal
                    key="invite"
                    submitter={
                      <Button type="primary" key="1"
                              disabled={rootContextData?.userCurrentPlan?.equityAccountCount ===
                                  total}>
                        {t('components_invite_members')}
                      </Button>
                    }
                    onOpen={() => props.onOpenTeamInviteModal?.()}
                />,
              ],
            }}
            request={getList}
        />}

        <span
            style={{
              fontSize: '0.88rem',
              color: 'rgba(0, 0, 0, 0.65)',
              fontWeight: 400,
            }}
        >失效成员</span>

        {isMobile ? null : <ProTable
            cardProps={{
              bodyStyle: {
                paddingInline: 0,
                paddingBlock: '8px 16px',
              },
            }}
            columns={columns}
            rowKey="id"
            options={false}
            search={false}
            pagination={false}
            request={async () => {
              return {
                success: true,
                data: [],
              };
            }}
        />}
      </>
  );
};

export default MemberTable;
