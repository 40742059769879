import {Button, message, Space, Typography} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import React, {useContext, useEffect, useState} from "react";
import ParamsLine from "@/pages/AddApplication/ParamsList/ParamsLine";
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from "react-sortable-hoc";
import {CopyOutlined, HolderOutlined} from "@ant-design/icons";
import styles from './index.module.scss';
import {v4} from 'uuid';
import AddApplicationContext from "@/pages/AddApplication/Context";
import {copyToClip} from "@/common/utils/tools.js";
import {useT} from '@/common/utils/translation'
import CopyButton from "@/pages/components/CopyButton/index.jsx";
import {PrimaryBgColor, PrimaryColor} from "@/const/index.jsx";

const {Link} = Typography;

export const SortContainer = SortableContainer(({children}) => {
  return (<div>{children}</div>)
});

const SortElement = SortableElement((data) => {
  const {item, onChange, disabled} = data;
  let t = useT();

  return (<div>
    <div className={styles.sortContainer}>
      {!disabled && (
        <SortAbleHand>
          <span className={styles.sortText}>{item.tag}</span>
        </SortAbleHand>
      )}

      {!disabled && (
        <span style={{marginLeft: '8px'}}>
          <CopyButton
            onClick={() => {
              copyToClip('${' + item.tag + '}').then(() => {
                message.success(t('menu_copied_successfully'));
              });
            }}
          />
        </span>
      )}
    </div>
    <ParamsLine value={item} onChange={onChange}/>
  </div>)
});

const SortAbleHand = SortableHandle(({children}) => {
  return (<span>
      <HolderOutlined style={{color: 'rgba(0,0,0,0.25)'}}/>
    {children}
    </span>)
})


function ParamsList({disabled}) {
  let t = useT()
  const contextData = useContext(AddApplicationContext);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(contextData.step2List || []);
  }, [contextData.step2List]);

  const onSortEnd = ({newIndex, oldIndex}) => {
    const tempList = arrayMove(list, oldIndex, newIndex);
    setList(tempList);
    contextData.setStep2List(tempList);
  }

  const onAddParam = () => {
    const autoTag = getAutoTag();
    const tempList = [...list, {tag: autoTag, name: autoTag, description: '', id: v4()},];
    setList(tempList);
    contextData.setStep2List(tempList);

    const autoTagText = `\$\{${autoTag}\}`;
    contextData.setNoticeText(contextData.noticeText + autoTagText)

    copyToClip(autoTagText).then(() => {
      message.success(`${autoTag} ${t('menu_copied_to_clipboard')}`)
    });

  }

  const getAutoTag = () => {
    let index = list.length + 1;
    const defaultPrefix = t('menu_parameter');
    while (checkExit(`${defaultPrefix}${index}`)) {
      index++;
    }
    return `${defaultPrefix}${index}`
  }

  const checkExit = (name) => {
    return list.find(item => item.tag === name);
  }

  return (<div>
    <div>
      {
        !disabled && (
          <Button
            onClick={onAddParam}
            style={{
              background: PrimaryBgColor, borderRadius: '4px', border: "none", color: PrimaryColor,
            }}
            size={'small'}
          >
            <Space>
              <PlusOutlined/>
              {t('menu_insert_variable_parameters')}
            </Space>
          </Button>
        )
      }
    </div>

    <div className={styles.sortListContainer}>
      <SortContainer onSortEnd={onSortEnd} useDragHandle>
        {list.map((item, index) => {
          return (<SortElement
            disabled={disabled}
            key={item.id}
            index={index}
            item={item}
            onChange={(value) => {
              const tempList = JSON.parse(JSON.stringify(list))
              tempList[index] = value;
              setList(tempList);
              contextData?.setStep2List(tempList);
            }}
          />)
        })}
      </SortContainer>
    </div>
  </div>)
}

export default ParamsList;