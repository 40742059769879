import styles from './index.module.scss';
import SvgIcon from "@/pages/components/SvgIcon";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Dropdown, Upload} from "antd";
import cls from "classnames";
import TextArea from "antd/es/input/TextArea.js";
import HistoryDrawer from "@/pages/Talk/components/HistoryDrawer/index.jsx";
import {conversationChat, createConversation, fetchConversation, fetchConversationMessages} from "@/services/talk.js";
import {useRequest} from "ahooks";
import {v4 as uuid} from 'uuid'
import {PictureOutlined, UserOutlined} from "@ant-design/icons";
import MarkdownMessage from "@/pages/components/MarkdownMessage/index.jsx";
import dayjs from "dayjs";
import { useT } from '@/common/utils/translation';
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {getProof, OssFileType, uploadFileToOSS} from "@/services/upload.js";
import OssImage from "@/pages/components/OssImage/index.jsx";
import ImageItem from "@/pages/components/ImageItem/index.jsx";
import Introduction from "@/pages/Talk/components/Introduction.jsx";
import {useRootContext} from "@/common/RootContext.jsx";
import {VipContext} from "@/context/vip/index.jsx";
import {PACKAGE_EQUITY_ERROR} from "@/services/const.js";
import useVipOverdueNote from "@/pages/components/HomeHeader/VipOverdueInfo.jsx";
import CustomerConfirm from "@/common/utils/CustomerConfirm.jsx";
import iconLoading from '@/assets/icons/loading.svg'
import {blobToDataUrl} from "@/lib/image.js";
import {isOssKey} from "@/lib/utils.js";
import {PrimaryColor} from "@/const/index.jsx";

const SenderType = {
  ROBOT: 'ROBOT',
  USER: 'USER',
}

const IMAGE_MAX_COUNT = 10

const ModelLabel = ({label, disabled, cost, vip, isVip}) => {
  return (
    <div className="flex items-center whitespace-nowrap">
      <span>{label}</span>
      {disabled && vip && !isVip && (
        <span className="ml-1 rounded-lg text-xs px-1 border border-solid border-gray-300">VIP</span>
      )}
      {cost && !disabled && (
        <span className="text-gray-400">{cost}</span>
      )}
    </div>
  )
}

const modelList = [
  {
    value: 'GPT35',
    label: 'KQ-3.5',
    cost: {
      FREE: 1,
      STANDARD: 1,
      PROFESSIONAL: 0,
    },
    vip: false,
  },
  {
    value: 'GPT4',
    label: 'KQ-4',
    cost: {
      FREE: null,
      STANDARD: 15,
      PROFESSIONAL: 15,
    },
    vip: true,
  },
  {
    value: 'GPT4_VISION',
    label: 'KQ-4 Vision',
    cost: {
      FREE: null,
      STANDARD: 30,
      PROFESSIONAL: 30,
    },
    vip: true,
  }
]

export const MarkdownLoadingPic = ` ![not finish](${iconLoading})`

const Talk = () => {
  let t = useT()
  const rootContentData = useRootContext()

  const {contextHolder: vipContextHolder} = useVipOverdueNote();
  const {dispatchSetVipModalVisible} = useContext(VipContext)
  const [imageList, setImageList] = useState([])
  const [conversation, setConversation] = useState();
  const [messageList, setMessageList] = useState([])
  const [messagePage, setMessagePage] = useState(1)
  const [messageTotal, setMessageTotal] = useState(0)
  const [gptModel, setGptModel] = useState(modelList[0])
  const [menuOpen, setMenuOpen] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)
  const [inputContent, setInputContent] = useState('')
  const [currentReq, setCurrentReq] = useState()
  const listRef = useRef(null)
  const [reader, setReader] = useState(null)
  const [inputLayoutHeight, setInputLayoutHeight] = useState(0)
  const inputLayoutRef = useRef(null)

  const {runAsync: create, loading: creating} = useRequest(createConversation, {
    manual: true,
  })

  const {runAsync: loadMessage, loading: loadingMessage} = useRequest(fetchConversationMessages, {
    manual: true,
    debounceWait: 30,
    onSuccess: (res, params) => {
      setMessageList(list => {
        const dataList = res.data.list
            .reverse()
            .filter(t => !list.find(item => item.id === t.id))
            .map(t => ({
              ...t,
              message: t.message.message,
              images: t.message.images,
              done: true,
            }))
        if (params[1] === 1) {
          return dataList
        } else {
          return dataList.concat(list)
        }
      })
      if (params[1] === 1) {
        scrollToBottom()
      }
      setMessagePage(params[1])
      setMessageTotal(res.data.total)
    }
  })

  const packageVersion = rootContentData?.userCurrentPlan?.vipPackage.packageVersion
  const talkCost = gptModel?.cost?.[packageVersion]

  const gptMenu = modelList.map((item) => {
    const disabled = !rootContentData?.userCurrentPlan?.equityGpt?.includes(item.value)
    const cost = item.cost[packageVersion]
    return {
      key: item.value,
      label: (
        <ModelLabel
          label={item.label}
          disabled={disabled}
          cost={cost !== null && t('talk_point_cost', cost)}
          vip={item.vip}
          isVip={packageVersion !== "FREE"}
        />
      ),
      value: item.value,
      title: item.label,
      disabled,
    }
  })

  const scrollToBottom = () => {
    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight
      }
    }, 20)
  }

  const onGptChange = (item) => {
    setMenuOpen(false)
    setGptModel(modelList.find(t => t.value === item.key))
  }
  const changeConversation = (item) => {
    fetchConversation(item.id).then(res => {
      setHistoryOpen(false)
      setConversation(res.data)
    })
    void loadMessage(item.id, 1)
  }

  const onScroll = useCallback((e) => {
    if (conversation &&
      e.target.scrollTop < 50 &&
      !loadingMessage &&
      messageList?.length < messageTotal
    ) {
      void loadMessage(conversation.id, messagePage + 1)
    }
  }, [loadingMessage, messageList, messageTotal, loadMessage, conversation, messagePage])

  const onSendClick = async () => {
    if (!rootContentData.loginStatus) {
      rootContentData.dispatchSetLoginVisible(true)
      return
    }
    if (!inputContent.trim()) return
    let id = conversation?.id
    if (!id) {
      const model = gptModel.value
      const data = await create({
        model
      })
      if (!data.success) {
        throw new Error(data.message)
      }
      setConversation({
        model,
        id: data.data
      })
      id = data.data
    }
    talk(id)
  }

  const talk = (id) => {
    if (!inputContent.trim() || currentReq) return
    const images = (gptModel.value === 'GPT4_VISION' && imageList.length > 0) ? imageList.filter(t => !!t.url) : undefined
    const sendMessage = {
      from: SenderType.USER,
      id: uuid(),
      message: inputContent,
      images: images?.map(t => t.url),
      dataUrlList: images?.map(t => t.dataUrl),
      createTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      replyTo: null
    }
    const msgList = [
      ...messageList,
      sendMessage,
    ]
    setMessageList(msgList)
    setMessageTotal(num => num + 1)
    scrollToBottom()
    const msg = {
      id: uuid(),
      from: SenderType.ROBOT,
      message: '',
      images: [],
      replyTo: sendMessage.id,
      createTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    }
    setCurrentReq({...msg})
    setInputContent('')
    setImageList([])
    setMessageList(list => {
      return [...list, {...msg}]
    })
    conversationChat({
      conversationId: id,
      message: inputContent,
      images: images?.map(t => t.url),
    }).then(async (res) => {
      setMessageTotal(num => num + 1)
      const reader = res.body.pipeThrough(new TextDecoderStream()).getReader()
      setReader(reader)
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const {value, done} = await reader.read();
        if (value) {
          msg.message += value
          setMessageList((list) => {
            const index = list.findIndex(t => t.id === msg.id)
            if (index !== -1) {
              const newList = [...list]
              newList[index] = {
                ...msg,
                message: msg.message,
                done,
              }
              return newList
            }
            return list
          })
        }
        if (done) {
          setCurrentReq(undefined)
          setReader(null)
          setMessageList((list) => {
            const index = list.findIndex(t => t.id === msg.id)
            if (index !== -1) {
              const newList = [...list]
              newList[index] = {
                ...newList[index],
                done: true,
              }
              return newList
            }
            return list
          })
          try {
            const data = JSON.parse(msg.message)
            if (!data.success) {
              if (data.code === PACKAGE_EQUITY_ERROR) {
                msg.message = data.data
                setMessageList(list => {
                  const index = list.findIndex(t => t.id === msg.id)
                  if (index !== -1) {
                    const newList = [...list]
                    newList[index] = {...msg}
                    return newList
                  }
                  return list
                })
                CustomerConfirm({
                  title: data.message,
                  content: data.data,
                  okText: t('button_update_permissions'),
                  onOk: () => {
                    dispatchSetVipModalVisible();
                  },
                });
              }
            }
          } catch (e) {
            // nothing
          }
          break
        }
        scrollToBottom()
      }
    }).catch(e => {
      console.error(e)
      setCurrentReq(undefined)
    })
  }

  useEffect(() => {
    rootContentData.eventEmitter.on('menu-item-click', resetPage)
    return () => {
      rootContentData.eventEmitter.off('menu-item-click', resetPage)
    }
  }, []);
  const resetPage = (item) => {
    if (item.key === '/talk') {
      setConversation(undefined)
      setImageList([])
      setMessageList([])
      setMessagePage(1)
      setMessageTotal(0)
      setInputContent('')
      setCurrentReq(undefined)
      if (reader) {
        reader.cancel()
        setReader(null)
      }
    }
  }

  const handleEnterPress = (e) => {
    // console.log(e)
    if (e.keyCode === 13) {
      if (!e.shiftKey) {
        e.preventDefault()
        onSendClick()
      }
    }
  }

  const handleTextChange = (e) => {
    // console.log('change', e)
    let text = e.target.value
    // if (text.endsWith('\n')) {
    //   text = text.substring(0, text.length - 1)
    // }
    setInputContent(text)
  }

  const handleUploadImage = async ({file}) => {
    const key = uuid()
    const dataUrl = await blobToDataUrl(file)
    setImageList(list => {
      if (list.length >= IMAGE_MAX_COUNT) return list
      return list.concat([{
        file,
        key,
        dataUrl,
        url: '',
        status: 'uploading',
      }])
    })
    try {
      const proof = await getProof({fileType: OssFileType.TEMP})
      const [res] = await uploadFileToOSS([file], proof)
      setImageList(list => {
        const index = list.findIndex(t => t.key === key)
        if (index !== -1) {
          const newList = [...list]
          newList[index] = {
            ...newList[index],
            url: res.url,
            status: 'done',
          }
          return newList
        }
        return list
      })
    } catch (e) {
      console.error(e)
      setImageList(list => {
        const index = list.findIndex(t => t.key === key)
        if (index !== -1) {
          const newList = [...list]
          newList[index] = {
            ...newList[index],
            url: '',
            status: 'error',
          }
          return newList
        }
      })
    }
  }

  const handleRemoveImage = (key) => {
    setImageList(list => {
      return list.filter(t => t.key !== key)
    })
  }

  const resizeList = () => {
    setTimeout(() => {
      if (inputLayoutRef.current) {
        const delta = inputLayoutRef.current.clientHeight - inputLayoutHeight
        setInputLayoutHeight(inputLayoutRef.current.clientHeight)
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollTop + delta
          }
        })
      }
    })
  }

  useEffect(() => {
    window.addEventListener('resize', resizeList)
    return () => {
      window.removeEventListener('resize', resizeList)
    }
  }, []);

  useEffect(() => {
    resizeList()
  }, [imageList, inputContent]);

  return (
    <div className={styles.container} style={{paddingBottom: inputLayoutHeight}}>
      {vipContextHolder}
      {conversation ? (
        <div className={styles["talk"]} onScroll={onScroll} ref={listRef}>
          <div className={styles['talk-title']}>
            <SvgIcon name="ChatGPT" className={styles['talk-title-logo']} />
            <span>{gptModel.label}
              <span className="text-gray-400">{talkCost !== null ? t('talk_point_cost', talkCost) : ''}</span>
            </span>
          </div>
          {loadingMessage && (
            <div className="flex justify-center py-2">
              <LoadingOutlined spin={true} />正在加载……
            </div>
          )}
          <div className={styles['talk-content']}>
            {messageList.map(item => {
              if (item.from === SenderType.ROBOT || item.from === SenderType.USER) {
                return (
                  <div
                    key={item.id}
                    className={cls(
                      styles['message'],
                      {[styles['from-robot']]: SenderType.ROBOT === item.from,
                        [styles['from-user']]: SenderType.USER === item.from}
                    )}>
                    <div className={styles['message-content']}>
                      {item.from === SenderType.ROBOT ? (
                        <div
                          className={cls(styles['message-avatar'])}
                          style={{backgroundColor:PrimaryColor}}
                        >
                          <SvgIcon name="ChatGPT" style={{color: '#fff'}} />
                        </div>
                      ) : (
                        <div
                          className={cls(styles['message-avatar'])}
                          style={{backgroundColor: '#2ad38e'}}
                        >
                          <UserOutlined style={{fontSize: '1.5rem'}} />
                        </div>
                      )}
                      {item.from === SenderType.USER ? (
                        <div className="py-2">
                          <div className="whitespace-pre-wrap">{item.message}</div>
                          {item.images?.length > 0 && (
                            <div className="flex gap-2 flex-wrap mt-2">
                              {(item.dataUrlList || item.images).map(url => (
                                <div className="md:w-[21.5rem] md:h-[21.5rem] w-[7rem] h-[7rem] border border-solid border-black/5 rounded flex items-center justify-center" key={url}>
                                  {isOssKey(url) ? (
                                    <OssImage src={url} key={url} className="object-contain max-w-full max-h-full" />
                                  ) : (
                                    <img src={url} key={url} className="object-contain max-w-full max-h-full" />
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex-1 overflow-x-auto">
                          <MarkdownMessage content={item.message + (item.done ? '' : MarkdownLoadingPic)} className={styles['message-data']} />
                          {item.images?.length > 0 && (
                              <div className="flex gap-2 flex-wrap mt-2">
                                {item.images.map(url => (
                                    <OssImage src={url} key={url} className="md:w-[21.5rem] md:h-[21.5rem] w-[7rem] h-[7rem]" />
                                ))}
                              </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      ) : (
        <Introduction
          onTextClick={setInputContent}
        />
      )}
      <div
        className={styles['input-layout']}
        style={{backgroundColor: messageList.length > 0 ? 'white' : '#f6f7f8'}}
        ref={inputLayoutRef}>
        <div className={styles['actions-wrapper']}>
          {!conversation && (
            <div className={styles.actions}>
              <Dropdown
                menu={{
                  items: gptMenu,
                  selectable: true,
                  defaultSelectedKeys: [gptModel.value],
                  onSelect: onGptChange,
                }}
                placement="top"
                trigger="click"
                open={menuOpen}
                onOpenChange={(open) => setMenuOpen(open)}
              >
                <div className={cls(styles.action, {[styles.active]: menuOpen})}>
                  <SvgIcon name="ChatGPT" className={styles['action-icon']} style={{color: PrimaryColor}} />
                  <div className={styles['gpt-label']}>
                    {gptModel.label}
                    <span className="text-gray-400">{talkCost !== null ? t('talk_point_cost', talkCost) : ''}</span>
                  </div>
                  <SvgIcon name="arrow-down" className={cls(styles['gpt-arrow'])} />
                </div>
              </Dropdown>
              <div className={cls(styles.action)} onClick={() => setHistoryOpen(true)}>
                <SvgIcon name="history" className={styles['action-icon']} />
                <div>{t('createCenter_history_record')}</div>
              </div>
            </div>
          )}
          <div className={styles['textarea-box']}>
            {imageList.length > 0 && (
              <div className="flex gap-2 mb-2 flex-wrap">
                {imageList.map(item => (
                  <div key={item.key} className={styles['image-wrapper']}>
                    <ImageItem
                      src={item.url}
                      file={item.file}
                      loading={item.status === 'uploading'}
                      error={item.status === 'error' && '上传失败'}
                      loadingText=""
                      showSave={false}
                      showPreview={false}
                      showDownload={false}
                      showRemove={false}
                      size="3.5rem"
                      className="rounded-xl border-[0.06rem] border-solid border-gray-300"
                    />
                    <SvgIcon
                      name="close-circle"
                      onClick={() => handleRemoveImage(item.key)}
                      className={cls(styles['icon-remove'])}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={styles['textarea-wrapper']}>
              {gptModel.value === 'GPT4_VISION' && (
                <Upload
                  onChange={handleUploadImage}
                  beforeUpload={() => false}
                  showUploadList={false}
                  multiple={true}
                  fileList={[]}
                  disabled={IMAGE_MAX_COUNT <= imageList.length}
                  maxCount={IMAGE_MAX_COUNT - imageList.length}
                  accept=".png,.jpg,.jpeg,.webp"
                >
                  <PictureOutlined className="text-gray-500 relative bottom-[.18rem] text-xl cursor-pointer" />
                </Upload>
              )}
              <TextArea
                placeholder={t('createCenter_please_enter_custom_writing_instructions')}
                value={inputContent}
                onPressEnter={handleEnterPress}
                onChange={handleTextChange}
                autoSize={{maxRows: 5}}
                className={styles.textarea}
              />
              <div
                className={cls(styles.send, {[styles.disabled]: !inputContent.length || currentReq || creating})}
                onClick={onSendClick}
              >
                {currentReq || creating ? (
                  <LoadingOutlined spin={true} />
                ) : (
                  <SvgIcon name="arrow-right" className={styles['icon-send']} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HistoryDrawer
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        onHistoryClick={changeConversation}
      />
    </div>
  )
}
export default Talk;