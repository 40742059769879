import {Flex} from 'antd';
import useStyle from '@/pages/components/SettlementBoard/hooks/useStyle.js';

const DescriptionItem = (props) => {
  const {descriptionItemClassname} = useStyle();
  return (
      <Flex style={props.style} justify="space-between" align={props.align}
            className={descriptionItemClassname}>
        <div className="f-14 item-title">{props.title}</div>
        <div className="f-14 item-value">{props.value}</div>
      </Flex>
  );
};

export default DescriptionItem;