import MenuName from "@/pages/components/MenuName/index.jsx";
import SvgIcon from "@/pages/components/SvgIcon/index.jsx";
import AmazonOutlined from "@ant-design/icons/lib/icons/AmazonOutlined";

const menus = [
  {
    key: '/',
    label: '数据看板',
    icon: <SvgIcon name={'dashboard'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
  },
  {
    key: '/application-manager',
    label:'应用管理',
    icon: <SvgIcon name={'application'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
    children: [
      {
        key: '/channel-application',
        label: '渠道应用',
      },{
        key: '/home-application',
        label: '首页应用',
      },{
        key: '/customer-application',
        label: '客户应用',
      },
    ]
  },
  {
    key: '/customer',
    label:'客户管理',
    icon: <SvgIcon name={'customer'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
    children: [
      {
        key: '/customer/list',
        label: '客户列表',
      },{
        key: '/customer/invite',
        label: '专属链接客户',
      },
    ]
  },
  {
    key: '/order-center',
    label: '订单中心',//'智能作图',
    icon: <SvgIcon name={'order'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
  },

  {
    key: '/marketing',
    label:'营销管理',
    icon: <SvgIcon name={'marketing'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
    children: [
      {
        key: '/marketing/salesman',
        label: '营销成员',
      },
      {
        key: '/marketing/redemption-code',
        label: '兑换码',
      },
    ]
  },
  {
    key: '/enterprise',
    label:'企业管理',
    icon: <SvgIcon name={'enterprise'} style={{height: '14px', width: '14px', marginRight: '10px'}}/>,
    children: [
      {
        key: '/enterprise/list',
        label: '企业套餐',
      },
    ]
  },
];

export default menus;