import axios from "axios";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {v4 as uuid} from "uuid";
import stores from "@/common/utils/stores.js";
import Fingerprint2 from "fingerprintjs2";
import request from "@/services/index.jsx";
import isEmpty from "lodash/isEmpty";
// 生成nanoid

export const listRequest = async (api, params, sort, fieldMap) => {
    const _params = {
        ...params,
        pageNum: params.current,
    };
    if (!isEmpty(sort) && fieldMap) {
        Object.keys(sort).map((s) => {
            _params.sortField = fieldMap[s];
            _params.sortType = sort[s] === "ascend" ? "ASC" : "DESC";
        });
    }
    const res = await request.get(api, _params);
    return {
        success: res.success,
        message: res.message,
        total: res.data?.total ?? 0,
        data: res.data?.list ?? [],
    };
};

let urlAlphabet =
    "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";

export const nanoid = (size = 21) => {
    let id = "";
    let i = size;
    while (i--) {
        id += urlAlphabet[(Math.random() * 64) | 0];
    }
    return "client-" + id;
};

export const batchDownloadAndZipFile = async (propUrls, name, isBlob) => {
    let resList;
    if (isBlob) {
        resList = propUrls;
    } else {
        const urls = propUrls
            .filter((t) => !!t)
            .map((t) => {
                return isOssKey(t) ? getOssRedirectUrl(t, true) : t;
            });

        if (!urls.length) return;

        resList = await Promise.all(
            urls.map((url) => {
                return axios
                    .get(url, {
                        responseType: "blob",
                    })
                    .then((t) => t.data);
            })
        );
    }

    const zip = new JSZip();
    resList.forEach((blob) => {
        let name = uuid();
        if (!name.includes(".")) {
            const ext = blobImageTypeMap[blob.type];
            if (ext) {
                name += "." + ext;
            }
        }
        zip.file(name, blob, {binary: true});
    });
    zip
        .generateAsync({
            type: "blob",
            compression: "STORE",
        })
        .then((res) => {
            saveAs(res, name || `kerqu-ai-image-${Date.now()}.zip`);
        });
};

export const fileToDataUrl = (file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();
        reader.onload = function (e) {
            img.src = e.target.result;
        };
        reader.onerror = function (e) {
            reject(e);
        };
        reader.readAsDataURL(file);
        img.onload = function () {
            resolve(img.src);
        };
        img.onerror = function (e) {
            reject(e);
        };
    });
};

export const getOssRedirectUrl = (key, origin) => {
    try {
        if (!key) return "";
        const ossKey = getOssKey(key);
        return (
            import.meta.env.VITE_API_BASE_URL +
            "/api/aiImage/downloadImage?url=" +
            encodeURIComponent(ossKey) +
            `&originalImage=${origin ? "true" : "false"}`
        );
    } catch (err) {
    }
};

export const isOssKey = (url) => {
    if (!url || typeof url !== "string") return false;
    return (
        url.startsWith(import.meta.env.VITE_PUBLIC_OSS_HOST) ||
        url.startsWith("temp/") ||
        url.startsWith("/temp/") ||
        url.startsWith("group/") ||
        url.startsWith("/group/") ||
        url.startsWith("image/") ||
        url.startsWith("/image/")
    );
};

export const getOssKey = (url) => {
    if (url.startsWith(import.meta.env.VITE_PUBLIC_OSS_HOST)) {
        let start = url.indexOf("/temp/");
        if (start == -1) {
            start = url.indexOf("/image/");
        }
        const end = url.indexOf("?");
        return url.substring(start + 1, end);
    } else if (url.startsWith("temp/")) {
        return url;
    } else if (url.startsWith("/temp/")) {
        return url.substring(1);
    } else if (url.startsWith("group/")) {
        return url;
    } else if (url.startsWith("/group/")) {
        return url.substring(1);
    }
    throw new Error("invalid oss key");
};

const blobImageTypeMap = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/webp": "webp",
};

/**
 * 获取浏览器WebGl指纹
 */
export function getWebGlFingerprint() {
    // 创建一个canvas元素
    var canvas = document.createElement("canvas");
    // 获取WebGL上下文
    var gl =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (!gl) {
        console.log("WebGL is not supported");
        return "";
    } else {
        // 生成一个包含WebGL信息的指纹
        var extension = gl.getExtension("WEBGL_debug_renderer_info");
        var renderer = gl.getParameter(extension.UNMASKED_RENDERER_WEBGL);
        return renderer;
    }
}

/**
 * 获取浏览器canvansID
 * @returns
 */
export function getCanvasFingerprint() {
    // 创建一个canvas元素
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    // 设置canvas的特定属性
    canvas.width = 1;
    canvas.height = 1;
    ctx.textBaseline = "alphabetic";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);

    // 生成Canvas指纹
    var canvasFingerprint = canvas.toDataURL();

    return canvasFingerprint || "";
}

// 获取浏览器指纹
export function setBroswerFingerprint() {
    try {
        setTimeout(() => {
            Fingerprint2.get((components) => {
                // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
                const webgl =
                    components.find((item) => item.key === "webgl")?.value || [];
                const canvas =
                    components.find((item) => item.key === "canvas")?.value || [];
                const webglID = Fingerprint2.x64hash128(webgl.join(""), 31); // 生成webGLid
                const canvaslID = Fingerprint2.x64hash128(canvas.join(""), 31); // 生成canvansId
                stores.saveLocalStorage("webglId", webglID || "");
                stores.saveLocalStorage("canvaslId", canvaslID || "");
            });
        }, 1000);
    } catch (e) {
        console.log(e);
    }
}


/**
 * 使用 栈的 方案 遍历 树形 数据
 * @param tree
 * @param options  参数
 * */
export function ergodicTree(
    tree = [],
    {
        childName = 'child',
        direction = 'l-dfs',
        itemCallBack = () => {
        },
        autoAddParentId = false // 是否自动添加 parentId
    } = {}
) {

    if (['l-dfs', 'r-dfs', 'l-bfs', 'r-bfs'].indexOf(direction) === -1) {
        console.log(new Error('传入的遍历方向不对,参考:\'l-dfs\',\'r-dfs\',\'l-bfs\',\'r-bfs\''))
        return
    }

    let _status = true // 存储是否继续进行遍历的 一个 状态开关
    let _stack = [] // 存放栈 数据

    tree.forEach(item => { // 将 第一次数据放到对应的栈里面
        if (direction === 'l-dfs' || direction === 'r-bfs') { // 左 子节点深度遍历 和 右 子节点 广度遍历
            _stack.unshift(item)
        } else if (direction === 'r-dfs' || direction === 'l-bfs') { // 右 子节点深度遍历 和 左 子节点 广度遍历
            _stack.push(item)
        }
    })

    // 检查 栈是否为空，和 当前 遍历状态是否 可用，如果 栈 被清空，说明没有可以便利的数据
    while (_stack.length && _status !== false) {
        let _stackTop // 取出栈顶 元素
        if (direction === 'l-bfs' || direction === 'r-bfs') { // 左 子节点  和 右 子节点 广度遍历
            _stackTop = _stack.shift()
        } else { // 右 子节点深度遍历 和 左 子节点 广度遍历
            _stackTop = _stack.pop()
        }

        // 把这个元素 回调回去, 根据 _status 确定 是否 还继续 向下 遍历
        _status = itemCallBack(_stackTop)

        if (_stackTop[childName] && _stackTop[childName].length) { // 检查栈顶元素是否还有子节点

            let _child = []
            if (direction === 'l-dfs' || direction === 'r-bfs') { // 左 子节点 深度  和 右 子节点 广度遍历
                _child = _stackTop[childName].reverse() // 因为是左叶子节点深度遍历，所以需要对自己点翻转
            } else if (direction === 'r-dfs' || direction === 'l-bfs') { // 右 子节点 深度  和 左 子节点 广度遍历
                _child = _stackTop[childName]
            }

            if (autoAddParentId) {
                // 添加 parentId
                _child.forEach(_item => {
                    _item.parentId = _stackTop.id
                })
            }

            _stack = _stack.concat(_child) // 如果有子节点，那么将子节点放在栈里面

        }

    }
}

/**
 * 根据 对象列表里面的 某个参数 去重
 * */
export function delRepeatData(list, key) {
  const keyList = [];

  return list.filter(item => {
    const noExit = keyList.indexOf(item[key]) === -1;
    if(noExit){
      keyList.push(item[key]);
    }
    return noExit
  })

}