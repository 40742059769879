import { Avatar, Button, Select, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HeaderDropdown from "@/pages/components/HomeHeader/HeaderDropdown.jsx";
import styles from "./index.module.scss";
import { useRootContext } from "@/common/RootContext";
import { useT } from '@/common/utils/translation'
import VipModulePackageModal from '@/pages/components/VipPackageModal/index'

// === "development";

function HomeHeader() {
  let t = useT()
  const RootContext = useRootContext();

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {/* <Select
            defaultValue={RootContext.viewVersion}
            options={[
                {label: '国内版', value: 'cn'},
                {label: '国际版', value: 'com'},
            ]}
            onChange={(type) => {
                RootContext.dispatchViewVersion(type);
            }}
        /> */}
      </div>
      <div className={styles.right}>
        <Space size={16} style={{ height: '48px' }}>

          {/*<div className={styles.qrButton}>*/}
          {/*  <Popover*/}
          {/*    placement="bottom"*/}
          {/*    content={<QrComponent/>}*/}
          {/*    trigger={'click'}*/}
          {/*  >*/}
          {/*    <Link style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>*/}
          {/*      <SvgIcon name={'qr'} style={{height: '16px', width: '16px', color: 'rgba(0,0,0,0.88)'}}/>*/}
          {/*      <span>{t('cross_border_seller_ai_communication_group')}</span>*/}
          {/*    </Link>*/}
          {/*  </Popover>*/}
          {/*</div>*/}

          {/*<Divider type='vertical'/>*/}

          {/*<Button type={"primary"} shape="round" onClick={openVipInfo} style={{borderRadius: '8px'}}>*/}
          {/*  {t('components_vip_package')}*/}
          {/*</Button>*/}

          {/*<Select*/}
          {/*  style={{width: "6.25rem"}}*/}
          {/*  value={language}*/}
          {/*  onSelect={onSelect}*/}
          {/*>*/}
          {/*  <Option value={"zh"}>简体中文</Option>*/}
          {/*  <Option value={"en"}>English</Option>*/}
          {/*</Select>*/}

          {RootContext.loginStatus ? (
            <Space style={{ height: '48px', lineHeight: '48px' }}>
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={<UserOutlined />}
              />
              <HeaderDropdown />
            </Space>
          ) : (
            <Button
              onClick={() => {
                RootContext.dispatchSetLoginVisible(true)
                // navigate({
                //   pathname: '/login'
                // })
              }}
              type="primary"
              ghost
              shape="round"
              style={{ borderRadius: '8px' }}
            >
              {t('components_log_in_register')}
            </Button>
          )}
        </Space>
      </div>
      <VipModulePackageModal />
    </div>
  );
}

export default HomeHeader;
