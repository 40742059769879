import axios from "axios";
import { message } from "antd";
import {
  NOT_LOGIN,
  PACKAGE_EQUITY_ERROR,
  TOKEN_INVALID,
  TOKEN_INACTIVITY,
} from "@/services/const.js";
import CustomerConfirm from "@/common/utils/CustomerConfirm.jsx";
import { myCookie } from "@/common/utils/cookie";
import { allLoginVisible } from "@/common/RootContext";
import { vipPackageGlobalVisible } from "@/context/vip/index.jsx";
import stores from "@/common/utils/stores.js";
import React from "react";
import MenuName from "@/pages/components/MenuName";
import qs from "qs";

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

/**
 *
 * 弹窗管理
 *
 * 1. 未登录 展示登录弹窗
 * 2. 在登录弹窗出现之前, 要清除页面已经存在的弹窗
 * 3. 套餐余量不足,展示VIP套餐弹窗
 */
const ModalManage = {
  // 打开登录弹窗
  openLoginModal: function () {
    this.closeVipPackageMModal();
    allLoginVisible && allLoginVisible();
  },
  // 关闭VIP套餐弹窗
  closeVipPackageMModal: () => {
    vipPackageGlobalVisible && vipPackageGlobalVisible(false);
  },
  // 展示VIP套餐弹窗
  openVipPackageMModal: () => {
    vipPackageGlobalVisible && vipPackageGlobalVisible(true);
  },
};

// export const API_BASE_URL = 'https://kerqu-ai-kerqu-ai-dev-shahgltcfp.cn-hangzhou.fcapp.run';
// const API_BASE_URL1 = 'https://kerqu-dtc-dev-kerqu-dtc-test-fcmoxdaqtz.cn-hangzhou.fcapp.run';
// 创建Axios实例
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const lang = stores.getLocalStorage("languageType") || "zh";

    if (config.url.indexOf("?") !== -1) {
      config.url += `&lang=${lang === "zh" ? "zh_CN" : "en_US"}`;
    } else {
      config.url += `?lang=${lang === "zh" ? "zh_CN" : "en_US"}`;
    }

    const groupId = myCookie.get("groupId");
    if (groupId) {
      config.headers["kerqu-group"] = groupId;
    }
    const traderToken = myCookie.get("kerqu-admin-token");
    if (traderToken) {
      config.headers["kerqu-admin-token"] = traderToken;
    }

    if (config.responseType) {
      config.headers["responseType"] = config.responseType;
    }

    if (config?.options?.headers) {
      for (let key in config?.options?.headers) {
        config.headers[key] = config?.options?.headers[key];
      }
    }

    if (config?.options?.responseType) {
      config.responseType = config?.options?.responseType;
    }

    // config.headers['kerqu-domain'] = 'test';
    // config.headers['traderId'] = 47; // 需要移除的部分
    // config.headers['phone'] = 15939969105;  // 需要移除的部分

    // 在发送请求之前做些什么，例如添加身份验证信息等
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    if (!response?.data?.success) {
      const { code } = response.data;
      if (code === PACKAGE_EQUITY_ERROR) {
        CustomerConfirm({
          title: response.data.message ?? (
            <MenuName keyName={"application_detail"} />
          ),
          content: response.data.data ?? response.data.message,
          // title: <MenuName keyName={'application_detail'}/>,
          // content: t('menu_your_application_q_a_times_this_month_have_reached_the_limit_you_can_upgrade_your_package_to_continue_using_it'),
          // content: response.data.message,
          okText: <MenuName keyName={"button_update_permissions"} />,
          onOk: () => {
            ModalManage.openVipPackageMModal();
          },
        });
      } else if (code === NOT_LOGIN || code === TOKEN_INVALID || code === TOKEN_INACTIVITY) {
        // window.location.href = "/login";
        ModalManage.openLoginModal();
      } else if (code === 'PARAM_ERROR') {
        message
        .open({
          type: "error",
          content: response?.data?.detail || response?.data?.message,
        })
        .then();
      } else {
        message
          .open({
            type: "error",
            content: response?.data?.detail || response?.data?.message,
          })
          .then();
      }
    } else {
      const { code } = response.data;
      if (code === NOT_LOGIN || code === TOKEN_INVALID || code === TOKEN_INACTIVITY) {
        // window.location.href = "/login";
        ModalManage.openLoginModal();
      }
    }
    // 对响应数据做些什么
    return response;
  },
  (error) => {
    const { code } = error?.response?.data || {};
    if (code === NOT_LOGIN || code === TOKEN_INVALID || code === TOKEN_INACTIVITY) {
      // window.location.href = "/login";
      ModalManage.openLoginModal();
      return;
    }

    message
      .open({
        type: "error",
        content: error?.response?.data?.message,
      })
      .then();
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

// 封装常用的请求方法
const request = {
  get: async (url, params, options) => {
    try {
      const response = await axiosInstance.get(url, {
        params,
        options,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  post: async (url, data, options) => {
    try {
      const response = options?.useParams
        ? await axiosInstance.post(url, {}, { ...options, params: data })
        : await axiosInstance.post(url, data, options);
      if (options?.fullResponse) {
        return response;
      }
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  put: async (url, data, options) => {
    try {
      const response = options?.useParams
        ? await axiosInstance.put(url, {}, { ...options, params: data })
        : await axiosInstance.put(url, data, options);
      if (options?.fullResponse) {
        return response;
      }
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  delete: async (url, data) => {
    try {
      const response = await axiosInstance.delete(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  // 其他HTTP方法的封装方法，例如put、delete等
};

export function getFetch(url, config) {
  const cfg = { ...config };
  if (!cfg.headers) {
    cfg.headers = {};
  }
  if (typeof cfg.headers["Content-Type"] === "undefined") {
    cfg.headers["Content-Type"] = "application/json";
  }
  if (
    cfg.headers["Content-Type"] === "application/json" &&
    typeof config.body === "object"
  ) {
    cfg.body = JSON.stringify(config.body);
  }
  const groupId = myCookie.get("groupId");
  if (groupId) {
    cfg.headers["kerqu-group"] = groupId;
  }

  const traderToken = myCookie.get("kerqu-admin-token");
  if (traderToken) {
    cfg.headers["kerqu-admin-token"] = traderToken;
  }

  if (config.responseType) {
    cfg.headers["responseType"] = config.responseType;
  }

  const lang = stores.getLocalStorage("languageType") || "zh";
  url += `?lang=${lang === "zh" ? "zh_CN" : "en_US"}`;

  return fetch(API_BASE_URL + "/" + url, cfg);
}

export default request;
